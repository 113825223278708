'use es6'; // adapted from https://git.hubteam.com/HubSpot/I18n/blob/v6/static/js/utils/getLang.js

const getLang = ({
  locale,
  langEnabled
}) => {
  return locale !== 'en' && !langEnabled ? 'en' : locale;
};

export default (({
  EarlyRequester,
  User
}) => {
  const {
    user
  } = EarlyRequester;
  const locale = getLang({
    locale: user.locale,
    langEnabled: user.lang_enabled
  });
  return {
    language: locale.substring(0, 2).toUpperCase(),
    locale,
    userCreationUtc: User.createdAt,
    userEmail: user.email,
    userFirstName: user.first_name,
    userId: user.user_id,
    userLastName: user.last_name,
    userIsPartner: user.partner
  };
});