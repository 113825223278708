import { ASSIGN_SEAT, REQUEST_SEAT } from 'ui-addon-upgrades/_core/purchaseMotions/PurchaseMotionTypes';
import ApiNameToUpgradeProductMap from 'self-service-api/constants/ApiNameToUpgradeProductMap'; // @ts-expect-error dependency missing types

import UpgradeProductToApiNameMap from 'self-service-api/constants/UpgradeProductToApiNameMap';
import sortByTier, { sortByTierFromApiName } from './sortByTier';
import { getHubAndTier, getHubAndTierFromApiName } from './utils';
import ProductTierOrders from 'self-service-api/constants/ProductTierOrders';
import { ENTERPRISE } from 'self-service-api/constants/ProductLevels';
import { MARKETING_STARTER, MARKETING_STARTER_EMAIL } from 'self-service-api/constants/UpgradeProducts'; // getCorrectedUpgradeData isn't called downstream, so we need to account
// for the MARKETING_STARTER product here

export const correctProduct = upgradeProduct => {
  if (upgradeProduct === MARKETING_STARTER) {
    return MARKETING_STARTER_EMAIL;
  }

  return upgradeProduct;
};
export const getProductsInAboveTiers = upgradeProduct => {
  const correctedProduct = correctProduct(upgradeProduct);
  const {
    tier
  } = getHubAndTier(correctedProduct);

  if (tier === ENTERPRISE) {
    return [];
  }

  const productApiName = UpgradeProductToApiNameMap[correctedProduct];

  if (!productApiName) {
    return [];
  }

  const tieredApiNameGroup = ProductTierOrders.filter(productTierOrder => productTierOrder.indexOf(productApiName) !== -1)[0];

  if (!tieredApiNameGroup) {
    return [];
  }

  const apiNameIndex = tieredApiNameGroup.indexOf(productApiName);
  const currentAndHigherTieredApiNames = tieredApiNameGroup.slice(apiNameIndex, tieredApiNameGroup.length);
  const currentAndHigherTieredUpgradeProducts = [];
  currentAndHigherTieredApiNames.forEach(apiName => {
    const product = ApiNameToUpgradeProductMap[apiName];

    if (product) {
      currentAndHigherTieredUpgradeProducts.push(product);
    }
  });
  return sortByTier(currentAndHigherTieredUpgradeProducts);
};
export const getSeatedPurchaseMotionProducts = purchaseMotions => {
  const seatedPurchaseMotionProducts = [];
  Object.keys(purchaseMotions).forEach(apiName => {
    const purchaseMotion = purchaseMotions[apiName];
    if (!purchaseMotion) return;
    purchaseMotion.forEach(subkey => {
      if (subkey.key === ASSIGN_SEAT || subkey.key === REQUEST_SEAT) {
        const upgradeProductFromApiName = ApiNameToUpgradeProductMap[apiName];

        if (upgradeProductFromApiName) {
          seatedPurchaseMotionProducts.push(upgradeProductFromApiName);
        }
      }
    });
  });
  return seatedPurchaseMotionProducts;
};
export const getPrioritizedUpgradeProduct = (upgradeProducts, purchaseMotions) => {
  const seatedPurchaseMotionProducts = getSeatedPurchaseMotionProducts(purchaseMotions); // no seat purchase motions

  if (!(seatedPurchaseMotionProducts && seatedPurchaseMotionProducts.length > 0)) {
    return correctProduct(upgradeProducts[0]);
  } // there are seat purchase motions


  const upgradeProductAndHigherTiers = upgradeProducts.map(product => getProductsInAboveTiers(product)).flat(); // first seated purchase motion for product and higher tiers in upgradeProducts

  const prioritizedProduct = upgradeProductAndHigherTiers.find(product => seatedPurchaseMotionProducts.includes(product));

  if (prioritizedProduct) {
    return prioritizedProduct;
  }

  return correctProduct(upgradeProducts[0]);
};
export const getProductsInAboveTiersFromApiName = apiName => {
  const {
    tier
  } = getHubAndTierFromApiName(apiName);

  if (tier === ENTERPRISE) {
    return [];
  }

  const tieredApiNameGroup = ProductTierOrders.filter(productTierOrder => productTierOrder.indexOf(apiName) !== -1)[0];

  if (!tieredApiNameGroup) {
    return [];
  }

  const apiNameIndex = tieredApiNameGroup.indexOf(apiName);
  const currentAndHigherTieredApiNames = tieredApiNameGroup.slice(apiNameIndex, tieredApiNameGroup.length);
  const sortedApiNames = sortByTierFromApiName(currentAndHigherTieredApiNames);
  return sortedApiNames.slice(0, sortedApiNames.indexOf(apiName) + 1);
};
export const getSeatedPurchaseMotionApiNames = rawPurchaseMotions => {
  const seatedPurchaseMotionApiNames = [];
  Object.keys(rawPurchaseMotions).forEach(apiName => {
    const purchaseMotion = rawPurchaseMotions[apiName];
    if (!purchaseMotion) return;
    purchaseMotion.forEach(subkey => {
      if (subkey.key === ASSIGN_SEAT || subkey.key === REQUEST_SEAT) {
        seatedPurchaseMotionApiNames.push(apiName);
      }
    });
  });
  return seatedPurchaseMotionApiNames;
};
export const getPrioritizedUpgradeProductFromApiName = (apiNames, purchaseMotions) => {
  const seatedPurchaseMotionProducts = getSeatedPurchaseMotionApiNames(purchaseMotions); // no seat purchase motions

  if (!(seatedPurchaseMotionProducts && seatedPurchaseMotionProducts.length > 0)) {
    return apiNames[0];
  } // there are seat purchase motions


  const upgradeProductAndHigherTiers = apiNames.map(product => getProductsInAboveTiersFromApiName(product)).flat(); // first seated purchase motion for product and higher tiers in apiNames

  const prioritizedProduct = upgradeProductAndHigherTiers.find(product => seatedPurchaseMotionProducts.includes(product));

  if (prioritizedProduct) {
    return prioritizedProduct;
  }

  return apiNames[0];
};
export default getPrioritizedUpgradeProduct;