'use es6';

import PortalIdParser from 'PortalIdParser';
import { getFullUrl } from 'hubspot-url-utils';
import { getWindowSearch } from './params';
import { TABS } from '../constants/tabs';
import { hasFeature } from './features';
const EMBEDDABLE_APP_PATH = 'collaboration-sidebar-embeddable';
const MINI_COMMENTER_EMBEDDABLE_APP_PATH = 'collaboration-sidebar-mini-commenter';
export const COMMENTING_QUERYSTRING_PARAM = 'commenting=true';
export function getEmbeddablePanelUrl({
  objectType,
  objectId,
  ctaUrl,
  appName,
  currentLocation = window.location,
  tab = TABS.COMMENTS
}) {
  let isDevelopmentMode = false;

  if (currentLocation.hostname.includes('local')) {
    const isCommentingSandbox = currentLocation.pathname.startsWith('/collaboration-sidebar-sandbox-ui');

    try {
      isDevelopmentMode = isCommentingSandbox || !!localStorage.getItem('COLLABORATION_SIDEBAR_DEV'); // eslint-disable-next-line no-empty
    } catch (e) {}
  }

  const params = [`ctaUrl=${encodeURIComponent(ctaUrl)}`, `appName=${encodeURIComponent(appName)}`];
  const baseUrl = getFullUrl(isDevelopmentMode ? 'local' : 'app');
  return `${baseUrl}/${EMBEDDABLE_APP_PATH}/${PortalIdParser.get()}/panel/${objectType}/${objectId}/${tab}?${params.join('&')}`;
}
export function getEmbeddableMiniCommenterUrl({
  objectType,
  objectId,
  moduleId,
  ctaUrl,
  appName,
  currentLocation = window.location,
  autoFocus
}) {
  let isDevelopmentMode = false;

  if (currentLocation.hostname.includes('local')) {
    const isCommentingSandbox = currentLocation.pathname.startsWith('/collaboration-sidebar-sandbox-ui');

    try {
      isDevelopmentMode = isCommentingSandbox || !!localStorage.getItem('COLLABORATION_SIDEBAR_DEV'); // eslint-disable-next-line no-empty
    } catch (e) {}
  }

  const params = [`ctaUrl=${encodeURIComponent(ctaUrl)}`, `appName=${encodeURIComponent(appName)}`];

  if (autoFocus) {
    params.push(`autoFocus=true`);
  }

  const baseUrl = getFullUrl(isDevelopmentMode ? 'local' : 'app');
  return `${baseUrl}/${MINI_COMMENTER_EMBEDDABLE_APP_PATH}/${PortalIdParser.get()}?objectType=${objectType}&objectId=${objectId}&moduleId=${moduleId}&${params.join('&')}`;
}
export function parseQueryParams() {
  const search = getWindowSearch();
  return (search.startsWith('?') ? search.substring(1) : search).split('&').reduce((built, str) => {
    const [key, val] = str.split('=');
    built[key] = val;
    return built;
  }, {});
}
export function getOpenQueryParam() {
  return parseQueryParams()['csOpen'] === 'true';
}
export function getTabQueryParam() {
  return parseQueryParams()['csTab'] || TABS.COMMENTS;
}
export function getSecuredTabQueryParam({
  enabledFeatures
}) {
  const requestedTab = getTabQueryParam();

  if (hasFeature(enabledFeatures, requestedTab)) {
    return requestedTab;
  } //If not return the first enabled tab


  return Object.values(TABS).find(tab => hasFeature(enabledFeatures, tab));
}
export function getCommentingThreadQueryParam() {
  return parseQueryParams().commentThread || null;
}
export function getSelectedObjectIdQueryParam() {
  return parseQueryParams()['csSelectedObjectId'];
}
export function getSelectedObjectTypeQueryParam() {
  return parseQueryParams()['csSelectedObjectType'];
}
export function getIsCommentingUrl() {
  return window.location.search.includes(COMMENTING_QUERYSTRING_PARAM);
}
export function getIsWorkflowActionsUrl() {
  return window.location.pathname.includes('/workflows/') && window.location.pathname.includes('/actions/');
}
export function getCommentIdParam() {
  return parseQueryParams()['commentId'];
}