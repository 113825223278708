import { useCallback, useContext } from 'react';
import { APP_NAME, COMMENTS_FILTER, CTA_URL, HIDE_EMBEDDED_COMMENTS, IS_COMMENT_MODE_ACTIVE, IS_EMBEDDED_COMMENTS_ENABLED, IS_OPEN, MODULES_WITH_REPLIES, OBJECT_ID, OBJECT_TYPE, TAB, SELECTED_ASSET, EMBEDDED_COMMENT_SIDEBAR_INTERACTIONS, COMMENT_THREAD } from '../constants/CollaborationSidebarStateProperties';
import CollaborationSidebarStateContext from '../contexts/CollaborationSidebarStateContext';
import { getSanitizedTab } from '../utils/tab';
export default function useCollaborationSidebarState() {
  const {
    collaborationSidebarState,
    onUpdateCollaborationSidebarState
  } = useContext(CollaborationSidebarStateContext);
  return {
    collaborationSidebarState,
    onUpdateCollaborationSidebarState,
    // These are provided as helpers for the above
    appName: collaborationSidebarState[APP_NAME],
    objectType: collaborationSidebarState[OBJECT_TYPE],
    objectId: collaborationSidebarState[OBJECT_ID],
    ctaUrl: collaborationSidebarState[CTA_URL],
    isEmbeddedCommentingEnabled: collaborationSidebarState[IS_EMBEDDED_COMMENTS_ENABLED],
    isOpen: collaborationSidebarState[IS_OPEN],
    tab: collaborationSidebarState[TAB],
    modulesWithReplies: collaborationSidebarState[MODULES_WITH_REPLIES],
    isCommentModeActive: collaborationSidebarState[IS_COMMENT_MODE_ACTIVE],
    hideEmbeddedComments: collaborationSidebarState[HIDE_EMBEDDED_COMMENTS],
    embeddedCommentSidebarInteractions: collaborationSidebarState[EMBEDDED_COMMENT_SIDEBAR_INTERACTIONS],
    selectedChildAsset: collaborationSidebarState[SELECTED_ASSET],
    commentThread: collaborationSidebarState[COMMENT_THREAD],
    commentsFilter: collaborationSidebarState[COMMENTS_FILTER],
    openSidebar: useCallback(tab => onUpdateCollaborationSidebarState({
      [IS_OPEN]: true,
      [TAB]: getSanitizedTab(tab)
    }), [onUpdateCollaborationSidebarState]),
    closeSidebar: useCallback(() => onUpdateCollaborationSidebarState({
      [IS_OPEN]: false
    }), [onUpdateCollaborationSidebarState]),
    setHideEmbeddedComments: useCallback(hideEmbeddedComments => onUpdateCollaborationSidebarState({
      [HIDE_EMBEDDED_COMMENTS]: hideEmbeddedComments
    }), [onUpdateCollaborationSidebarState])
  };
}