import { SET_GATHERER_KEYS } from 'ContentEditorUI/redux/actions/actionTypes';
const defaultPayload = {
  gathererKeys: []
};
const initialState = {
  gathererKeys: []
};

const inpageOptimizationsReducer = (state = initialState, data = {
  type: '',
  payload: defaultPayload
}) => {
  const {
    type,
    payload
  } = data;

  switch (type) {
    case SET_GATHERER_KEYS:
      {
        const {
          gathererKeys
        } = payload;
        return Object.assign({}, state, {
          gathererKeys
        });
      }

    default:
      return state;
  }
};

export default inpageOptimizationsReducer;