import I18n from 'I18n';
import getLang from 'I18n/utils/getLang';
import userInfo from 'hub-http/userInfo';
import noAuthApiClient from 'hub-http/clients/noAuthApiClient';
import { getFullUrl } from 'hubspot-url-utils';
import { createClient } from 'usage-tracker-core';
import { genericClient } from 'usage-tracker-core/client';
const origin = getFullUrl('app-api');
const path = '/usage-logging/v1/log/hublytics-multi/no-auth';

const reportNetworkError = err => {
  return genericClient.reportError(err, {
    fingerprint: ['usage-tracker-js', 'network']
  });
};

const trackingClient = createClient({
  clientName: 'app',
  getDebug: genericClient.getDebug,
  getHstc: genericClient.getHstc,
  getTempStorage: genericClient.getTempStorage,
  setTempStorage: genericClient.setTempStorage,
  logWarning: genericClient.logWarning,
  logError: genericClient.logError,
  reportWarning: genericClient.reportWarning,
  reportError: genericClient.reportError,
  getEmail: () => userInfo({
    ignoreRedirect: true
  }).then(({
    user
  }) => user.email),
  getHubId: () => userInfo({
    ignoreRedirect: true
  }).then(({
    portal
  }) => portal.portal_id),
  getLang: () => I18n.Info.then(getLang),
  send: ({
    events,
    query,
    isBeforeUnload
  }) => {
    const endpoint = `${origin}${path}?${query}`;
    const data = events;

    const sendXhr = () => {
      noAuthApiClient.post(endpoint, {
        data
      }).catch(reportNetworkError);
    };

    if (isBeforeUnload) {
      genericClient.sendBeacon(endpoint, data, sendXhr);
    } else {
      sendXhr();
    }
  }
});
export default trackingClient;