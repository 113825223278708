import { List as ImmutableList } from 'immutable';
import I18n from 'I18n';
import * as FieldTypes from 'ContentUtils/constants/CustomWidgetFieldTypes';
export const InternalFields = {
  hs_name: 'hs_name',
  hs_path: 'hs_path',
  hs_child_table_id: 'hs_child_table_id'
};
export function hubDBFieldtoCmvField(fields, rowId, columns, moduleValues, fieldOverrides) {
  const indexOffset = fields.some(field => field.id === InternalFields.hs_child_table_id) ? 3 : 2;
  return fields.map((field, index) => {
    // Because the hsPath & hsName are included it messes up our indexing
    // when grabbing from the columns for information.
    const adjustedIndex = index - indexOffset;
    const correspondingColumn = columns[adjustedIndex];

    if (adjustedIndex >= 0) {
      field.help_text = correspondingColumn && correspondingColumn['description'];
    }

    if (field.type === 'image') {
      return Object.assign({
        showAlt: false,
        responsive: false,
        forceHideResponsive: false,
        resizable: true
      }, field);
    }

    if (field.type === 'video') {
      return Object.assign({}, field);
    }

    if (field.type === FieldTypes.HUBDB_TABLE) {
      const tableChoices = fieldOverrides && fieldOverrides.foreignTableOptions || [];
      tableChoices.unshift([0, I18n.text('editor.instanceEditing.childTableEmpty')]);
      return Object.assign({}, field, {
        choices: tableChoices
      });
    }

    if (field.type === 'hubdbrow') {
      return Object.assign({}, field, {
        rowId,
        optionType: 'foreignid',
        immutableValue: moduleValues[field.name],
        foreignIds: correspondingColumn && correspondingColumn.foreignIds || ImmutableList([])
      });
    } else {
      return field;
    }
  });
}
export function mapHubDBValueToModuleValue(row, tableRowSchema) {
  const moduleValues = {};
  tableRowSchema.fields.forEach(field => {
    if (row && (row.values[field.id] || InternalFields[field.id])) {
      const rowValue = row.values[field.id];
      let value;

      switch (field.type) {
        case FieldTypes.IMAGE:
          {
            value = rowValue;
            value.src = rowValue.url;
            break;
          }

        case FieldTypes.VIDEO:
          {
            value = {
              player_id: rowValue,
              player_type: 'hsvideo2'
            };
            break;
          }

        case FieldTypes.URL:
          {
            value = {
              href: rowValue
            };
            break;
          }

        case FieldTypes.LOCATION:
          {
            value = rowValue;
            break;
          }

        case FieldTypes.HUBDB_TABLE:
          {
            value = row.childTableId;
            break;
          }

        case FieldTypes.CHOICE:
          {
            if (Array.isArray(rowValue)) {
              value = rowValue.map(option_value => option_value.id.toString());
            } else {
              value = rowValue.id.toString();
            }

            break;
          }

        case FieldTypes.HUBDB_ROW:
          {
            if (rowValue.length > 0) value = rowValue;
            break;
          }

        case FieldTypes.TEXT:
          {
            if (field.id === InternalFields.hs_name) {
              value = row.name;
            } else if (field.id === InternalFields.hs_path) {
              value = row.path;
            } else {
              value = row.values[field.id];
            }

            break;
          }

        default:
          {
            value = row.values[field.id];
          }
      }

      moduleValues[field.name] = value;
    } else {
      if (field.type === FieldTypes.LOCATION) {
        moduleValues[field.name] = {
          lat: null,
          long: null
        };
      }
    }
  });
  return moduleValues;
}