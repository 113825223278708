'use es6';

import { debounce } from 'underscore';
import { SOURCE_APP_IDS_FOR_TRACKING } from './automationConstants';

class UsageTracker {
  constructor() {
    this.cloneCache = {};
  }

  init(tracker, appName = 'content-editor-ui') {
    this.tracker = tracker;
    this.appName = appName;
    this.defaultCloneOptions = {
      preserveTrackerProperties: false,
      preserveTrackerEvents: true
    };
    this.trackerAsBeacon = tracker.clone ? tracker.clone({
      isBeforeUnload: true
    }) : null;
  }

  getTracker() {
    return this.tracker;
  }

  isInitialized() {
    return !!this.getTracker();
  }

  cloneTracker(configOptions = {}) {
    return this.tracker ? this.tracker.clone(Object.assign({}, this.defaultCloneOptions, {}, configOptions)) : null;
  }

  getCachedClonedTracker(cacheKey, configOptions = {}) {
    if (!this.cloneCache[cacheKey]) {
      this.cloneCache[cacheKey] = this.cloneTracker(configOptions);
    }

    return this.cloneCache[cacheKey];
  }

  getAppName() {
    return this.appName;
  }

  track(event, properties = {}) {
    if (this.tracker) {
      this.tracker.track(event, properties);
    } else if (!window.jasmine) {
      console.error('Cannot track usage before tracker initialization.');
    }
  }

  trackEditorInteraction(properties) {
    this.track('editorInteraction', properties);
  }

  trackContextualAutomationInteraction(properties) {
    const sourceApp = SOURCE_APP_IDS_FOR_TRACKING[this.getAppName()];
    const extraProps = sourceApp ? {
      sourceApp
    } : {};
    this.track('contentEditorAutomationInteraction', Object.assign({}, extraProps, {}, properties));
  }

  createDebouncedTracker(event, properties, debounceTime) {
    return debounce(() => this.track(event, properties), debounceTime);
  } // NOTE Functionally no different than track, but helps clarify which events are public
  // ********** PUBLIC EVENT **********
  // Public Events help teams across HubSpot automate work and customize experiences based on user actions.
  // Speak with #product-insight and your PM before any shipping any changes to this event incl. event name, properties, values, and when it occurs.
  // Read more about Public Events on the wiki: https://wiki.hubspotcentral.net/display/PM/Public+Events+-+Amplitude+events+ready+for+HubSpot+team+use+and+automation


  trackPublicEvent(event, properties) {
    this.track(event, properties);
  }

  trackAsBeacon(event, properties) {
    if (this.trackerAsBeacon) {
      this.trackerAsBeacon.track(event, properties);
    }
  }

}

const Tracker = new UsageTracker();
export default Tracker;