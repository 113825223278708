import { getTourIdFromQueryParams } from './queryParams';
import { getNextTourIdFromStorage } from './tourStorage';
export const isTourCurrentlyActive = () => {
  const hasActiveTourBodyClass = document.querySelector('body.onboarding-tour--active');
  return !!hasActiveTourBodyClass;
};
export const shouldRenderTour = tourId => {
  const tourIdFromQuery = getTourIdFromQueryParams();
  const tourIdFromStorage = getNextTourIdFromStorage(); // enable only for specific tour ID(s)

  if (tourId) {
    if (typeof tourId === 'string') {
      return tourIdFromQuery === tourId || tourIdFromStorage === tourId;
    } else {
      return !!(tourIdFromQuery && tourId.includes(tourIdFromQuery)) || !!(tourIdFromStorage && tourId.includes(tourIdFromStorage));
    }
  }

  return !!(tourIdFromQuery || tourIdFromStorage);
};