'use es6';

import { SELECTED_ITEM_UPDATED, SELECTED_ITEM_CLEARED, SELECTED_ITEM_REVERTED_TO_PREVIOUS, SELECTED_ITEM_UPDATED_STYLE_SECTION_TOUR_STEP, CLEAR_USE_SCROLL_IF_MODULE_OUT_OF_BOUNDS_AT_ALL, FREEZE_NODE_POSITIONS, THAW_NODE_POSITIONS } from 'ContentEditorUI/redux/actions/actionTypes';
import { getSelectedItemId } from '../selectors/selectionSelectors';
import { getIsLayoutSectionModuleIsIn, getModulesByNameFromPostBody } from 'ContentEditorUI/redux/selectors/moduleSelectors';
import { getIsModuleStyleable } from 'ContentEditorUI/redux/selectors/styleSelectors';
import { getPreviewIframeNeedsRefresh } from 'ContentEditorUI/redux/selectors/inpageSelectors';
import { toggleApplyChangesModalOpen } from 'ContentEditorUI/redux/actions/appActions';
import { getIsUngatedForPatagonia } from 'ContentEditorUI/redux/selectors/authSelectors';
import { getIsDistractionFreeOpen } from 'ContentEditorUI/redux/selectors/localCachedSettingsSelectors';
import { toggleDistractionFree } from 'ContentEditorUI/redux/actions/distractionFreeActions';
export const updateSelectedItem = (id, {
  activeTab,
  containerId,
  shouldScrollToModule,
  doNotShowSidebarForm
} = {}) => (dispatch, getState) => {
  // If the module isn't styleable, switch the activeTab to the default tab.
  // We're adding this logic here, because the getIsModuleStyleable isn't memoized
  // and it causes performance issues when called multiple times. This will be removed when
  // https://git.hubteam.com/HubSpot/ContentEditorUI/issues/9191 gets addressed.
  const reduxState = getState();
  const isPatagonia = getIsUngatedForPatagonia(reduxState);

  if (activeTab === 'style') {
    const isModuleNotStyleable = !getIsModuleStyleable(reduxState, id);

    if (isModuleNotStyleable) {
      activeTab = null;
    }
  }

  const previewIframeNeedsRefresh = getPreviewIframeNeedsRefresh(reduxState);
  const isDistractionFreeOpen = getIsDistractionFreeOpen(reduxState);
  const postBodyModulesByName = getModulesByNameFromPostBody(reduxState, id); // Automatically fill the container id for modules in a D&D area

  containerId = containerId || getIsLayoutSectionModuleIsIn(reduxState, id);
  const opts = {
    id,
    activeTab,
    containerId,
    shouldScrollToModule,
    doNotShowSidebarForm,
    isPatagonia
  };

  if (previewIframeNeedsRefresh) {
    const applyChangesModalOpts = {
      selectionInProgress: opts,
      routingInProgress: null
    };
    dispatch(toggleApplyChangesModalOpen(true, applyChangesModalOpts));
  } else {
    if (id !== 'post_body' && isDistractionFreeOpen && !postBodyModulesByName.has(id)) {
      // This action is dispatched again in the BlogRichText widget with
      // `shouldScrollToModule = true` when the distraction free close
      // animation is complete.
      opts.shouldScrollToModule = false;
      dispatch(toggleDistractionFree(false));
    }

    if (isPatagonia) {
      dispatch({
        type: FREEZE_NODE_POSITIONS,
        iframeOnly: true
      });
      setTimeout(() => {
        dispatch({
          type: THAW_NODE_POSITIONS,
          iframeOnly: true
        });
      }, 250);
    }

    dispatch({
      payload: opts,
      type: SELECTED_ITEM_UPDATED
    });
  }
};
export const updateSelectedItemStyleSectionDropdown = (id, containerId) => {
  return {
    payload: {
      id,
      containerId
    },
    type: SELECTED_ITEM_UPDATED_STYLE_SECTION_TOUR_STEP
  };
};
export const clearSelectedItem = (opts = {
  onlyIfSelected: null
}) => (dispatch, getState) => {
  const reduxState = getState();
  const onlyIfSelected = opts.onlyIfSelected; // Only clear selection if the id that triggered the action  is the one that's currently selected
  // (or onlyIfSelected was unspecified)

  const previewIframeNeedsRefresh = getPreviewIframeNeedsRefresh(reduxState);
  const selectedItemId = getSelectedItemId(reduxState);
  const postBodyModulesByName = getModulesByNameFromPostBody(reduxState);
  const isPatagonia = getIsUngatedForPatagonia(reduxState);

  if (previewIframeNeedsRefresh) {
    dispatch(toggleApplyChangesModalOpen(true));
  } else if (onlyIfSelected == null || selectedItemId === onlyIfSelected) {
    if (getIsDistractionFreeOpen(reduxState) && selectedItemId && !postBodyModulesByName.has(selectedItemId)) {
      dispatch(toggleDistractionFree(false));
    } //in patagonia blurring a module from preview iframe closes the sidebar
    //however going out of module from content panel or optimize panel back to previous panel should not close the sidebar


    const closePatagoniaSidebar = isPatagonia && opts.returnToPreviousPanel === false;
    dispatch({
      type: SELECTED_ITEM_CLEARED,
      payload: {
        onlyIfSelected,
        closePatagoniaSidebar
      }
    });
  }
};
export const revertToPreviousSelection = () => ({
  type: SELECTED_ITEM_REVERTED_TO_PREVIOUS
});
export const clearUseScrollIfModuleOutOfBoundsAtAll = () => {
  return {
    type: CLEAR_USE_SCROLL_IF_MODULE_OUT_OF_BOUNDS_AT_ALL
  };
};