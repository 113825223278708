'use es6';

import * as actionTypes from 'ContentEditorUI/redux/actions/actionTypes';
import { OPEN_INPAGE_MODAL, CLOSE_INPAGE_MODAL, OPEN_RICH_TEXT_EDITOR_FIREFOX, CLOSE_RICH_TEXT_EDITOR_FIREFOX } from 'ContentEditorUI/redux/actions/inpageReduxActionTypes';
import { getScreenshot, getAsyncScreenshot } from '../../api/HubShotApi';
import { getCellOrRowById } from '../selectors/moduleSelectors';
import { getPreviewIframeUrl } from 'ContentEditorUI/js/redux/selectors/iframesSelectors';
import { getCurrentCreateNewSectionInfo } from '../selectors/sectionsSelectors';
import { updateSelectedCategory } from 'ContentEditorUI/redux/actions/sidebarActions';
import { openPatagoniaSidebar, setActivePatagoniaPanel } from 'ContentEditorUI/redux/actions/panelActions';
import { getDefaultSubCategoryMap } from 'ContentEditorUI/redux/selectors/sidebarSelectors';
import { getCanUseCustomSections } from '../selectors/templateInfoSelectors';
import { PATAGONIA_ADD_PANEL_CATEGORIES } from 'ContentEditorUI/constants/Sidebar';
import { getContentModelId } from 'ContentEditorUI/redux/selectors/baseContentModelSelectors';
import { getIsUngatedForAsyncScreenshotCreation } from 'ContentEditorUI/redux/selectors/authSelectors';
export const syncInpageState = (diff, iframeOnly = false) => {
  return {
    type: actionTypes.SYNC_INAPP_STATE,
    diff,
    iframeOnly
  };
};
export const clearInpageUIState = (values = {}) => {
  return {
    type: actionTypes.INPAGE_UI_STATE_CLEARED,
    payload: values
  };
};
export const resetHighlightedWidgetId = () => {
  return {
    type: actionTypes.RESET_HIGHLIGHTED_WIDGET_ID
  };
};
export const updateHighlightedWidgetId = (highlightedWidgetId, highlightedWidgetContainerId) => {
  return {
    type: actionTypes.UPDATE_HIGHLIGHTED_WIDGET_ID,
    payload: {
      highlightedWidgetId,
      highlightedWidgetContainerId
    }
  };
};
export const updateInpageMode = mode => {
  return {
    type: actionTypes.UPDATE_INPAGE_MODE,
    payload: mode
  };
};
export const updateIsDraggingModule = isDraggingModule => {
  return {
    type: actionTypes.UPDATE_INPAGE_IS_DRAGGING_MODULE,
    payload: {
      isDraggingModule
    }
  };
};
export const updatePostBodyFromInline = (postBody, metaData, undoData) => {
  metaData.inlineEdited = true;
  undoData.groupKey = 'post_body';
  return {
    type: actionTypes.UPDATE_POST_BODY,
    payload: {
      html: postBody
    },
    needsResponse: true,
    metaData,
    undoData
  };
};
export const updateScrollToModuleId = widgetId => {
  return {
    type: actionTypes.UPDATE_SCROLL_TO_MODULE_ID,
    payload: widgetId
  };
};
export const updateTitleFromInline = (title, metaData, undoData) => {
  metaData.inlineEdited = true;
  undoData.groupKey = 'post_title';
  return {
    type: actionTypes.UPDATE_TITLE_FROM_INLINE,
    payload: {
      title
    },
    needsResponse: true,
    undoData,
    metaData
  };
};
export const clearClonedModulesQueue = () => {
  return {
    type: actionTypes.CLONED_MODULES_QUEUE_CLEARED
  };
};
export const clearClonedStaticSectionsMap = () => {
  return {
    type: actionTypes.CLEAR_CLONED_STATIC_SECTION_MAP
  };
};
export const updateComputedStylesForSelectors = computedStylesBySelector => {
  return {
    type: actionTypes.UPDATE_COMPUTED_STYLES_FOR_SELECTORS,
    payload: {
      computedStylesBySelector
    }
  };
};
export const updatePreviewViewportDimensions = ({
  width,
  height
}) => {
  return {
    type: actionTypes.PREVIEW_VIEWPORT_DIMENSIONS_UPDATED,
    payload: {
      width,
      height
    }
  };
};
export const resetComputedStylesForSelectors = selectors => {
  const computedStylesBySelector = selectors.reduce((result, selector) => {
    result[selector] = null;
    return result;
  }, {});
  return {
    type: actionTypes.UPDATE_COMPUTED_STYLES_FOR_SELECTORS,
    payload: {
      computedStylesBySelector
    }
  };
};
export const openInpageModal = (modalType, props) => dispatch => {
  return dispatch({
    type: OPEN_INPAGE_MODAL,
    modalType,
    props,
    iframeOnly: false
  });
};
export const closeInpageModal = () => ({
  type: CLOSE_INPAGE_MODAL
});
export const inpagePreviewUnexpectedlyUnloaded = () => ({
  type: actionTypes.INPAGE_PREVIEW_UNEXPECTEDLY_UNLOADED
});
export const trackedFailedInpageAssets = failedAssets => ({
  type: actionTypes.TRACKED_FAILED_INPAGE_ASSETS,
  payload: failedAssets
});
export const trackedInpageJsLoadingMultipleTimes = () => ({
  type: actionTypes.TRACKED_INPAGE_JS_LOADING_MULTIPLE_TIMES
});
export const domReadyBlockingResourceDetected = resource => ({
  type: actionTypes.DOM_READY_BLOCKING_RESOURCE_DETECTED,
  payload: resource
});
export const openRichTextEditorFirefox = () => ({
  type: OPEN_RICH_TEXT_EDITOR_FIREFOX
});
export const closeRichTextEditorFirefox = () => ({
  type: CLOSE_RICH_TEXT_EDITOR_FIREFOX
});
export const setIframeNeedsRefresh = () => ({
  type: actionTypes.SET_IFRAME_NEEDS_REFRESH
});
export const previewScrollPositionChanged = ({
  left,
  top
}) => ({
  type: actionTypes.PREVIEW_SCROLL_POSITION_CHANGED,
  payload: {
    left,
    top
  }
});
export const openAddSectionPanel = ({
  layoutSectionId,
  rowId,
  isEmptyLayoutSection,
  targetBeforeOrAfter
}) => ({
  type: actionTypes.OPEN_ADD_SECTION_PANEL,
  payload: {
    layoutSectionId,
    rowId,
    isEmptyLayoutSection,
    targetBeforeOrAfter
  }
});
export const fetchScreenshotForCurrentNewSection = () => (dispatch, getState) => {
  const state = getState();
  const isUngatedForAsyncScreenshotCreation = getIsUngatedForAsyncScreenshotCreation(state); // Get the current row id and layout section id we care about

  const currentCreateNewSectionInfo = getCurrentCreateNewSectionInfo(state);
  const {
    sectionName,
    layoutSectionId
  } = currentCreateNewSectionInfo; // Find the corresponding node in the tree

  const sectionNode = getCellOrRowById(state, {
    id: sectionName,
    layoutSectionId
  }); // Get the "renderer compatible selector" (usually `.row-number-5` or something, each row
  // the renderer spits out has a unique numberered class).

  const rendererRowId = `[data-hs-row-id="${sectionNode.getRendererCompatibleRowName()}"]`;
  const previewUrl = getPreviewIframeUrl(state);
  const contentId = getContentModelId(state);

  if (!isUngatedForAsyncScreenshotCreation) {
    return getScreenshot(previewUrl, rendererRowId, undefined, {
      useCache: false
    });
  }

  return getAsyncScreenshot(previewUrl, contentId, rendererRowId);
};
export const openCreateNewSectionPanel = (sectionName, layoutSectionId) => ({
  type: actionTypes.OPEN_CREATE_NEW_SECTION_PANEL,
  payload: {
    sectionName,
    layoutSectionId
  }
});
export const closeCreateNewSectionPanel = () => ({
  type: actionTypes.CLOSE_CREATE_NEW_SECTION_PANEL
});
export const openDeleteSectionModal = layoutSectionId => ({
  type: actionTypes.OPEN_DELETE_SECTION_MODAL,
  payload: {
    layoutSectionId
  }
});
export const closeDeleteSectionModal = () => ({
  type: actionTypes.CLOSE_DELETE_SECTION_MODAL
});
export const updateNopeZoneSelector = selector => ({
  type: actionTypes.UPDATE_NOPE_ZONE_SELECTOR,
  payload: selector
});
export const openPatagoniaInpageSectionsPanel = () => (dispatch, getState) => {
  const state = getState();
  const canUseCustomSections = getCanUseCustomSections(state);
  const defaultSelectedSubCategoryMap = getDefaultSubCategoryMap(state);
  const category = canUseCustomSections ? PATAGONIA_ADD_PANEL_CATEGORIES.SECTIONS : PATAGONIA_ADD_PANEL_CATEGORIES.LAYOUTS;
  const subCategory = defaultSelectedSubCategoryMap.get(category);
  dispatch(updateSelectedCategory(category, subCategory));
  dispatch(setActivePatagoniaPanel('addWidget'));
  dispatch(openPatagoniaSidebar());
};