'use es6';

import { createNewModuleFromSchema } from 'ContentUtils/helpers/ModuleCreationHelpers';

const utf8ToB64 = str => {
  return btoa(unescape(encodeURIComponent(str)));
};

const b64ToUtf8 = str => {
  return decodeURIComponent(escape(atob(str)));
};

const isString = s => {
  return Object.prototype.toString.call(s) === '[object String]';
};

const parseDoc = doc => {
  if (!doc) {
    return new Document();
  }

  if (isString(doc)) {
    const parser = new DOMParser();
    return parser.parseFromString(doc, 'text/html');
  }

  return doc;
};

export const makeModuleInstance = (parentName, schema) => {
  const name = `widget_${Date.now()}`;
  const moduleInstance = createNewModuleFromSchema(schema, {}, name, name);
  moduleInstance.parent_name = parentName;
  moduleInstance.body = Object.assign({}, moduleInstance.body, {
    module_id: schema.moduleId,
    schema_version: schema.schemaVersion,
    tag: 'module',
    no_wrapper: false
  });
  return moduleInstance;
};
export const makeRenderedModulePlaceholder = name => {
  const placeholder = document.createElement('div');
  placeholder.id = `hs_cos_wrapper_${name}`;
  return placeholder;
};
export const makeHublModuleElement = (moduleInfo, shouldEncodeModuleAttributes = true) => {
  const {
    name,
    path,
    label,
    body = {},
    css = {},
    child_css = {}
  } = moduleInfo;
  const wrapper = document.createElement('div');
  wrapper.dataset.name = name;
  wrapper.dataset.path = path;
  wrapper.dataset.label = label;
  wrapper.classList.add('mceNonEditable');
  wrapper.classList.add('hs-module');
  wrapper.setAttribute('contenteditable', false);

  if (shouldEncodeModuleAttributes) {
    const attributes = {
      body,
      css,
      child_css
    };
    const encodedAttributes = utf8ToB64(JSON.stringify(attributes));
    wrapper.dataset.attributes = encodedAttributes;
  }

  return wrapper;
};

const parseHublModuleElement = domElement => {
  const {
    name,
    path,
    label,
    attributes: rawAttributes
  } = domElement.dataset;
  const attributes = JSON.parse(b64ToUtf8(rawAttributes));
  const {
    css,
    child_css,
    body
  } = attributes;
  return {
    path,
    name,
    label,
    body,
    css,
    child_css,
    type: 'module'
  };
};

export const getHublModuleElementInfo = domElement => {
  if (!domElement.classList.contains('hs-module')) {
    return null;
  } // Remove this condition once Blog:ModuleInsertionBackendHublParsing is ungated to all.


  if (domElement.dataset.attributes) {
    return parseHublModuleElement(domElement);
  }

  return {
    name: domElement.dataset.name
  };
};
export const makeHublModuleElementManager = doc => {
  const dom = parseDoc(doc);

  const getDOM = () => dom;

  const get = name => {
    const hublModuleElement = dom.querySelector(`.hs-module[data-name=${name}]`);

    if (!hublModuleElement) {
      return null;
    }

    const moduleInfo = getHublModuleElementInfo(hublModuleElement);
    return {
      element: hublModuleElement,
      info: moduleInfo
    };
  };

  const update = updatedModuleInfo => {
    const module = get(updatedModuleInfo.name);

    if (!module) {
      return;
    }

    module.element.replaceWith(makeHublModuleElement(updatedModuleInfo));
  };

  const remove = name => {
    const module = get(name);

    if (!module) {
      return;
    }

    module.element.remove();
  };

  const getAll = () => {
    const hublModuleElements = [...dom.querySelectorAll('.hs-module')];
    return hublModuleElements.map(hublModuleElement => {
      const moduleInfo = getHublModuleElementInfo(hublModuleElement);
      return {
        element: hublModuleElement,
        info: moduleInfo
      };
    });
  };

  return {
    get,
    getAll,
    update,
    remove,
    getDOM
  };
};