'use es6';
/* eslint-disable quotes */

export default {
  "supported": {
    "DE": true,
    "EN": true,
    "ES": true,
    "FI": true,
    "FR": true,
    "IT": true,
    "JA": true,
    "NL": true,
    "PL": true,
    "PT": true
  },
  "complete": {
    "DE": true,
    "EN": true,
    "ES": true,
    "FI": true,
    "FR": true,
    "IT": true,
    "JA": true,
    "NL": true,
    "PL": true,
    "PT": true
  },
  "customMessages": {
    "DE": {
      "followup_question": "Was könnten wir tun, um Ihr Nutzererlebnis zu verbessern?",
      "followup_question_nps": "Was ist der wichtigste Grund für Ihre Bewertung?",
      "followup_placeholder": "Helfen Sie uns, Ihre Bewertung besser zu verstehen.",
      "placeholder_text": "Helfen Sie uns, Ihre Bewertung besser zu verstehen.",
      "send": "O. K.",
      "csat_questions": {
        "ACCOUNTS_AND_BILLING": "Wie zufrieden sind Sie mit Ihrer Online-Account- und Abrechnungserfahrung?",
        "WORKFLOWS": "Wie zufrieden sind Sie mit der Erstellung der von Ihnen gewünschten Automatisierung?",
        "INBOX": "Wie zufrieden sind Sie mit dem Verwalten von Konversationen in HubSpot?",
        "TRIAL_EXPIRATION": "Wie zufrieden sind Sie mit Ihrem Testlauferfahrung?",
        "TRIAL_EXPIRATION_DASHBOARD": "Wie zufrieden sind Sie mit Ihrem Testlauferfahrung?",
        "CMS_CONTENT_DETAIL": "Wie zufrieden sind die Content-Management-Tools von HubSpot?",
        "CMS_DESIGN_MANAGER": "Wie sind Sie mit den Entwickler-Tools von HubSpot zufrieden?",
        "ADVANCED_BUILDER": "Wie zufrieden sind Sie mit den Einblicken, die die HubSpot-Berichterstattung liefert?",
        "FORECASTING": "Wie zufrieden sind Sie mit dem Verwalten Ihrer Deal-Pipeline in HubSpot?",
        "SOCIAL": "Wie zufrieden sind Sie mit dem Verwalten von Social Media in HubSpot?",
        "MARKETING_EMAIL": "Wie zufrieden sind Sie mit dem Verwalten von Marketing-E-Mails in HubSpot?",
        "FORMS": "Wie zufrieden sind Sie mit dem Verwalten von Formularen in HubSpot?",
        "CAMPAIGNS": "Wie zufrieden sind Sie mit dem Verwalten von Kampagnen in HubSpot?",
        "DEAL": "Wie zufrieden sind Sie mit dem Verwalten Ihrer Deal-Pipeline in HubSpot?",
        "ADS": "Wie zufrieden sind Sie mit dem Verwalten von Anzeigen in HubSpot?",
        "TASKS": "Wie zufrieden sind Sie mit dem Verwalten der Produktivität im Vertrieb in HubSpot?",
        "SEQUENCES": "Wie zufrieden sind Sie mit der Kontaktaufnahme Ihres Vertriebs in HubSpot?",
        "QUOTES": "Wie zufrieden sind Sie mit dem Verwalten von Angeboten in HubSpot?",
        "ANALYTICS": "Wie zufrieden sind Sie mit den Einblicken, die die HubSpot-Berichterstattung liefert?",
        "INDEX_CRM": "Wie zufrieden sind Sie mit Ihren Möglichkeiten, mit diesen Daten in HubSpot zu arbeiten?",
        "RECORD_CRM": "Wie zufrieden sind Sie mit Ihren Möglichkeiten, mit diesen Daten in HubSpot zu arbeiten?",
        "CHAT": "Wie zufrieden sind Sie mit dem Verwalten von Chats in HubSpot?",
        "DATA_SYNC": "Wie zufrieden sind Sie mit der Datensynchronisierungsfunktion in HubSpot?",
        "CRM_LISTS": "Wie zufrieden sind Sie mit der Erstellung von Listen in HubSpot?",
        "CRM_SETTINGS": "Wie zufrieden sind Sie mit dem Verwaltensfunktion für Objekte und Eigenschaften in HubSpot?",
        "PAYMENTS": "Wie zufrieden sind Sie mit dem Verwalten von Zahlungen in HubSpot?",
        "ADMIN_SETUP": "Wie zufrieden sind Sie damit, wie Kunden der Einstieg in HubSpot erleichtert wird?",
        "IMPORT": "Wie zufrieden sind Sie mit Ihrem Import-Tool?",
        "ADMIN_GOVERNANCE": "Wie zufrieden sind Sie mit der Kontrolle, die Sie über Ihren HubSpot-Account haben (als Super-Admin für Ihr Portal)?",
        "SINGLE_REPORT_VIEWER": "Wie zufrieden sind Sie mit den Einblicken, die die HubSpot-Berichterstattung liefert?",
        "REPORT_LIBRARY": "Wie zufrieden sind Sie mit den von HubSpot bereitgestellten Standardberichten?",
        "JOURNEY_ANALYTICS": "Wie zufrieden sind Sie mit den Einblicken, die die HubSpot-Berichterstattung liefert?",
        "SINGLE_OBJECT_BUILDER": "Wie zufrieden sind Sie mit den Einblicken, die die HubSpot-Berichterstattung liefert?",
        "MULTI_TOUCH_ATTRIBUTION": "Wie zufrieden sind Sie mit den Einblicken, die die HubSpot-Berichterstattung liefert?"
      },
      "wootric_recommend_target": "an Mitarbeiter oder Kollegen",
      "followup_thank_you": "Vielen Dank!",
      "ces_questions": {
        "TRIAL_EXPIRATION": "Wie einfach war es für Sie, die HubSpot-Funktionalität während dieses Testlaufs zu beurteilen?",
        "SETUP_COMPLETE": "Wie einfach war die Einrichtung von HubSpot für Sie?",
        "ACADEMY": "Wie einfach war die Verwendung der HubSpot Academy für Sie?",
        "PURCHASE_EXPERIENCE": "Wie einfach war es für Sie, HubSpot zu kaufen?"
      }
    },
    "EN": {
      "followup_question": "What could we do to improve your experience?",
      "followup_question_nps": "What's the most important reason for your score?",
      "followup_placeholder": "Help us improve by explaining your score.",
      "placeholder_text": "Help us improve by explaining your score.",
      "send": "Send",
      "csat_questions": {
        "ACCOUNTS_AND_BILLING": "How satisfied are you with your online account and billing experience?",
        "WORKFLOWS": "How satisfied are you with creating the automation you intended just now?",
        "INBOX": "How satisfied are you with managing conversations in HubSpot?",
        "TRIAL_EXPIRATION": "How satisfied are you with your overall Trial experience?",
        "TRIAL_EXPIRATION_DASHBOARD": "How satisfied are you with your overall Trial experience?",
        "CMS_CONTENT_DETAIL": "How satisfied are you with HubSpot's content management tools?",
        "CMS_DESIGN_MANAGER": "How satisfied are you with HubSpot's developer tools?",
        "ADVANCED_BUILDER": "How satisfied are you with getting insights from HubSpot reporting?",
        "FORECASTING": "How satisfied are you with managing your deal pipeline in HubSpot?",
        "SOCIAL": "How satisfied are you with managing social media in HubSpot?",
        "MARKETING_EMAIL": "How satisfied are you with managing marketing email in HubSpot?",
        "FORMS": "How satisfied are you with managing forms in HubSpot?",
        "CAMPAIGNS": "How satisfied are you with managing campaigns in HubSpot?",
        "DEAL": "How satisfied are you with managing your deal pipeline in HubSpot?",
        "ADS": "How satisfied are you with managing ads in HubSpot?",
        "TASKS": "How satisfied are you with managing sales productivity in HubSpot?",
        "SEQUENCES": "How satisfied are you with managing sales outreach in HubSpot?",
        "QUOTES": "How satisfied are you with managing quotes in HubSpot?",
        "ANALYTICS": "How satisfied are you with getting insights from HubSpot reporting?",
        "INDEX_CRM": "How satisfied are you with your ability to work with this data in HubSpot?",
        "RECORD_CRM": "How satisfied are you with your ability to work with this data in HubSpot?",
        "CHAT": "How satisfied are you with managing chat in HubSpot?",
        "DATA_SYNC": "How satisfied are you with your ability to sync data in HubSpot?",
        "CRM_LISTS": "How satisfied are you with building lists in HubSpot?",
        "CRM_SETTINGS": "How satisfied are you with your ability to manage objects and properties in HubSpot?",
        "PAYMENTS": "How satisfied are you with managing payments in HubSpot?",
        "ADMIN_SETUP": "How satisfied are you with getting people set up in HubSpot?",
        "IMPORT": "How satisfied are you with the import experience?",
        "ADMIN_GOVERNANCE": "How satisfied are you with the level of control that you have over your HubSpot account (as a super admin for your portal)?",
        "SINGLE_REPORT_VIEWER": "How satisfied are you with getting insights from HubSpot reporting?",
        "REPORT_LIBRARY": "How satisfied are you with out-of-the-box reports provided by HubSpot?",
        "JOURNEY_ANALYTICS": "How satisfied are you with getting insights from HubSpot reporting?",
        "SINGLE_OBJECT_BUILDER": "How satisfied are you with getting insights from HubSpot reporting?",
        "MULTI_TOUCH_ATTRIBUTION": "How satisfied are you with getting insights from HubSpot reporting?"
      },
      "wootric_recommend_target": "a coworker or colleague",
      "followup_thank_you": "Thank you!",
      "ces_questions": {
        "TRIAL_EXPIRATION": "How easy was it for you to assess HubSpot functionality during this trial?",
        "SETUP_COMPLETE": "How easy was it for you to get set up with HubSpot?",
        "ACADEMY": "How easy was it for you to use the Academy?",
        "PURCHASE_EXPERIENCE": "How easy was it for you to purchase HubSpot?"
      }
    },
    "ES": {
      "followup_question": "¿Qué podríamos hacer para mejorar tu experiencia?",
      "followup_question_nps": "¿Cuál es la razón más importante de tu calificación?",
      "followup_placeholder": "Ayúdanos a mejorar explicando tu puntaje.",
      "placeholder_text": "Ayúdanos a mejorar explicando tu puntaje.",
      "send": "Enviar",
      "csat_questions": {
        "ACCOUNTS_AND_BILLING": "¿Qué tan satisfecho estás con tu cuenta en línea y con tu experiencia de facturación?",
        "WORKFLOWS": "¿Cuál es tu nivel de satisfacción ahora mismo por crear la automatización que tenías prevista?",
        "INBOX": "¿Qué tan satisfecho estás al administrar tus conversaciones en HubSpot?",
        "TRIAL_EXPIRATION": "¿Qué tan satisfecho estás con tu experiencia de prueba general?",
        "TRIAL_EXPIRATION_DASHBOARD": "¿Qué tan satisfecho estás con tu experiencia de prueba general?",
        "CMS_CONTENT_DETAIL": "¿Qué tan satisfecho estás con las herramientas de gestión de contenido de HubSpot?",
        "CMS_DESIGN_MANAGER": "¿Qué tan satisfecho estás con las herramientas para desarrolladores de contenido de HubSpot?",
        "ADVANCED_BUILDER": "¿Cuánto te satisface la información que adquieres con los informes de HubSpot?",
        "FORECASTING": "¿Qué tan satisfecho estás al administrar tu pipeline de negocios en HubSpot?",
        "SOCIAL": "¿Qué tan satisfecho estás al administrar tus redes sociales en HubSpot?",
        "MARKETING_EMAIL": "¿Qué tan satisfecho estás al administrar tus correos electrónicos de marketing en HubSpot?",
        "FORMS": "¿Qué tan satisfecho estás al administrar tus formularios en HubSpot?",
        "CAMPAIGNS": "¿Qué tan satisfecho estás al administrar tus campañas en HubSpot?",
        "DEAL": "¿Qué tan satisfecho estás al administrar tu pipeline de negocios en HubSpot?",
        "ADS": "¿Qué tan satisfecho estás al administrar anuncios en HubSpot?",
        "TASKS": "¿Qué tan satisfecho estás al administrar la productividad de las ventas en HubSpot?",
        "SEQUENCES": "¿Qué tan satisfecho estás al administrar el alcance de las ventas en HubSpot?",
        "QUOTES": "¿Qué tan satisfecho estás al administrar tus cotizaciones en HubSpot?",
        "ANALYTICS": "¿Cuánto te satisface la información que adquieres con los informes de HubSpot?",
        "INDEX_CRM": "¿Qué tan satisfecho estás con tu capacidad de trabajar con estos datos en HubSpot?",
        "RECORD_CRM": "¿Qué tan satisfecho estás con tu capacidad de trabajar con estos datos en HubSpot?",
        "CHAT": "¿Qué tan satisfecho estás al administrar el chat en HubSpot?",
        "DATA_SYNC": "¿Cuánto te satisface tu capacidad de sincronizar datos en HubSpot?",
        "CRM_LISTS": "¿Qué tan satisfecho estás al crear listas en HubSpot?",
        "CRM_SETTINGS": "¿Cuánto te satisface tu capacidad de gestionar objetos y propiedades en HubSpot?",
        "PAYMENTS": "¿Cuánto te satisface la gestión de pagos en HubSpot?",
        "ADMIN_SETUP": "¿Cuánto te satisface promover la configuración de otros usuarios en HubSpot?",
        "IMPORT": "¿Qué tan satisfecho estás con tu experiencia de importación?",
        "ADMIN_GOVERNANCE": "¿Cuán satisfecho estás con el nivel de control que tienes sobre tu cuenta de HubSpot (como super administrador de tu portal)?",
        "SINGLE_REPORT_VIEWER": "¿Cuánto te satisface la información que adquieres con los informes de HubSpot?",
        "REPORT_LIBRARY": "¿Qué tan satisfecho estás con los informes de rápida instalación proporcionados por HubSpot?",
        "JOURNEY_ANALYTICS": "¿Cuánto te satisface la información que adquieres con los informes de HubSpot?",
        "SINGLE_OBJECT_BUILDER": "¿Cuánto te satisface la información que adquieres con los informes de HubSpot?",
        "MULTI_TOUCH_ATTRIBUTION": "¿Cuánto te satisface la información que adquieres con los informes de HubSpot?"
      },
      "wootric_recommend_target": "un compañero de trabajo o colega",
      "followup_thank_you": "¡Gracias!",
      "ces_questions": {
        "TRIAL_EXPIRATION": "¿Qué tan fácil te resultó evaluar la funcionalidad de HubSpot durante esta prueba?",
        "SETUP_COMPLETE": "¿Qué tan fácil te resulto prepararte para usar HubSpot?",
        "ACADEMY": "¿Qué tan fácil te resultó usar HubSpot Academy?",
        "PURCHASE_EXPERIENCE": "¿Qué tan fácil te resulto comprar HubSpot?"
      }
    },
    "FI": {
      "followup_question": "Mitä voisimme tehdä parantaaksemme kokemustasi?",
      "followup_question_nps": "Mikä on tärkein syy pistemäärääsi?",
      "followup_placeholder": "Auta meitä parantamaan selittämällä arvosanasi.",
      "placeholder_text": "Auta meitä parantamaan selittämällä arvosanasi.",
      "send": "Lähetä",
      "csat_questions": {
        "ACCOUNTS_AND_BILLING": "Kuinka tyytyväinen olet verkkotiliisi ja laskutuskokemukseesi?",
        "WORKFLOWS": "Kuinka tyytyväinen olet juuri nyt suunnittelemasi automaation luomiseen?",
        "INBOX": "Kuinka tyytyväinen olet HubSpotin keskusteluiden hallintaan?",
        "TRIAL_EXPIRATION": "Kuinka tyytyväinen olet yleisesti kokeilujaksoosi?",
        "TRIAL_EXPIRATION_DASHBOARD": "Kuinka tyytyväinen olet yleisesti kokeilujaksoosi?",
        "CMS_CONTENT_DETAIL": "Kuinka tyytyväinen olet HubSpotin sisällönhallintatyökaluihin?",
        "CMS_DESIGN_MANAGER": "Kuinka tyytyväinen olet HubSpotin kehittämistyökaluihin?",
        "ADVANCED_BUILDER": "Kuinka tyytyväinen olet siihen, että saat tietoa HubSpotin raportoinnista?",
        "FORECASTING": "Kuinka tyytyväinen olet kauppojen myyntiputken hallinnointiin HubSpotissa?",
        "SOCIAL": "Kuinka tyytyväinen olet sosiaalisen median hallintaan HubSpotissa?",
        "MARKETING_EMAIL": "Kuinka tyytyväinen olet markkinointiviestien hallintaan HubSpotissa?",
        "FORMS": "Kuinka tyytyväinen olet lomakkeiden hallintaan HubSpotissa?",
        "CAMPAIGNS": "Kuinka tyytyväinen olet kampanjoiden hallintaan HubSpotissa?",
        "DEAL": "Kuinka tyytyväinen olet kauppojen myyntiputken hallinnointiin HubSpotissa?",
        "ADS": "Kuinka tyytyväinen olet mainosten hallintaan HubSpotissa?",
        "TASKS": "Kuinka tyytyväinen olet myynnin tuottavuuden hallintaan HubSpotissa?",
        "SEQUENCES": "Kuinka tyytyväinen olet myynnin ulottuvuuden hallintaan HubSpotissa?",
        "QUOTES": "Kuinka tyytyväinen olet tarjousten hallintaan HubSpotissa?",
        "ANALYTICS": "Kuinka tyytyväinen olet siihen, että saat tietoa HubSpotin raportoinnista?",
        "INDEX_CRM": "Kuinka tyytyväinen olet kykyysi työskennellä tämän datan kanssa HubSpotissa?",
        "RECORD_CRM": "Kuinka tyytyväinen olet kykyysi työskennellä tämän datan kanssa HubSpotissa?",
        "CHAT": "Kuinka tyytyväinen olet chatin hallinnointiin HubSpotissa?",
        "DATA_SYNC": "Kuinka tyytyväinen olet kykyysi synkronoida tietoja HubSpotissa?",
        "CRM_LISTS": "Kuinka tyytyväinen olet luetteloiden luomiseen HubSpotissa?",
        "CRM_SETTINGS": "Kuinka tyytyväinen olet kykyysi hallinnoida objekteja ja ominaisuuksia HubSpotissa?",
        "PAYMENTS": "Kuinka tyytyväinen olet maksujen hallintaan HubSpotissa?",
        "ADMIN_SETUP": "Kuinka tyytyväinen olet siihen, miten saat ihmiset käyttämään HubSpotia?",
        "IMPORT": "Kuinka tyytyväinen olet tuontikokemukseen?",
        "ADMIN_GOVERNANCE": "Kuinka tyytyväinen olet HubSpot-tilisi hallinnan tasoon, joka sinulla on (portaalisi pääjärjestelmänvalvojana)?",
        "SINGLE_REPORT_VIEWER": "Kuinka tyytyväinen olet siihen, että saat tietoa HubSpotin raportoinnista?",
        "REPORT_LIBRARY": "Kuinka tyytyväinen olet HubSpotin toimittamiin valmiisiin raportteihin?",
        "JOURNEY_ANALYTICS": "Kuinka tyytyväinen olet siihen, että saat tietoa HubSpotin raportoinnista?",
        "SINGLE_OBJECT_BUILDER": "Kuinka tyytyväinen olet siihen, että saat tietoa HubSpotin raportoinnista?",
        "MULTI_TOUCH_ATTRIBUTION": "Kuinka tyytyväinen olet siihen, että saat tietoa HubSpotin raportoinnista?"
      },
      "wootric_recommend_target": "työkaveri tai kollega",
      "followup_thank_you": "Kiitos!",
      "ces_questions": {
        "TRIAL_EXPIRATION": "Kuinka helppoa HubSpotin toiminnan arvioiminen oli tämän kokeilun aikana?",
        "SETUP_COMPLETE": "Kuinka helppoa HubSpotin käyttöön ottaminen oli?",
        "ACADEMY": "Kuinka helppoa HubSpot-akatemian käyttäminen oli?",
        "PURCHASE_EXPERIENCE": "Kuinka helppoa sinun oli ostaa HubSpot?"
      }
    },
    "FR": {
      "followup_question": "Comment pourrions-nous améliorer votre expérience ?",
      "followup_question_nps": "Quelle est la principale raison pour laquelle vous avez donné cette note ?",
      "followup_placeholder": "Aidez-nous à nous améliorer en expliquant la note que vous avez attribué.",
      "placeholder_text": "Aidez-nous à nous améliorer en expliquant la note que vous avez attribué.",
      "send": "Envoyer",
      "csat_questions": {
        "ACCOUNTS_AND_BILLING": "Dans quelle mesure êtes-vous satisfait de votre compte en ligne et du processus de facturation ?",
        "WORKFLOWS": "Quel est votre degré de satisfaction concernant la création à l'instant de l'automatisation que vous souhaitiez ?",
        "INBOX": "Quel est votre degré de satisfaction concernant la gestion des conversations dans HubSpot ?",
        "TRIAL_EXPIRATION": "Quel est votre degré de satisfaction concernant votre expérience de participation à l'essai ?",
        "TRIAL_EXPIRATION_DASHBOARD": "Quel est votre degré de satisfaction concernant votre expérience de participation à l'essai ?",
        "CMS_CONTENT_DETAIL": "Dans quelle mesure êtes-vous satisfait des outils de gestion de contenu de HubSpot ?",
        "CMS_DESIGN_MANAGER": "Dans quelle mesure êtes-vous satisfait des outils de développeur de HubSpot ?",
        "ADVANCED_BUILDER": "Quel est votre degré de satisfaction concernant l'obtention d'informations via des rapports HubSpot ?",
        "FORECASTING": "Quel est votre degré de satisfaction concernant la gestion de votre pipeline de transactions dans HubSpot ?",
        "SOCIAL": "Quel est votre degré de satisfaction concernant la gestion des réseaux sociaux dans HubSpot ?",
        "MARKETING_EMAIL": "Quel est votre degré de satisfaction concernant la gestion des e-mails marketing dans HubSpot ?",
        "FORMS": "Quel est votre degré de satisfaction concernant la gestion des formulaires dans HubSpot ?",
        "CAMPAIGNS": "Quel est votre degré de satisfaction concernant la gestion des campagnes dans HubSpot ?",
        "DEAL": "Quel est votre degré de satisfaction concernant la gestion de votre pipeline de transactions dans HubSpot ?",
        "ADS": "Quel est votre degré de satisfaction concernant la gestion des publicités dans HubSpot ?",
        "TASKS": "Quel est votre degré de satisfaction concernant la gestion de la productivité de HubSpot Sales dans HubSpot ?",
        "SEQUENCES": "Quel est votre degré de satisfaction concernant la gestion des activités de vente dans HubSpot ?",
        "QUOTES": "Quel est votre degré de satisfaction concernant la gestion des devis dans HubSpot ?",
        "ANALYTICS": "Quel est votre degré de satisfaction concernant l'obtention d'informations via des rapports HubSpot ?",
        "INDEX_CRM": "Quel est votre degré de satisfaction concernant votre capacité à travailler avec ces données dans HubSpot ?",
        "RECORD_CRM": "Quel est votre degré de satisfaction concernant votre capacité à travailler avec ces données dans HubSpot ?",
        "CHAT": "Quel est votre degré de satisfaction concernant la gestion du chat dans HubSpot ?",
        "DATA_SYNC": "Quel est votre degré de satisfaction concernant votre capacité à synchroniser des données dans HubSpot ?",
        "CRM_LISTS": "Quel est votre degré de satisfaction concernant la création de listes dans HubSpot ?",
        "CRM_SETTINGS": "Quel est votre degré de satisfaction concernant votre capacité à gérer des objets et des propriétés dans HubSpot ?",
        "PAYMENTS": "Dans quelle mesure êtes-vous satisfait(e) de la gestion des paiements dans HubSpot ?",
        "ADMIN_SETUP": "Dans quelle mesure êtes-vous satisfait(e) de la définition des paramètres des personnes dans HubSpot ?",
        "IMPORT": "Dans quelle mesure êtes-vous satisfait de l'expérience d'import ?",
        "ADMIN_GOVERNANCE": "Dans quelle mesure êtes-vous satisfait du niveau de contrôle dont vous disposez sur votre compte HubSpot (en tant que super administrateur de votre portail) ?",
        "SINGLE_REPORT_VIEWER": "Quel est votre degré de satisfaction concernant l'obtention d'informations via des rapports HubSpot ?",
        "REPORT_LIBRARY": "Quel est votre degré de satisfaction concernant les rapports prêts à l'emploi fournis par HubSpot ?",
        "JOURNEY_ANALYTICS": "Quel est votre degré de satisfaction concernant l'obtention d'informations via des rapports HubSpot ?",
        "SINGLE_OBJECT_BUILDER": "Quel est votre degré de satisfaction concernant l'obtention d'informations via des rapports HubSpot ?",
        "MULTI_TOUCH_ATTRIBUTION": "Quel est votre degré de satisfaction concernant l'obtention d'informations via des rapports HubSpot ?"
      },
      "wootric_recommend_target": "un collègue",
      "followup_thank_you": "Merci !",
      "ces_questions": {
        "TRIAL_EXPIRATION": "Dans quelle mesure est-il facile pour vous d'évaluer les fonctionnalités de HubSpot pendant cet essai ? ",
        "SETUP_COMPLETE": "Dans quelle mesure la configuration des paramètres avec HubSpot est-elle facile ?",
        "ACADEMY": "Dans quelle mesure est-il facile pour vous d'utiliser HubSpot Academy ?",
        "PURCHASE_EXPERIENCE": "Dans quelle mesure l'achat de HubSpot est-il facile ?"
      }
    },
    "IT": {
      "followup_question": "Cosa potremmo fare per migliorare la tua esperienza?",
      "followup_question_nps": "Qual è il motivo più importante del punteggio?",
      "followup_placeholder": "Aiutaci a migliorare spiegandoci il tuo punteggio.",
      "placeholder_text": "Aiutaci a migliorare spiegandoci il tuo punteggio.",
      "send": "Invia",
      "csat_questions": {
        "ACCOUNTS_AND_BILLING": "Quanto sei soddisfatto del tuo account e della tua esperienza di fatturazione online?",
        "WORKFLOWS": "Quanto sei soddisfatto della creazione dell'automazione che desideravi?",
        "INBOX": "Quanto sei soddisfatto della gestione delle conversazioni in HubSpot?",
        "TRIAL_EXPIRATION": "Quanto sei soddisfatto della tua esperienza di prova complessiva?",
        "TRIAL_EXPIRATION_DASHBOARD": "Quanto sei soddisfatto della tua esperienza di prova complessiva?",
        "CMS_CONTENT_DETAIL": "Quanto sei soddisfatto degli strumenti di gestione dei contenuti di HubSpot?",
        "CMS_DESIGN_MANAGER": "Quanto sei soddisfatto degli strumenti di sviluppo di HubSpot?",
        "ADVANCED_BUILDER": "Qual è il tuo livello di soddisfazione delle informazioni acquisite dai rapporti HubSpot?",
        "FORECASTING": "Quanto sei soddisfatto della gestione della pipeline delle trattative in HubSpot?",
        "SOCIAL": "Quanto sei soddisfatto della gestione dei social media in HubSpot?",
        "MARKETING_EMAIL": "Quanto sei soddisfatto della gestione delle e-mail di marketing in HubSpot?",
        "FORMS": "Quanto sei soddisfatto della gestione dei form in HubSpot?",
        "CAMPAIGNS": "Quanto sei soddisfatto della gestione delle campagne in HubSpot?",
        "DEAL": "Quanto sei soddisfatto della gestione della pipeline delle trattative in HubSpot?",
        "ADS": "Quanto sei soddisfatto della gestione degli annunci in HubSpot?",
        "TASKS": "Quanto sei soddisfatto della gestione della produttività delle vendite in HubSpot?",
        "SEQUENCES": "Quanto sei soddisfatto della gestione dell'estensione delle vendite in HubSpot?",
        "QUOTES": "Quanto sei soddisfatto della gestione dei preventivi in HubSpot?",
        "ANALYTICS": "Qual è il tuo livello di soddisfazione delle informazioni acquisite dai rapporti HubSpot?",
        "INDEX_CRM": "Quanto sei soddisfatto della possibilità di lavorare con questi dati in HubSpot?",
        "RECORD_CRM": "Quanto sei soddisfatto della possibilità di lavorare con questi dati in HubSpot?",
        "CHAT": "Quanto sei soddisfatto della gestione della chat in HubSpot?",
        "DATA_SYNC": "Quanto sei soddisfatto della possibilità di sincronizzare i dati in HubSpot?",
        "CRM_LISTS": "Quanto sei soddisfatto della creazione degli elenchi in HubSpot?",
        "CRM_SETTINGS": "Quanto sei soddisfatto della possibilità di gestire gli oggetti e le proprietà in HubSpot?",
        "PAYMENTS": "Qual è il tuo livello di soddisfazione della gestione dei pagamenti in HubSpot?",
        "ADMIN_SETUP": "Qual è il tuo livello di soddisfazione nella configurazione delle persone in HubSpot?",
        "IMPORT": "Qual è il tuo livello di soddisfazione dell'esperienza di importazione?",
        "ADMIN_GOVERNANCE": "Quanto sei soddisfatto del livello di controllo sull'account HubSpot di cui disponi (in qualità di super amministratore per il tuo portale)?",
        "SINGLE_REPORT_VIEWER": "Qual è il tuo livello di soddisfazione delle informazioni acquisite dai rapporti HubSpot?",
        "REPORT_LIBRARY": "Qual è il tuo livello di soddisfazione dei rapporti predefiniti forniti da HubSpot?",
        "JOURNEY_ANALYTICS": "Qual è il tuo livello di soddisfazione delle informazioni acquisite dai rapporti HubSpot?",
        "SINGLE_OBJECT_BUILDER": "Qual è il tuo livello di soddisfazione delle informazioni acquisite dai rapporti HubSpot?",
        "MULTI_TOUCH_ATTRIBUTION": "Qual è il tuo livello di soddisfazione delle informazioni acquisite dai rapporti HubSpot?"
      },
      "wootric_recommend_target": "un collaboratore o un collega",
      "followup_thank_you": "Grazie.",
      "ces_questions": {
        "TRIAL_EXPIRATION": "Quanto è stato facile valutare la funzionalità di HubSpot durante la prova?",
        "SETUP_COMPLETE": "Quanto è stato facile configurare HubSpot?",
        "ACADEMY": "Quanto è stato facile usare Academy?",
        "PURCHASE_EXPERIENCE": "Quanto è stato facile acquistare HubSpot?"
      }
    },
    "JA": {
      "followup_question": "改善に向けてお気付きの点がございましたら、お聞かせください。",
      "followup_question_nps": "この評価の最も重要な理由を教えてください。",
      "followup_placeholder": "いただいた評価について理由をお聞かせください。今後の参考にさせていただきます。",
      "placeholder_text": "いただいた評価について理由をお聞かせください。今後の参考にさせていただきます。",
      "send": "送信",
      "csat_questions": {
        "ACCOUNTS_AND_BILLING": "オンラインの「アカウントと請求」機能にご満足いただけましたか？",
        "WORKFLOWS": "今まさに意図していた自動化の作成にご満足いただけましたか？",
        "INBOX": "HubSpotでのコミュニケーションの管理にご満足いただけましたか？",
        "TRIAL_EXPIRATION": "全体的なトライアル体験にご満足いただけましたか？",
        "TRIAL_EXPIRATION_DASHBOARD": "全体的なトライアル体験にご満足いただけましたか？",
        "CMS_CONTENT_DETAIL": "HubSpotのコンテンツ管理ツールにご満足いただけましたか？",
        "CMS_DESIGN_MANAGER": "HubSpotの開発者ツールにご満足いただけましたか？",
        "ADVANCED_BUILDER": "HubSpotのレポート機能による分析にご満足いただけましたか？",
        "FORECASTING": "HubSpotでの取引パイプラインの管理にご満足いただけましたか？",
        "SOCIAL": "HubSpotでのソーシャルメディアの管理にご満足いただけましたか？",
        "MARKETING_EMAIL": "HubSpotでのマーケティングEメールの管理にご満足いただけましたか？",
        "FORMS": "HubSpotでのフォームの管理にご満足いただけましたか？",
        "CAMPAIGNS": "HubSpotでのキャンペーンの管理にご満足いただけましたか？",
        "DEAL": "HubSpotでの取引パイプラインの管理にご満足いただけましたか？",
        "ADS": "HubSpotでの広告の管理にご満足いただけましたか？",
        "TASKS": "HubSpotでの営業の生産性管理にご満足いただけましたか？",
        "SEQUENCES": "HubSpotでの営業アプローチの管理にご満足いただけましたか？",
        "QUOTES": "HubSpotでの見積もり管理にご満足いただけましたか？",
        "ANALYTICS": "HubSpotのレポート機能による分析にご満足いただけましたか？",
        "INDEX_CRM": "HubSpot上でこのようなデータを扱う機能にご満足いただけましたか？",
        "RECORD_CRM": "HubSpot上でこのようなデータを扱う機能にご満足いただけましたか？",
        "CHAT": "HubSpotでのチャットの管理にご満足いただけましたか？",
        "DATA_SYNC": "HubSpot上でデータを同期する機能にご満足いただけましたか？",
        "CRM_LISTS": "HubSpotでのリストの作成にご満足いただけましたか？",
        "CRM_SETTINGS": "HubSpotでのオブジェクトやプロパティーを管理する機能にご満足いただけましたか？",
        "PAYMENTS": "HubSpotでの支払い管理にご満足いただけましたか？",
        "ADMIN_SETUP": "HubSpotでの人のセットアップにご満足いただけましたか？",
        "IMPORT": "インポート環境にご満足いただけましたか？",
        "ADMIN_GOVERNANCE": "HubSpotアカウントに対する制御レベルにご満足いただけましたか（ポータルのスーパー管理者として）？",
        "SINGLE_REPORT_VIEWER": "HubSpotのレポート機能による分析にご満足いただけましたか？",
        "REPORT_LIBRARY": "HubSpotが提供する設定不要のレポートにご満足いただけましたか？",
        "JOURNEY_ANALYTICS": "HubSpotのレポート機能による分析にご満足いただけましたか？",
        "SINGLE_OBJECT_BUILDER": "HubSpotのレポート機能による分析にご満足いただけましたか？",
        "MULTI_TOUCH_ATTRIBUTION": "HubSpotのレポート機能による分析にご満足いただけましたか？"
      },
      "wootric_recommend_target": "同僚や友人に",
      "followup_thank_you": "ありがとうございます。",
      "ces_questions": {
        "TRIAL_EXPIRATION": "今回のトライアル期間中、お客さまにとってHubSpot機能の評価は簡単でしたか？",
        "SETUP_COMPLETE": "お客さまにとって、HubSpotの初期設定は簡単でしたか？",
        "ACADEMY": "お客さまにとって、アカデミーの利用は簡単でしたか？",
        "PURCHASE_EXPERIENCE": "お客さまにとって、HubSpotのご購入は簡単でしたか？"
      }
    },
    "NL": {
      "followup_question": "Hoe kunnen we je een betere ervaring bieden?",
      "followup_question_nps": "Wat is de belangrijkste reden voor je score?",
      "followup_placeholder": "Help ons het product te verbeteren door je score toe te lichten.",
      "placeholder_text": "Help ons het product te verbeteren door je score toe te lichten.",
      "send": "Verzenden",
      "csat_questions": {
        "ACCOUNTS_AND_BILLING": "Hoe tevreden ben je met je online-account en de facturering?",
        "WORKFLOWS": "Hoe tevreden ben je met je met het maken van de gewenste automatisering?",
        "INBOX": "Hoe tevreden ben je met je met het beheren van gesprekken in HubSpot?",
        "TRIAL_EXPIRATION": "Hoe tevreden ben je met je algehele trial-ervaring?",
        "TRIAL_EXPIRATION_DASHBOARD": "Hoe tevreden ben je met je algehele trial-ervaring?",
        "CMS_CONTENT_DETAIL": "Hoe tevreden ben je met de beheertools voor inhoud van HubSpot?",
        "CMS_DESIGN_MANAGER": "Hoe tevreden ben je met de tools voor ontwikkelaars van HubSpot?",
        "ADVANCED_BUILDER": "Hoe tevreden ben je met de inzichten in HubSpot-rapporten?",
        "FORECASTING": "Hoe tevreden ben je met je met het beheren van je dealpijplijn in HubSpot?",
        "SOCIAL": "Hoe tevreden ben je met je met het beheren van je social media in HubSpot?",
        "MARKETING_EMAIL": "Hoe tevreden ben je met je met het beheren van je marketingmail in HubSpot?",
        "FORMS": "Hoe tevreden ben je met je met het beheren van formulieren in HubSpot?",
        "CAMPAIGNS": "Hoe tevreden ben je met je met het beheren van campagnes in HubSpot?",
        "DEAL": "Hoe tevreden ben je met je met het beheren van je dealpijplijn in HubSpot?",
        "ADS": "Hoe tevreden ben je met je met het beheren van advertenties in HubSpot?",
        "TASKS": "Hoe tevreden ben je met je met het beheren van je verkoopproductiviteit in HubSpot?",
        "SEQUENCES": "Hoe tevreden ben je met je met het beheren van je verkoopoutreach in HubSpot?",
        "QUOTES": "Hoe tevreden ben je met je met het beheren van prijsopgaven in HubSpot?",
        "ANALYTICS": "Hoe tevreden ben je met de inzichten in HubSpot-rapporten?",
        "INDEX_CRM": "Hoe tevreden ben je met je met de mogelijkheid om met deze gegevens te werken in HubSpot?",
        "RECORD_CRM": "Hoe tevreden ben je met je met de mogelijkheid om met deze gegevens te werken in HubSpot?",
        "CHAT": "Hoe tevreden ben je met je met het beheren van chat in HubSpot?",
        "DATA_SYNC": "Hoe tevreden ben je met je met de mogelijkheid om gegevens te synchroniseren in HubSpot?",
        "CRM_LISTS": "Hoe tevreden ben je met je met het maken van lijsten in HubSpot?",
        "CRM_SETTINGS": "Hoe tevreden ben je met je met de mogelijkheid om objecten en eigenschappen te beheren in HubSpot?",
        "PAYMENTS": "Hoe tevreden ben je met het beheren van betalingen in HubSpot?",
        "ADMIN_SETUP": "Hoe tevreden ben je met het treffen van voorbereidingen voor gebruikers in HubSpot?",
        "IMPORT": "Hoe tevreden ben je met de importervaring?",
        "ADMIN_GOVERNANCE": "Hoe tevreden ben je met de controle die je hebt over je HubSpot-account (als superbeheerder voor je portaal)?",
        "SINGLE_REPORT_VIEWER": "Hoe tevreden ben je met de inzichten in HubSpot-rapporten?",
        "REPORT_LIBRARY": "Hoe tevreden ben je met de kant-en-klare rapporten van HubSpot?",
        "JOURNEY_ANALYTICS": "Hoe tevreden ben je met de inzichten in HubSpot-rapporten?",
        "SINGLE_OBJECT_BUILDER": "Hoe tevreden ben je met de inzichten in HubSpot-rapporten?",
        "MULTI_TOUCH_ATTRIBUTION": "Hoe tevreden ben je met de inzichten in HubSpot-rapporten?"
      },
      "wootric_recommend_target": "een collega",
      "followup_thank_you": "Bedankt!",
      "ces_questions": {
        "TRIAL_EXPIRATION": "Hoe gemakkelijk kreeg je toegang tot de HubSpot-functionaliteit in deze proefversie?",
        "SETUP_COMPLETE": "Hoe gemakkelijk was het om alles in te stellen voor HubSpot?",
        "ACADEMY": "Hoe gemakkelijk was het om de Academy te gebruiken?",
        "PURCHASE_EXPERIENCE": "Hoe gemakkelijk was het voor jou om HubSpot aan te kopen?"
      }
    },
    "PL": {
      "followup_question": "Co możemy zrobić, aby poprawić nasze usługi?",
      "followup_question_nps": "Co jest najważniejszym powodem otrzymania takiego wyniku?",
      "followup_placeholder": "Wyjaśnimy Ci powody przyznania takiej oceny, co pomoże ulepszyć witrynę.",
      "placeholder_text": "Wyjaśnimy Ci powody przyznania takiej oceny, co pomoże ulepszyć witrynę.",
      "send": "Wyślij",
      "csat_questions": {
        "ACCOUNTS_AND_BILLING": "Jak oceniasz działanie internetowego konta i funkcji rozliczeniowych?",
        "WORKFLOWS": "Jak oceniasz funkcjonalność tworzenia zamierzonej przez Ciebie automatyzacji?",
        "INBOX": "Jak oceniasz funkcjonalność zarządzania konwersacjami na platformie HubSpot?",
        "TRIAL_EXPIRATION": "Jak całościowo oceniasz okres próbny?",
        "TRIAL_EXPIRATION_DASHBOARD": "Jak całościowo oceniasz okres próbny?",
        "CMS_CONTENT_DETAIL": "Jak oceniasz narzędzia do zarządzania treściami na platformie HubSpot?",
        "CMS_DESIGN_MANAGER": "Jak oceniasz narzędzia programistyczne na platformie HubSpot?",
        "ADVANCED_BUILDER": "Jak oceniasz informacje otrzymywane w ramach funkcji sprawozdawczych na platformie HubSpot?",
        "FORECASTING": "Jak oceniasz funkcjonalność zarządzania procesem sprzedaży na platformie HubSpot?",
        "SOCIAL": "Jak oceniasz funkcjonalność zarządzania mediami społecznościowymi na platformie HubSpot?",
        "MARKETING_EMAIL": "Jak oceniasz funkcjonalność zarządzania marketingowymi wiadomościami e-mail na platformie HubSpot?",
        "FORMS": "Jak oceniasz funkcjonalność zarządzania formularzami na platformie HubSpot?",
        "CAMPAIGNS": "Jak oceniasz funkcjonalność zarządzania kampaniami na platformie HubSpot?",
        "DEAL": "Jak oceniasz funkcjonalność zarządzania procesem sprzedaży na platformie HubSpot?",
        "ADS": "Jak oceniasz funkcjonalność zarządzania reklamami na platformie HubSpot?",
        "TASKS": "Jak oceniasz funkcjonalność zarządzania wydajnością pracy działu sprzedaży na platformie HubSpot?",
        "SEQUENCES": "Jak oceniasz funkcjonalność zarządzania zasięgiem działań sprzedażowych na platformie HubSpot?",
        "QUOTES": "Jak oceniasz funkcjonalność zarządzania wycenami na platformie HubSpot?",
        "ANALYTICS": "Jak oceniasz informacje otrzymywane w ramach funkcji sprawozdawczych na platformie HubSpot?",
        "INDEX_CRM": "Jak oceniasz zdolność pracy z tymi danymi na platformie HubSpot?",
        "RECORD_CRM": "Jak oceniasz zdolność pracy z tymi danymi na platformie HubSpot?",
        "CHAT": "Jak oceniasz funkcjonalność zarządzania czatem na platformie HubSpot?",
        "DATA_SYNC": "Jak oceniasz zdolność synchronizowania danych na platformie HubSpot?",
        "CRM_LISTS": "Jak oceniasz funkcjonalność tworzenia list na platformie HubSpot?",
        "CRM_SETTINGS": "Jak oceniasz zdolność zarządzania obiektami i właściwościami na platformie HubSpot?",
        "PAYMENTS": "Jak oceniasz funkcjonalność zarządzania płatnościami na platformie HubSpot?",
        "ADMIN_SETUP": "Jak oceniasz szybkość wdrażania użytkowników w funkcjonalności HubSpot?",
        "IMPORT": "Jak oceniasz funkcję importowania?",
        "ADMIN_GOVERNANCE": "W jakim stopniu podoba Ci się poziom kontroli, jaką masz nad swoim kontem HubSpot (jako administrator o rozszerzonych uprawnieniach portalu)?",
        "SINGLE_REPORT_VIEWER": "Jak oceniasz informacje otrzymywanie w ramach funkcji sprawozdawczych na platformie HubSpot?",
        "REPORT_LIBRARY": "Jak oceniasz gotowe raporty dostarczane przez HubSpot?",
        "JOURNEY_ANALYTICS": "Jak oceniasz informacje otrzymywane w ramach funkcji sprawozdawczych na platformie HubSpot?",
        "SINGLE_OBJECT_BUILDER": "Jak oceniasz informacje otrzymywane w ramach funkcji sprawozdawczych na platformie HubSpot?",
        "MULTI_TOUCH_ATTRIBUTION": "Jak oceniasz informacje otrzymywane w ramach funkcji sprawozdawczych na platformie HubSpot?"
      },
      "wootric_recommend_target": "współpracownik lub kolega z pracy",
      "followup_thank_you": "Dziękujemy!",
      "ces_questions": {
        "TRIAL_EXPIRATION": "Jak łatwo było Ci ocenić funkcjonalność HubSpot podczas korzystania z wersji próbnej?",
        "SETUP_COMPLETE": "Jak łatwo było skonfigurować HubSpot?",
        "ACADEMY": "Jak łatwo było korzystać z HubSpot Academy?",
        "PURCHASE_EXPERIENCE": "Jak łatwy był zakup HubSpot?"
      }
    },
    "PT": {
      "followup_question": "O que poderíamos fazer para melhorar sua experiência?",
      "followup_question_nps": "Qual é o motivo mais importante para sua pontuação?",
      "followup_placeholder": "Ajude-nos a melhorar explicando sua pontuação.",
      "placeholder_text": "Ajude-nos a melhorar explicando sua pontuação.",
      "send": "Enviar",
      "csat_questions": {
        "ACCOUNTS_AND_BILLING": "Quão satisfeito você está com sua conta on-line e com a experiência de cobrança?",
        "WORKFLOWS": "Quão satisfeito você está com a criação da automação desejada?",
        "INBOX": "Quão satisfeito você está com o gerenciamento de conversas no HubSpot?",
        "TRIAL_EXPIRATION": "Quão satisfeito você está com sua experiência geral na avaliação?",
        "TRIAL_EXPIRATION_DASHBOARD": "Quão satisfeito você está com sua experiência geral na avaliação?",
        "CMS_CONTENT_DETAIL": "Quão satisfeito você está com as ferramentas de gerenciamento de conteúdo da HubSpot?",
        "CMS_DESIGN_MANAGER": "Quão satisfeito você está com as ferramentas para desenvolvedores da HubSpot?",
        "ADVANCED_BUILDER": "Como está sua satisfação em obter insights dos relatórios da HubSpot?",
        "FORECASTING": "Quão satisfeito você está com o gerenciamento do seu pipeline de negócios no HubSpot?",
        "SOCIAL": "Quão satisfeito você está com o gerenciamento das suas redes sociais no HubSpot?",
        "MARKETING_EMAIL": "Quão satisfeito você está com o gerenciamento dos seus e-mails de marketing no HubSpot?",
        "FORMS": "Quão satisfeito você está com o gerenciamento de formulários no HubSpot?",
        "CAMPAIGNS": "Quão satisfeito você está com o gerenciamento de campanhas no HubSpot?",
        "DEAL": "Quão satisfeito você está com o gerenciamento do seu pipeline de negócios no HubSpot?",
        "ADS": "Quão satisfeito você está com o gerenciamento de anúncios no HubSpot?",
        "TASKS": "Quão satisfeito você está com o gerenciamento da produtividade de vendas no HubSpot?",
        "SEQUENCES": "Quão satisfeito você está com o gerenciamento do alcance das vendas no HubSpot?",
        "QUOTES": "Quão satisfeito você está com o gerenciamento de orçamentos no HubSpot?",
        "ANALYTICS": "Como está sua satisfação em obter insights dos relatórios da HubSpot?",
        "INDEX_CRM": "Quão satisfeito você está com a capacidade de trabalhar com esses dados no HubSpot?",
        "RECORD_CRM": "Quão satisfeito você está com a capacidade de trabalhar com esses dados no HubSpot?",
        "CHAT": "Quão satisfeito você está com o gerenciamento de chats no HubSpot?",
        "DATA_SYNC": "Quão satisfeito você está com a capacidade de sincronizar dados no HubSpot?",
        "CRM_LISTS": "Qual seu nível de satisfação com as listas de construção no HubSpot?",
        "CRM_SETTINGS": "Qual seu nível de satisfação com sua capacidade de gerenciar objetos e propriedades no HubSpot?",
        "PAYMENTS": "Qual é seu nível de satisfação com o gerenciamento de pagamentos no HubSpot?",
        "ADMIN_SETUP": "Qual é seu nível de satisfação com a configuração de pessoas no HubSpot?",
        "IMPORT": "Quão satisfeito você está com a experiência de importação?",
        "ADMIN_GOVERNANCE": "Qual é seu grau de satisfação com o nível de controle que você tem sobre sua conta da HubSpot (como superadministrador do seu portal)?",
        "SINGLE_REPORT_VIEWER": "Como está sua satisfação em obter insights dos relatórios da HubSpot?",
        "REPORT_LIBRARY": "Qual é seu nível de satisfação com os relatórios prontos para uso fornecidos pela HubSpot?",
        "JOURNEY_ANALYTICS": "Como está sua satisfação em obter insights dos relatórios da HubSpot?",
        "SINGLE_OBJECT_BUILDER": "Como está sua satisfação em obter insights dos relatórios da HubSpot?",
        "MULTI_TOUCH_ATTRIBUTION": "Como está sua satisfação em obter insights dos relatórios da HubSpot?"
      },
      "wootric_recommend_target": "um colega",
      "followup_thank_you": "Obrigado!",
      "ces_questions": {
        "TRIAL_EXPIRATION": "Foi fácil avaliar a funcionalidade do HubSpot durante o teste?",
        "SETUP_COMPLETE": "Foi fácil começar a usar o HubSpot?",
        "ACADEMY": "Foi fácil usar o Academy?",
        "PURCHASE_EXPERIENCE": "Foi fácil comprar o HubSpot?"
      }
    }
  },
  "permission_labels": {
    "DE": "Ich gestatte HubSpot, mich hinsichtlich meines Feedbacks zu kontaktieren.",
    "EN": "You can contact me about my feedback.",
    "ES": "Puedes contactarme para hablar sobre mis comentarios.",
    "FI": "Voit ottaa minuun yhteyttä antamani palautteen pohjalta.",
    "FR": "Vous pouvez me contacter au sujet de mes commentaires.",
    "IT": "Potete contattarmi in merito al mio feedback.",
    "JA": "フィードバックの詳細について、別途連絡を受けても構わない。",
    "NL": "Je mag contact met me opnemen over mijn feedback.",
    "PL": "Możesz się ze mną kontaktować w sprawie moich opinii.",
    "PT": "Você pode entrar em contato comigo sobre meu feedback."
  },
  "extra_questions": {
    "DE": {
      "question_text": "Was ist Ihre Rolle bzw. beschreibt Ihre Rolle am besten?",
      "button": "OK",
      "banner": "Noch eine Frage:",
      "answer_options": ["Rolle auswählen", "Agentur/Partner/Freiberufler", "Kundenservice-Leiter/Manager", "Kundendienstmitarbeiter", "Datenanalyse", "Entwickler/Designer", "Marketer", "Marketing-Direktor/Manager", "Operations-Fachkraft", "Betriebsleiter/Manager", "Vertriebsleiter/Manager", "Vertriebsmitarbeiter", "Von allem etwas", "Sonstiger Leiter/Manager", "Sonstige", "Ich möchte nicht antworten"]
    },
    "EN": {
      "question_text": "Which one of these describes your role?",
      "button": "Done",
      "banner": "One more thing:",
      "answer_options": ["Choose one role", "Agency / Partner / Freelancer", "Customer Service Director / Manager", "Customer Service Rep", "Data Analyst", "Developer / Designer", "Marketer", "Marketing Director / Manager", "Operations Specialist", "Operations Director / Manager", "Sales Director / Manager", "Salesperson", "I do a little bit of everything", "Other Director / Manager", "Other", "I prefer not to say"]
    },
    "ES": {
      "question_text": "¿Cuál de las siguientes opciones describe tu rol?",
      "button": "Listo",
      "banner": "Una cosa más:",
      "answer_options": ["Elige un rol", "Agencia / Partner / Freelancer", "Director / Gerente de Servicio al Cliente", "Servicio al Cliente", "Analista de datos", "Desarrollador / Diseñador", "Especialista en marketing", "Director de marketing/Gerente", "Especialista en operaciones", "Director/mánager de operaciones", "Dirección o gerencia de ventas", "Ventas", "Hago un poco de todo", "Otro Director / Gerente", "Otro", "Prefiero no decirlo"]
    },
    "FI": {
      "question_text": "Mikä näistä kuvailee rooliasi?",
      "button": "Valmis",
      "banner": "Vielä yksi juttu:",
      "answer_options": ["Valitse yksi rooli", "Edustus / Kumppani / Freelancer", "Asiakaspalvelun ohjaaja / Päällikkö", "Asiakaspalvelun vastaava", "Data-analyytikko", "Kehittäjä / Suunnittelija", "Markkinoija", "Markkinoinnin ohjaaja / Päällikkö", "Operaatioasiantuntija", "Operaatio-ohjaaja / Päällikkö", "Myynnin ohjaaja / Päällikkö", "Myyntihenkilö", "Teen vähän kaikkea", "Toinen ohjaaja / Päällikkö", "Muu", "En halua kertoa"]
    },
    "FR": {
      "question_text": "Comment décrivez-vous votre rôle ?",
      "button": "Terminé",
      "banner": "Une dernière chose :",
      "answer_options": ["Sélectionnez un rôle", "Agence / Partenaire / Freelance", "Directeur / Responsable du service client", "Représentant du service client", "Data Analyst", "Développeur / Designer", "Spécialiste marketing", "Directeur Marketing / Responsable", "Spécialiste des opérations", "Directeur / responsable des opérations", "Directeur des ventes/Responsable", "Membre de l'équipe commerciale", "Je touche un peu à tout", "Autre directeur / Responsable", "Autre", "Je préfère ne pas répondre"]
    },
    "IT": {
      "question_text": "Quale di questi descrive il tuo ruolo?",
      "button": "Operazione completata",
      "banner": "Ancora una cosa:",
      "answer_options": ["Scegli un ruolo", "Agenzia/Partner/Freelancer", "Direttore del servizio clienti/manager", "Rappresentante del servizio clienti", "Analista di dati", "Sviluppatore/designer", "Marketer", "Direttore marketing/responsabile", "Specialista delle operazioni", "Direttore/Responsabile delle operazioni", "Direttore delle vendite/responsabile", "Rappresentante delle vendite", "Faccio un po' di tutto", "Altro direttore/manager", "Altro ", "Preferisco non dichiararlo"]
    },
    "JA": {
      "question_text": "あなたの役割に近いものをお選びください。",
      "button": "完了",
      "banner": "もう1点、お聞かせください。",
      "answer_options": ["役割を1つ選択", "代理店／パートナー／フリーランス", "カスタマーサービス責任者／管理者", "カスタマーサービス担当者", "データ分析、データアナリスト", "開発者／デザイナー", "マーケティング担当者", "マーケティング責任者／管理者", "オペレーション担当者", "オペレーションディレクター／マネージャー", "営業責任者／管理者", "営業担当者", "全事業領域に部分的に関与", "その他の責任者／管理者", "その他", "回答しない"]
    },
    "NL": {
      "question_text": "Welke van deze rollen is jouw rol?",
      "button": "Klaar",
      "banner": "Er is nog iets:",
      "answer_options": ["Kies een rol", "Bureau/Partner/Freelancer", "Directeur/manager van klantenservice", "Medewerker klantenservice", "Data-analist", "Ontwikkelaar/ontwerper", "Marketingmedewerker", "Directeur/manager marketing", "Specialist Operations", "Operations-directeur/manager", "Salesdirecteur/-manager", "Verkoper", "Ik doe van alles wat", "Andere directeur/manager", "Overig", "Dat vertel ik liever niet"]
    },
    "PL": {
      "question_text": "Która z poniższych ról najlepiej Cię charakteryzuje?",
      "button": "Gotowe",
      "banner": "Jeszcze jedna sprawa:",
      "answer_options": ["Wybierz jedną rolę", "Agencja/partner/samozatrudnienie", "Dyrektor ds. obsługi klienta / menedżer", "Przedstawiciel ds. obsługi klienta", "Analityk danych", "Programista / projektant", "Marketer", "Dyrektor ds. marketingu / menedżer", "Specjalista ds. działań operacyjnych", "Dyrektor operacyjny / menedżer", "Dyrektor ds. sprzedaży / menedżer", "Sprzedawca", "Robię różne rzeczy", "Inny dyrektor / menedżer", "Inne", "Wolę nie podawać"]
    },
    "PT": {
      "question_text": "Qual delas descreve sua função?",
      "button": "Concluído",
      "banner": "Só mais uma coisa:",
      "answer_options": ["Escolha uma função", "Agência/Parceiro/Freelancer", "Diretor/Gerente de atendimento ao cliente", "Representante de atendimento ao cliente", "Analista de informações", "Desenvolvedor/Designer", "Profissional de marketing", "Diretor de Marketing/Gerente", "Especialista em operações", "Diretor de Operações/Gerente", "Diretor de Vendas/Gerente", "Vendedor", "Faço um pouco de tudo", "Outro diretor/gerente", "Outro", "Prefiro não dizer"]
    }
  },
  "field_required": {
    "DE": "Hierbei handelt es sich um ein Pflichtfeld.",
    "EN": "This field is required",
    "ES": "Este campo es obligatorio",
    "FI": "Tämä kenttä on pakollinen",
    "FR": "Ce champ est obligatoire",
    "IT": "Questo campo è obbligatorio",
    "JA": "このフィールドは必須です",
    "NL": "Dit veld is verplicht",
    "PL": "To pole jest wymagane",
    "PT": "Este campo é obrigatório"
  }
};