import http from 'hub-http/clients/apiClient'; // safeMode here is used to prevent middleware from doing dangerous operations (such as a redirect)
// https://product.hubteam.com/docs/frontend/docs/hub-http.html#safemode

export function getTourStatus(tourKey, safeMode = false) {
  return http.get(`/onboarding/tours/v1/status/${tourKey}`, {
    safeMode
  });
}
export function setTourStatus(tourKey, status, safeMode = false) {
  return http.post(`/onboarding/tours/v1/status`, {
    safeMode,
    data: {
      tourKey,
      status
    }
  });
}
export function deleteTourStatus(tourKey, safeMode = false) {
  return http.delete(`/onboarding/tours/v1/status/${tourKey}`, {
    safeMode
  });
}