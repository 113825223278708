import { createSelector } from 'reselect';
import { basicSelector } from 'ContentEditorUI/redux/selectors/helpers';
const getBrandColorsPayload = basicSelector(state => state.brandSettings.colors);
export const getBrandColors = createSelector(getBrandColorsPayload, colorsPayload => {
  if (colorsPayload && colorsPayload.length > 0) {
    // Return only an array of hex colors, not any other metadata
    return colorsPayload.map(colorObj => colorObj.color);
  }

  return undefined; //empty array will cause nothing to be rendered if you return undefined the favorites section is rendered.
});