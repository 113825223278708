'use es6';

import Immutable from 'immutable';
import { FETCH_CONTENT_SCHEMA_SUCCEEDED, SET_DIRTY_FLAG, SET_MODULE_BODY, SET_MODULE_SMART_OBJECT_BODY, MERGE_MODULE_BODY, SET_DEPRECATED_STYLES_FLAGS, MERGE_MODULE_SMART_OBJECT_BODY, UPDATE_CONTENT_MODEL_THEME_PAGE_OVERRIDES, LAYOUT_SECTION_MODULE_DELETED, LAYOUT_SECTION_COLUMN_DELETED, LAYOUT_SECTION_ROW_DELETED, UPDATED_MODULE_SMART_RULES, REMOVED_MODULE_SMART_RULES, SET_BREAKPOINT_STYLES_FOR_TREE_NODE, MODULE_SET_LAYOUT_STYLES, LAYOUT_SECTION_CELL_SET_LAYOUT_STYLES, LAYOUT_SECTION_ROW_SET_LAYOUT_STYLES, SET_MODULE_BREAKPOINT_STYLES, SET_MODULE_STYLES, LAYOUT_SECTION_ROW_SET_VERTICAL_ALIGNMENT, PERMANENTLY_DISABLE_SAVING, CONTENT_SAVE_REQUEST_STARTED, CONTENT_SAVE_REQUEST_SUCCEEDED, CONTENT_SAVE_REQUEST_FAILED, RELOAD_WITHOUT_WARNING, LAYOUT_SECTION_COLUMN_INSERTED_AFTER, LAYOUT_SECTION_COLUMN_INSERTED_BEFORE, LAYOUT_SECTION_ROW_INSERTED_AFTER, LAYOUT_SECTION_ROW_INSERTED_BEFORE, LAYOUT_SECTION_ROW_CLONED_BELOW, LAYOUT_SECTION_CELL_CLONED_TO_RIGHT, UPDATE_CONTENT_MODEL_HTML_TITLE } from 'ContentEditorUI/redux/actions/actionTypes';
import { isLocked } from 'ContentEditorUI/utils/errorUtils';
const initialState = Immutable.Map({
  saveRequestId: 0,
  permanentlyDisabled: false,
  isDirty: false,
  isHardSavePending: false,
  lastSaveSuccessful: undefined,
  lastHardSaveTs: 0,
  preventUnsavedChangesWarning: false,
  saveRequested: false,
  lastSaveWasAutoSave: false,
  shouldSaveImmediately: false
}); // The saveRequestId is just an arbritrary number that increments each time an action that would could set
// `isDirty` to true is fired. This lets us track when those actions stop coming in, so we can better
// debounce trigger an autosave.

const setSaveState = (state, {
  isDirty,
  isHardSavePending,
  isFromAutoSaveContainer,
  shouldSaveImmediately = false
}) => {
  const newSaveRequestId = isDirty && !isFromAutoSaveContainer ? state.get('saveRequestId') + 1 : state.get('saveRequestId');
  const newIsHardSavePending = state.get('isHardSavePending') || isHardSavePending;
  return state.merge({
    isDirty,
    saveRequestId: newSaveRequestId,
    isHardSavePending: newIsHardSavePending,
    shouldSaveImmediately
  });
};

const SaveData = (state = initialState, {
  type,
  payload,
  response,
  error
}) => {
  switch (type) {
    case FETCH_CONTENT_SCHEMA_SUCCEEDED:
      return setSaveState(state, {
        isDirty: response.isDirty || false,
        shouldSaveImmediately: false
      });

    case SET_DIRTY_FLAG:
      return setSaveState(state, {
        isDirty: payload.isDirty,
        isHardSavePending: payload.isHardSavePending,
        isFromAutoSaveContainer: payload.isFromAutoSaveContainer
      });

    case SET_MODULE_BODY:
    case SET_MODULE_SMART_OBJECT_BODY:
    case MERGE_MODULE_BODY:
    case SET_DEPRECATED_STYLES_FLAGS:
    case MERGE_MODULE_SMART_OBJECT_BODY:
    case UPDATE_CONTENT_MODEL_THEME_PAGE_OVERRIDES:
    case LAYOUT_SECTION_MODULE_DELETED:
    case LAYOUT_SECTION_COLUMN_DELETED:
    case LAYOUT_SECTION_ROW_DELETED:
    case UPDATED_MODULE_SMART_RULES:
    case REMOVED_MODULE_SMART_RULES:
    case SET_BREAKPOINT_STYLES_FOR_TREE_NODE:
    case MODULE_SET_LAYOUT_STYLES:
    case LAYOUT_SECTION_CELL_SET_LAYOUT_STYLES:
    case LAYOUT_SECTION_ROW_SET_LAYOUT_STYLES:
    case SET_MODULE_BREAKPOINT_STYLES:
    case SET_MODULE_STYLES:
    case LAYOUT_SECTION_ROW_SET_VERTICAL_ALIGNMENT:
    case LAYOUT_SECTION_COLUMN_INSERTED_AFTER:
    case LAYOUT_SECTION_COLUMN_INSERTED_BEFORE:
    case LAYOUT_SECTION_ROW_INSERTED_AFTER:
    case LAYOUT_SECTION_ROW_INSERTED_BEFORE:
      return setSaveState(state, {
        isDirty: true
      });

    case LAYOUT_SECTION_ROW_CLONED_BELOW:
    case LAYOUT_SECTION_CELL_CLONED_TO_RIGHT:
    case UPDATE_CONTENT_MODEL_HTML_TITLE:
      return setSaveState(state, {
        isDirty: true,
        shouldSaveImmediately: true
      });

    case PERMANENTLY_DISABLE_SAVING:
      return state.merge({
        permanentlyDisabled: true
      });

    case CONTENT_SAVE_REQUEST_STARTED:
      {
        return state.merge({
          saveRequested: true
        });
      }

    case CONTENT_SAVE_REQUEST_SUCCEEDED:
      {
        const {
          opts: {
            buffer,
            isAutoSave
          }
        } = payload;
        return state.merge({
          isDirty: false,
          isHardSavePending: false,
          lastHardSaveTs: buffer ? state.get('lastHardSaveTs') : Date.now(),
          lastSaveSuccessful: true,
          lastSaveWasAutoSave: isAutoSave
        });
      }

    case CONTENT_SAVE_REQUEST_FAILED:
      return state.merge({
        permanentlyDisabled: isLocked(error.response),
        lastSaveSuccessful: false
      });

    case RELOAD_WITHOUT_WARNING:
      return state.set('preventUnsavedChangesWarning', true);

    default:
      return state;
  }
};

export default SaveData;