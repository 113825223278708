/* hs-eslint ignored failing-rules */

/* eslint-disable hubspot-dev/no-declarations */
import enviro from 'enviro';
import Raven from 'raven-js';
import { track } from '../common/eventTracking/tracker';
import getLang from 'I18n/utils/getLang';
import { QA } from 'self-service-api/constants/Environments';
import { getUpgradeDataTrackingProperties } from 'ui-addon-upgrades/ums/utils';

const getQueryParam = (isRetailPortal, isAssignable) => {
  if (isRetailPortal) {
    return `show-retail-tts-chat-${getLang()}`;
  } else if (isAssignable) {
    return `show-assignable-tts-chat-${getLang()}`;
  } else {
    return `show-unassignable-tts-chat-${getLang()}`;
  }
};

const openSalesChat = (hostWindow, upgradeData) => {
  if (!hostWindow.HubSpotConversations) {
    Raven.captureMessage('Attempted to open chat without the widget being loaded', {
      tags: {
        location: hostWindow.location.href
      }
    });
    return;
  }

  if (hostWindow.hubspot && hostWindow.hubspot.zorse && !hostWindow.hubspot.zorse.supressed) {
    hostWindow.hubspot.zorse.minimizeZorseWidgetButton();
  }

  hostWindow.HubSpotConversations.on('conversationStarted', __payload => {
    track('communicationMethodsInteraction', Object.assign({
      action: 'started conversation'
    }, getUpgradeDataTrackingProperties(upgradeData)));
  });
  hostWindow.HubSpotConversations.on('conversationClosed', __payload => {
    track('communicationMethodsInteraction', Object.assign({
      action: 'ended conversation'
    }, getUpgradeDataTrackingProperties(upgradeData)));
    hostWindow.HubSpotConversations.widget.close();

    if (hostWindow.hubspot && hostWindow.hubspot.zorse && !hostWindow.hubspot.zorse.supressed) {
      hostWindow.hubspot.zorse.restoreZorseWidgetButton();
    }
  });
  hostWindow.HubSpotConversations.widget.remove();
  hostWindow.HubSpotConversations.widget.load({
    widgetOpen: true
  });
};

const mountConversationsScriptTag = hostWindow => {
  const qa = enviro.isProd() ? '' : QA;
  const chatPortal = enviro.isProd() ? '53' : '100048175';
  const script = hostWindow.document.createElement('script');
  const env = enviro.getShort();
  script.type = 'text/javascript';
  script.id = 'hubspot-messages-loader';
  script.async = false;
  script.defer = false;
  script.src = `//js.usemessages${qa}.com/conversations-embed.js`;
  script.setAttribute('data-hsjs-portal', chatPortal);
  script.setAttribute('data-hsjs-env', env);
  hostWindow.document.body.appendChild(script);
};
/**
 * Adds the required query parameter, loads the Conversations script tag,
 * and opens the chat when it's ready.
 *
 *
 * Note that `show-tts-chat=true` is a specific query parameter for
 * the [chatflow in 53](https://app.hubspot.com/chatflows/53/edit/live-chat/548260/target).
 */


export const loadAndOpenSalesChatInWindow = hostWindow => ({
  upgradeData,
  isRetail = false,
  isAssignable = false
}) => {
  if (hostWindow) {
    // When in a modal, post this the parent, and the parent will open chat
    if (hostWindow.self !== hostWindow.top) {
      hostWindow.parent.postMessage({
        type: 'OPEN_CHAT',
        data: Object.assign({}, upgradeData, {
          isRetail,
          upgradeData,
          isAssignable
        })
      }, '*');
      return;
    }

    hostWindow.hsConversationsSettings = {
      loadImmediately: true
    };
    const queryParam = `${hostWindow.location.search.length ? '&' : '?'}${getQueryParam(isRetail, isAssignable)}=true`;
    const queryParamIsPresent = hostWindow.location.search.includes(queryParam.slice(1));

    if (!queryParamIsPresent) {
      hostWindow.history.pushState(null, '', `${hostWindow.location.href}${queryParam}`);
    }

    if (hostWindow.HubSpotConversations) {
      openSalesChat(hostWindow, upgradeData);
    } else {
      hostWindow.hsConversationsOnReady = [() => openSalesChat(hostWindow, upgradeData)];
      mountConversationsScriptTag(hostWindow);
    }
  }
};
export const loadAndOpenSalesChat = params => loadAndOpenSalesChatInWindow(window)(params);