import * as UpgradeProducts from 'self-service-api/constants/UpgradeProducts';
import * as ApiNames from 'self-service-api/constants/ApiNames';
// This is used to submit product_upgrade_interest to 53
// If a new value is added here, let #marketing-tech know and make a request in #53-data-questions using the slack shortcut /53_data_help
export const productUpgradeInterest = {
  [UpgradeProducts.GENERAL]: 'General',
  [UpgradeProducts.SALES_PRO]: 'Sales-Pro',
  [UpgradeProducts.SALES_STARTER]: 'Sales-Starter',
  [UpgradeProducts.SALES_PROFESSIONAL]: 'Sales-Professional',
  [UpgradeProducts.SALES_ENTERPRISE]: 'Sales-Enterprise',
  [UpgradeProducts.SALES_FREE]: 'Sales-Free',
  [UpgradeProducts.MARKETING_FREE]: 'Marketing-Free',
  [UpgradeProducts.MARKETING_STARTER]: 'Marketing-Starter',
  [UpgradeProducts.MARKETING_STARTER_EMAIL]: 'Marketing-Starter',
  [UpgradeProducts.MARKETING_BASIC]: 'Marketing-Basic',
  [UpgradeProducts.MARKETING_PRO]: 'Marketing-Pro',
  [UpgradeProducts.MARKETING_ENTERPRISE]: 'Marketing-Ent',
  [UpgradeProducts.SERVICE_FREE]: 'Service-Free',
  [UpgradeProducts.SERVICE_STARTER]: 'Service-Starter',
  [UpgradeProducts.SERVICE_PROFESSIONAL]: 'Service-Professional',
  [UpgradeProducts.SERVICE_ENTERPRISE]: 'Service-Enterprise',
  [UpgradeProducts.STARTER_CONTACTS]: 'Starter-Contacts',
  [UpgradeProducts.CMS_STARTER]: 'CMS-Starter',
  [UpgradeProducts.CMS_PROFESSIONAL]: 'CMS-Professional',
  [UpgradeProducts.CMS_ENTERPRISE]: 'CMS-Enterprise',
  [UpgradeProducts.ADS]: 'Marketing-Ads',
  [UpgradeProducts.ADS_CAPACITY]: 'Ads-Capacity',
  [UpgradeProducts.WEBSITE]: 'Marketing-Website',
  [UpgradeProducts.REPORTING]: 'reporting',
  [UpgradeProducts.DEDICATED_IP]: 'Marketing-Dedicated IP',
  [UpgradeProducts.TRANSACTIONAL_EMAIL]: 'Marketing-Transactional Email',
  [UpgradeProducts.ADDITIONAL_ACCOUNT]: 'Marketing-Additional Portal',
  [UpgradeProducts.BRAND_DOMAIN]: 'Brand-Domain',
  [UpgradeProducts.DESIGNATED_TECHNICAL_SUPPORT]: 'Designated-Technical-Support',
  [UpgradeProducts.IN_PERSON_TRAINING]: 'Training-In-Person',
  [UpgradeProducts.STARTER_KIT]: 'Starter Kit',
  [UpgradeProducts.SALES_PROFESSIONAL_ONBOARDING]: 'Sales Professional Onboarding',
  [UpgradeProducts.SERVICE_PROFESSIONAL_ONBOARDING]: 'Service Professional Onboarding',
  [UpgradeProducts.INBOUND_CONSULTING_BLOCK]: 'Inbound Consulting Block',
  [UpgradeProducts.TECHNICAL_CONSULTING_BLOCK]: 'Technical Consulting Block',
  [UpgradeProducts.ENTERPRISE]: 'Enterprise',
  [UpgradeProducts.PARTNER_PROGRAM]: 'Partner-Program',
  [UpgradeProducts.SUITE_STARTER]: 'Starter Suite',
  [UpgradeProducts.SUITE_PROFESSIONAL]: 'Professional Suite',
  [UpgradeProducts.SUITE_ENTERPRISE]: 'Enterprise Suite',
  [UpgradeProducts.BUNDLE]: 'Bundle',
  [UpgradeProducts.OPERATIONS_STARTER]: 'Operations-Starter',
  [UpgradeProducts.OPERATIONS_PROFESSIONAL]: 'Operations-Professional',
  [UpgradeProducts.OPERATIONS_ENTERPRISE]: 'Operations-Enterprise',
  [UpgradeProducts.CUSTOM_SSL]: 'Custom-SSL',
  [UpgradeProducts.DOMAINS_LIMIT_INCREASE]: 'Domains-Limit-Increase',
  [UpgradeProducts.SANDBOX_LIMIT_INCREASE]: 'Sandbox-Limit-Increase',
  [UpgradeProducts.ESIGNATURE_LIMIT_INCREASE]: 'Esignature-Limit-Increase',
  [UpgradeProducts.WORKFLOWS_ADDON]: 'Workflows-Addon',
  [UpgradeProducts.CALLING_LIMIT_INCREASE]: 'Calling-Limit-Increase',
  [UpgradeProducts.PHONE_LIMIT_INCREASE]: 'Phone-Limit-Increase',
  [UpgradeProducts.WHATSAPP_LIMIT_INCREASE]: 'Whatsapp-Limit-Increase',
  [UpgradeProducts.VIDEO_LIMIT_INCREASE]: 'Video-Limit-Increase',
  [UpgradeProducts.TEAMS_LIMIT_INCREASE]: 'Teams-Limit-Increase',
  //temporary duplicates for apiNames
  [ApiNames.SALES_HUB_FREE]: 'Sales-Free',
  [ApiNames.SALES_HUB_STARTER]: 'Sales-Starter',
  [ApiNames.SALES_HUB_PROFESSIONAL]: 'Sales-Professional',
  [ApiNames.SALES_HUB_ENTERPRISE]: 'Sales-Enterprise',
  [ApiNames.MARKETING_HUB_FREE]: 'Marketing-Free',
  [ApiNames.MARKETING_HUB_STARTER]: 'Marketing-Starter',
  [ApiNames.MARKETING_HUB_BASIC]: 'Marketing-Basic',
  [ApiNames.MARKETING_HUB_PROFESSIONAL]: 'Marketing-Pro',
  [ApiNames.MARKETING_HUB_ENTERPRISE]: 'Marketing-Ent',
  [ApiNames.SERVICE_HUB_FREE]: 'Service-Free',
  [ApiNames.SERVICE_HUB_STARTER]: 'Service-Starter',
  [ApiNames.SERVICE_HUB_PROFESSIONAL]: 'Service-Professional',
  [ApiNames.SERVICE_HUB_ENTERPRISE]: 'Service-Enterprise',
  [ApiNames.CMS_HUB_STARTER]: 'CMS-Starter',
  [ApiNames.CMS_HUB_PROFESSIONAL]: 'CMS-Professional',
  [ApiNames.CMS_HUB_ENTERPRISE]: 'CMS-Enterprise',
  [ApiNames.OPERATIONS_HUB_STARTER]: 'Operations-Starter',
  [ApiNames.OPERATIONS_HUB_PROFESSIONAL]: 'Operations-Professional',
  [ApiNames.OPERATIONS_HUB_ENTERPRISE]: 'Operations-Enterprise',
  [ApiNames.CRM_SUITE_STARTER_BUNDLE]: 'Starter Suite',
  [ApiNames.CRM_SUITE_PROFESSIONAL_BUNDLE]: 'Professional Suite',
  [ApiNames.CRM_SUITE_ENTERPRISE_BUNDLE]: 'Enterprise Suite',
  //probably not used
  // [ApiNames.STARTER_CONTACTS]: 'Starter-Contacts', //apparently replaced with STARTER_MARKETING_CONTACTS
  // [ApiNames.DESIGNATED_TECHNICAL_SUPPORT]: 'Designated-Technical-Support', //service
  // [ApiNames.IN_PERSON_TRAINING]: 'Training-In-Person', //service
  // [ApiNames.INBOUND_CONSULTING_BLOCK]: 'Inbound Consulting Block', //pricing page, in use
  // [ApiNames.TECHNICAL_CONSULTING_BLOCK]: 'Technical Consulting Block', //pricing page, in use
  // [ApiNames.PARTNER_PROGRAM]: 'Partner-Program',
  // [ApiNames.ADS_CAPACITY]: 'Ads-Capacity', //replaced by ADS_LIMIT_INCREASE
  // [ApiNames.WEBSITE]: 'Marketing-Website', //was an add-on, probably not used
  // [ApiNames.ADDITIONAL_ACCOUNT]: 'Marketing-Additional Portal', //probably not used
  [ApiNames.DEDICATED_IP]: 'Marketing-Dedicated IP',
  [ApiNames.TRANSACTIONAL_EMAIL]: 'Marketing-Transactional Email',
  [ApiNames.BRAND_DOMAIN]: 'Brand-Domain',
  [ApiNames.STARTER_KIT]: 'Starter Kit',
  [ApiNames.SALES_HUB_PROFESSIONAL_ONBOARDING]: 'Sales Professional Onboarding',
  [ApiNames.SERVICE_HUB_PROFESSIONAL_ONBOARDING]: 'Service Professional Onboarding',
  [ApiNames.MARKETING_HUB_ENTERPRISE]: 'Enterprise',
  [ApiNames.CUSTOM_SSL]: 'Custom-SSL',
  [ApiNames.ADS_LIMIT_INCREASE]: 'Marketing-Ads',
  [ApiNames.REPORTING_LIMIT_INCREASE]: 'reporting',
  [ApiNames.DOMAINS_LIMIT_INCREASE]: 'Domains-Limit-Increase',
  [ApiNames.SANDBOX_LIMIT_INCREASE]: 'Sandbox-Limit-Increase',
  [ApiNames.ESIGNATURE_LIMIT_INCREASE]: 'Esignature-Limit-Increase',
  [ApiNames.WORKFLOWS_LIMIT_INCREASE]: 'Workflows-Addon',
  [ApiNames.CALLING_LIMIT_INCREASE]: 'Calling-Limit-Increase',
  [ApiNames.PHONE_LIMIT_INCREASE]: 'Phone-Limit-Increase',
  [ApiNames.WHATSAPP_LIMIT_INCREASE]: 'Whatsapp-Limit-Increase',
  [ApiNames.VIDEO_LIMIT_INCREASE]: 'Video-Limit-Increase',
  // hold-overs from upgradeProducts that are needed but don't have an apiName
  ['BUNDLE']: 'Bundle',
  // what the pricing page sends when multiple products are selected
  ['GENERAL']: 'General' // indicates general upgrade interest without a specific product

};