'use es6';

import { Map as ImmutableMap } from 'immutable';
import { SET_HIGHLIGHTED_FIELD, SET_SETTING_OPTIMIZATION_TYPE, SET_MODULE_OPTIMIZATION_TYPE, SELECTED_ITEM_CLEARED, SET_IMAGE_MODULES_FOR_ALT_TEXT, SET_XRAY_PAGE_TITLE, SET_XRAY_META_DESC, LOCATION_CHANGE, SET_XRAY_CONFIGS, SET_GATHERER_ARTIFACT } from 'ContentEditorUI/redux/actions/actionTypes';
import { getPaneNameFromPath } from 'ContentEditorUI/utils/helpers';
const initialState = ImmutableMap({
  highlightedField: [],
  settingOptimizationType: '',
  moduleOptimizationType: '',
  imageModulesForAltText: [],
  pageTitle: '',
  metaDesc: '',
  configs: [],
  gathererArtifacts: new ImmutableMap()
});

const optimizationsReducer = (state = initialState, {
  type,
  payload
}) => {
  switch (type) {
    case SET_HIGHLIGHTED_FIELD:
      {
        const {
          field
        } = payload;
        return state.merge({
          highlightedField: field
        });
      }

    case SET_SETTING_OPTIMIZATION_TYPE:
      return state.set('settingOptimizationType', payload.optimizationType);

    case SET_MODULE_OPTIMIZATION_TYPE:
      return state.set('moduleOptimizationType', payload.optimizationType);

    case LOCATION_CHANGE:
      {
        const {
          pathname
        } = payload;
        const paneName = getPaneNameFromPath(pathname);

        if (paneName !== 'settings') {
          return state.set('settingOptimizationType', '');
        }

        return state;
      }

    case SELECTED_ITEM_CLEARED:
      return state.merge({
        highlightedField: [],
        moduleOptimizationType: ''
      });

    case SET_IMAGE_MODULES_FOR_ALT_TEXT:
      {
        const {
          imageModules
        } = payload;
        return state.merge({
          imageModulesForAltText: imageModules
        });
      }

    case SET_XRAY_PAGE_TITLE:
      {
        const {
          pageTitle
        } = payload;
        return state.merge({
          pageTitle
        });
      }

    case SET_XRAY_META_DESC:
      {
        const {
          metaDesc
        } = payload;
        return state.merge({
          metaDesc
        });
      }

    case SET_XRAY_CONFIGS:
      {
        const {
          configs
        } = payload;
        return state.set('configs', configs);
      }

    case SET_GATHERER_ARTIFACT:
      {
        const {
          key,
          value
        } = payload;
        return state.setIn(['gathererArtifacts', key], value);
      }

    default:
      return state;
  }
};

export default optimizationsReducer;