'use es6';

import { goBack, push, replace } from 'ContentEditorUI/redux/actions/historyActions';
import { getRoutingCurrentPage, getRoutingRelativePath, getRoutingQueryParams, getRoutingLocation } from 'ContentEditorUI/redux/selectors/routingSelectors';
import { NAVIGATION_TO_SAME_PAGE, NAVIGATE_TO_PREVIEW, FLUSH_SIDEBAR_HIDDEN_STATE } from 'ContentEditorUI/redux/actions/actionTypes';
import { getPreviewIframeNeedsRefresh } from 'ContentEditorUI/redux/selectors/inpageSelectors';
import { clearInpageUIState } from 'ContentEditorUI/redux/actions/inpageActions';
import { toggleApplyChangesModalOpen } from 'ContentEditorUI/redux/actions/appActions';
import { constructSearchStringFromUrlParams } from 'ContentEditorUI/utils/routeHelper';
export const routeToPage = page => {
  return dispatch => {
    const currentPage = getRoutingCurrentPage();
    const query = getRoutingQueryParams();

    if (page !== currentPage) {
      const relativePath = getRoutingRelativePath();
      dispatch(push(`${relativePath}${page}${constructSearchStringFromUrlParams(query)}`));
    } else {
      dispatch({
        type: NAVIGATION_TO_SAME_PAGE,
        payload: page
      });
    }
  };
}; // TODO branden this naming is better

export const routeToTab = routeToPage;
export const replacePage = (page, query = {}) => {
  return dispatch => {
    const currentPage = getRoutingCurrentPage();

    if (page !== currentPage) {
      const relativePath = getRoutingRelativePath();
      dispatch(replace(`${relativePath}${page}${constructSearchStringFromUrlParams(query)}`));
    }
  };
};
export const routeToPreviousPage = () => {
  return dispatch => {
    dispatch(goBack());
  };
};
export const updateActivePanelToPreview = () => {
  return dispatch => {
    dispatch({
      type: NAVIGATE_TO_PREVIEW,
      payload: 'preview'
    });
  };
};
export const flushSidebarHiddenState = page => ({
  type: FLUSH_SIDEBAR_HIDDEN_STATE,
  payload: {
    paneName: page
  }
});
export const checkForModuleChangesAndThenRouteToTab = tab => {
  return (dispatch, getState) => {
    const reduxState = getState();
    const previewIframeNeedsRefresh = getPreviewIframeNeedsRefresh(reduxState);

    if (previewIframeNeedsRefresh) {
      const opts = {
        selectionInProgress: null,
        routingInProgress: {
          target: tab
        }
      };
      dispatch(toggleApplyChangesModalOpen(true, opts));
    } else {
      dispatch(clearInpageUIState()); // Certain tabs (e.g. optimize) require the sidebar to be expanded initially.

      dispatch(flushSidebarHiddenState(tab));
      dispatch(routeToPage(tab));
    }
  };
};
export const clearQueryParam = queryParam => {
  return (dispatch, getState) => {
    const state = getState();
    const query = getRoutingQueryParams(state);
    const currentLocation = getRoutingLocation(state);

    if (queryParam in query) {
      delete query[queryParam];
      dispatch(push(Object.assign({}, currentLocation, {
        search: constructSearchStringFromUrlParams(query)
      })));
    }
  };
};