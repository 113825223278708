import { useMemo } from 'react';
import { CompletionStatuses } from '../constants';
export const useGenerateAuditResults = ({
  configs,
  artifacts,
  editorApi
}) => {
  const auditResults = useMemo(() => {
    const allAuditResults = {};
    configs.map(config => {
      const {
        groups
      } = config;
      return groups.map(group => {
        const {
          recs
        } = group;
        return recs.map(rec => {
          allAuditResults[rec.type] = rec.audit(artifacts, editorApi);
          return allAuditResults;
        });
      });
    });
    return allAuditResults;
  }, [artifacts, configs, editorApi]);
  return auditResults;
};
export const getGroupIncompleteCount = ({
  auditResults,
  group
}) => {
  const recTypes = group.recs.map(rec => rec.type);
  const incompleteCount = recTypes.filter(recType => {
    const auditResult = auditResults[recType];
    const status = auditResult && auditResult.status;
    return status === CompletionStatuses.INCOMPLETE || status === CompletionStatuses.BLOCKED;
  }).length;
  return incompleteCount;
};