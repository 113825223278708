export const MODAL_TYPES = {
  LOCKED_MODAL: 'LOCKED_MODAL',
  DELETE_MODAL: 'DELETE_MODAL',
  CLONE_CONTENT_MODAL: 'CLONE_CONTENT_MODAL',
  CREATE_CONTENT_MODAL: 'CREATE_CONTENT_MODAL',
  CONVERT_TO_DD_MODAL: 'CONVERT_TO_DD_MODAL',
  SAVE_TEMPLATE_MODAL: 'SAVE_TEMPLATE_MODAL',
  CONFIRM_EDIT_GLOBAL: 'CONFIRM_EDIT_GLOBAL',
  FORCE_IFRAME_HIDE: 'FORCE_IFRAME_HIDE',
  EMAIL_CUSTOM_ONBOARDING_MODAL: 'EMAIL_CUSTOM_ONBOARDING_MODAL',
  EMAIL_TRUE_SIZE_MODAL: 'EMAIL_TRUE_SIZE_MODAL',
  SAVE_SECTION_MODAL: 'SAVE_SECTION_MODAL',
  VIDEO_DETAILS_PANEL: 'VIDEO_DETAILS_PANEL',
  VIDEO_UPGRADE_PQL: 'VIDEO_UPGRADE_PQL',
  ECOMM_CONNECT: 'ECOMM_CONNECT',
  ECOMM_MODULES_PQL: 'ECOMM_MODULES_PQL',
  MANAGE_SAVED_SECTIONS: 'MANAGE_SAVED_SECTIONS',
  EDIT_ECOMM_PRODUCT: 'EDIT_ECOMM_PRODUCT',
  CREATE_SIMPLE_WORKFLOW: 'CREATE_SIMPLE_WORKFLOW',
  ADD_TO_WORKFLOW_AFTER_PUBLISH: 'ADD_TO_WORKFLOW_AFTER_PUBLISH',
  CONTEXTUAL_AUTOMATION_ONBOARDING_MODAL: 'CONTEXTUAL_AUTOMATION_ONBOARDING_MODAL',
  FILE_MANAGER: 'FILE_MANAGER',
  SETTINGS_MODAL: 'SETTINGS_MODAL',
  SECTIONS_INSTRUCTIONAL_MODAL: 'SECTIONS_INSTRUCTIONAL_MODAL',
  CREATE_MLANG_VARIANT: 'CREATE_MLANG_VARIANT',
  MLANG_LIMIT_UPGRADE_MODAL: 'MLANG_LIMIT_UPGRADE_MODAL'
};
export const TEST_MODAL_TYPES = {
  AB_TEST: 'ab',
  MAB_TEST: 'mab'
};
export const NO_TEST_ACCESS = 'noAccess';
export const SMART_CONTENT = 'smartContent';