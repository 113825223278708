'use es6';

import Raven from 'raven-js';
let options = {
  groupByTitle: false
};
export const initializeRavenOptions = overrides => {
  options = Object.assign({}, options, {}, overrides);
};
/**
 * Wrapper around Raven.captureMessage that handles grouping.
 * docs: https://github.com/getsentry/sentry-javascript/blob/master/packages/raven-js/src/raven.js
 */

export const captureMessage = (title, data = {}, ...args) => {
  let updatedData;

  if (options.groupByTitle) {
    updatedData = Object.assign({}, data, {
      fingerprint: [title]
    });
  }

  Raven.captureMessage(title, updatedData || data, ...args);
};