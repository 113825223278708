import { getPageTitle } from './pageTitle';
import { getLinks } from './links';
import { getH1Count } from './h1Count';
import { getImages } from './images';
import { getMetaTags } from './metaTags';
import { getPostBody } from './postBody';
import { getDOMBody } from './DOMBody';
export const GathererKeys = {
  DOM_BODY: 'DOM_BODY',
  H1Count: 'H1Count',
  IMAGES: 'IMAGES',
  LINKS: 'LINKS',
  META_TAGS: 'META_TAGS',
  PAGE_TITLE: 'PAGE_TITLE',
  POST_BODY: 'POST_BODY'
};
export const ALL_GATHERERS_MAP = {
  [GathererKeys.DOM_BODY]: getDOMBody,
  [GathererKeys.H1Count]: getH1Count,
  [GathererKeys.IMAGES]: getImages,
  [GathererKeys.LINKS]: getLinks,
  [GathererKeys.META_TAGS]: getMetaTags,
  [GathererKeys.PAGE_TITLE]: getPageTitle,
  [GathererKeys.POST_BODY]: getPostBody
};