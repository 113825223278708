import { CompletionStatuses } from '../../../constants';
export const verifyEmptyImageAltTextAudit = artifacts => {
  const {
    images
  } = artifacts;
  const imagesWithEmptyAltText = images && images.filter(img => {
    const {
      alt,
      role
    } = img;
    return alt === '' && role !== 'presentation';
  });
  let status;

  if (imagesWithEmptyAltText === undefined || imagesWithEmptyAltText.length === 0) {
    status = CompletionStatuses.COMPLETE;
    return {
      status
    };
  } else {
    status = CompletionStatuses.INCOMPLETE;
    return {
      status,
      imagesWithEmptyAltText
    };
  }
};