'use es6';

import { USER_SEEN_STATE_SETTINGS_OBJECT_KEY, USER_LAST_PREVIEW_DEVICE, USER_BREAKPOINT_STYLING_TOOLTIP_DISMISSED, SHOULD_START_FREE_LP_ONBOARDING_TOUR, HAS_SEEN_SCOPES_HAVE_CHANGED_MODAL_KEY, IS_DISTRACTION_FREE_OPEN, ALL_USER_ATTRIBUTE_KEYS_IN_SUPERSTORE, ALL_LOCALLY_CACHED_PER_CONTENT_ATTRIBUTE_KEYS, HAS_SEEN_ONBOARDING_MODAL_LOCAL } from 'ContentEditorUI/lib/userAndLocalCacheSettings/constants';
import { basicSelectorWithStats } from 'ContentEditorUI/redux/selectors/helpers';

const getPerUserLocalSettings = state => state.locallyCachedSettings.get('perUser');

const getPerContentLocalSettings = state => state.locallyCachedSettings.get('perContent'); // Ever needed?
// export const getHadErrorLoadingSettings = !!state.locallyCachedSettings.errorLoadingSettings;


const maybeThrowMissingKeyWarning = (key, ALLOWED_KEYS) => {
  if (!ALLOWED_KEYS.includes(key)) {
    console.warn(`${key} is not a known attribute of ${ALLOWED_KEYS}.
If you want it to persist, add to the appropriate array.
      `);
  }
};

export const makeGetUserLocalSetting = key => {
  maybeThrowMissingKeyWarning(key, ALL_USER_ATTRIBUTE_KEYS_IN_SUPERSTORE);
  return basicSelectorWithStats(state => getPerUserLocalSettings(state).get(key));
};
export const makeGetPerContentLocalSetting = key => {
  maybeThrowMissingKeyWarning(key, ALL_LOCALLY_CACHED_PER_CONTENT_ATTRIBUTE_KEYS);
  return basicSelectorWithStats(state => getPerContentLocalSettings(state).get(key));
}; // Selectors based on shared editor user attributes that are also locally cached in superstore

export const getUserSeenStateSettingsObject = makeGetUserLocalSetting(USER_SEEN_STATE_SETTINGS_OBJECT_KEY);
export const getUserLastPreviewDevice = makeGetUserLocalSetting(USER_LAST_PREVIEW_DEVICE);
export const getBreakpointStylingTooltipDismissed = makeGetUserLocalSetting(USER_BREAKPOINT_STYLING_TOOLTIP_DISMISSED);
export const getShouldStartFreeLPOnboardingTour = basicSelectorWithStats(state => {
  if (state.locallyCachedSettings) {
    return getPerUserLocalSettings(state).get(SHOULD_START_FREE_LP_ONBOARDING_TOUR);
  }

  return false;
});
export const getHasSeenScopesHaveChangedModal = makeGetPerContentLocalSetting(HAS_SEEN_SCOPES_HAVE_CHANGED_MODAL_KEY);
export const getHasSeenOnboardingModalFromSuperStore = makeGetUserLocalSetting(HAS_SEEN_ONBOARDING_MODAL_LOCAL);
export const getIsDistractionFreeOpen = makeGetPerContentLocalSetting(IS_DISTRACTION_FREE_OPEN);