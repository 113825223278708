'use es6';

import getEarlyRequester from './EarlyRequester';
import * as Logger from '../utils/Logger';
const defaultFields = {
  'nav:seen': null,
  'nav:responded': null,
  'nav:declined': null,
  'nps:seen': null,
  'nps:responded': null,
  'nps:declined': null
};

class UserAttributesClient {
  constructor(apiClient, userInfo, keyName = 'wootric-nps') {
    const {
      user_id: userId
    } = userInfo;
    this.httpClient = apiClient;
    this.keyName = keyName;
    this.userId = userId;
  }

  fetch() {
    return this.httpClient.get(`users/v1/app/attributes?user-id=${this.userId}&key=${this.keyName}`).then(resp => {
      return resp && resp.attributes.length ? JSON.parse(resp.attributes[0].value) : defaultFields;
    });
  }

  update(instance) {
    return this.httpClient.post('users/v1/app/attributes', {
      data: {
        userId: instance.userId,
        key: this.keyName,
        value: JSON.stringify(instance.value)
      }
    });
  }

}

const fieldTypes = {
  'nav:seen': 'nav:seen',
  'nav:responded': 'nav:responded',
  'nav:declined': 'nav:declined',
  'nps:seen': 'nps:seen',
  'nps:responded': 'nps:responded',
  'nps:declined': 'nps:declined'
};
export class UserAttributesData {
  constructor(data = defaultFields) {
    this.value = data;
  }

  get(field) {
    return this.value[field];
  }

  hasRespondedToNav() {
    return this.value['nav:responded'] !== null;
  }

  touch(field) {
    if (!field || fieldTypes[field] == null) {
      Logger.warn(`response_callback: expected key of seen|responded|declined, got ${field}`);
      return;
    }

    this.value = Object.assign({}, this.value, {
      [field]: new Date()
    });
  }

}
let client;
let instance;
export const touch = field => {
  if (!instance) return;
  instance.touch(field);
  client.update(instance);
};
export default (({
  apiClient
}) => {
  return getEarlyRequester().then(({
    user
  }) => {
    client = new UserAttributesClient(apiClient, user);
    return client.fetch().then(data => {
      instance = new UserAttributesData(data);
      return instance;
    });
  });
});