import { CompletionStatuses } from '../../../constants';
const MIN_WORD_COUNT = 300;

const getNoMarkupPostBody = postBody => {
  const postBodyDiv = document.createElement('div');
  postBodyDiv.innerHTML = postBody;
  return postBodyDiv.textContent;
};

export const increaseWordCountAudit = artifacts => {
  const {
    postBody
  } = artifacts;
  const noMarkupPostBody = getNoMarkupPostBody(postBody);
  const count = noMarkupPostBody ? noMarkupPostBody.split(' ').length : 0;
  let status;

  if (count < MIN_WORD_COUNT) {
    status = CompletionStatuses.INCOMPLETE;
  } else {
    status = CompletionStatuses.COMPLETE;
  }

  return {
    status,
    count
  };
};