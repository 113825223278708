import Raven from 'raven-js';
import http from 'hub-http/clients/apiClient';

let _cachedProducts;

export const getProducts = () => {
  if (_cachedProducts) {
    return _cachedProducts;
  }

  _cachedProducts = http.get('monetization/v3/product').catch(error => {
    _cachedProducts = null;

    if (error && error.status === 0) {
      return;
    }

    Raven.captureException(new Error('Failed to fetch products'), {
      extra: {
        error
      }
    });
  });
  return _cachedProducts;
};

let _cachedProductsOwned;

export const getOwnedProducts = () => {
  if (_cachedProductsOwned) {
    return _cachedProductsOwned;
  }

  _cachedProductsOwned = http.get('monetization/v3/product/owned').catch(() => {
    _cachedProductsOwned = null;
  });
  return _cachedProductsOwned;
};