import I18n from 'I18n';
import { debounce } from 'underscore';
import enviro from 'enviro';
import { isEmpty } from 'underscore';
import { userInfoSync } from 'hub-http/userInfo';
import * as formsClient from 'ContentData/api/Forms';
import * as CrmObjectsClient from 'ContentData/api/CrmObjects';
import * as workflowsClient from '../api/Workflows';
import * as feedbackClient from 'ContentData/api/Feedback';
import * as OptinActions from 'ContentData/actions/Optin';
import { getFormFetchStatus, getFormCache, getDraftForm } from 'ContentData/selectors/forms';
import { getWorkflowHybrid } from '../selectors/workflows';
import { createGenericFetchAction, createGenericCreateAction } from 'ContentData/helpers/reduxHelpers';
import { createNewWorkflowWithEnrollmentCriteria, addFormEnrollmentCriteriaToWorkflow, removeFormEnrollmentCriteriaFromWorkflow } from 'ContentData/helpers/workflowsHelpers';
import { FORMS_FETCH_FORM, FORMS_FETCH, FORMS_CREATE, FORMS_CLEAR_CACHE, FORMS_SAVE_FORM_CACHE, FORMS_SAVE_FORM_DRAFT, FORMS_UPDATE_FORM_DRAFT, FORMS_FETCH_FORM_DRAFT, FORMS_PROPERTIES_FETCH, FORMS_SUBSCRIPTIONS_FETCH, FORMS_DEFAULT_CONSENT_FETCH, FORMS_SAVE_FORM_DRAFT_REQUEST, FORMS_SAVE_FORM_DRAFT_FAIL, FORMS_SAVE_FORM_DRAFT_PRECONDITION_FAIL, FORMS_FETCH_PARENT_PAGES_REQUEST, FORMS_FETCH_PARENT_PAGES_SUCCEEDED, FORMS_FETCH_PARENT_PAGES_FAIL, FORMS_DISMISSED_PAGES_WARNING, FORMS_FETCH_WORKFLOW_DEPENDENCIES, FORMS_ADD_WORKFLOW_DEPENDENCY, FORMS_DELETE_WORKFLOW_DEPENDENCY } from 'ContentData/actions/ActionTypes';
import { FormInlineEditingOptinName } from 'ContentData/constants/Optins';
import http from 'hub-http/clients/apiClient';
import { HUBSPOT, IDS_BY_TYPE } from 'ContentUtils/constants/FormTypes';
import { FORM_OBJECT_TYPE_ID, PROPERTIES } from 'ContentUtils/constants/FormsCRM';
const PRECONDITION_FAILED = 412;
const fetchFormAction = createGenericFetchAction(FORMS_FETCH_FORM, formsClient.fetchForm, getFormFetchStatus);
const fetchFormDraftAction = createGenericCreateAction(FORMS_FETCH_FORM_DRAFT, formsClient.fetchFormDraft);
const createFormAction = createGenericCreateAction(FORMS_CREATE, formsClient.createForm);
/*
If we're trying to fetch too many workflows at once, break them up into chunks
that only fetch up to 50 at a time

TODO: Will look into seeing if we can have an endpoint that fetches all of a form's
workflow dependencies in one go so we don't have to do this expensive fetching/filtering
on the FE so that this is just a temporary measure
*/

const MAX_WORKFLOWS = 25;

const fetchHybridWorkflows = flowIds => {
  if (flowIds.length > MAX_WORKFLOWS) {
    const promises = [];

    for (let i = 0; i < Math.ceil(flowIds.length / MAX_WORKFLOWS); i++) {
      promises.push(workflowsClient.fetchHybridWorkflowsBatch(flowIds.slice(i * MAX_WORKFLOWS, (i + 1) * MAX_WORKFLOWS)));
    }

    return Promise.all(promises);
  } else {
    return workflowsClient.fetchHybridWorkflowsBatch(flowIds);
  }
};

export const fetchFormWorkflowDependencies = (formId, formType) => {
  const {
    request,
    requestHybrids,
    receive,
    error
  } = fetchFormWorkflowDependencies;
  return dispatch => {
    let flowIds = [];
    dispatch(request(formId));
    workflowsClient.fetchFormWorkflowDependencies(formId, IDS_BY_TYPE[formType || HUBSPOT]).then(response => {
      const uniqueFlowIds = {};
      flowIds = response.reduce((acc, flow) => {
        const {
          parentName
        } = flow;
        const flowId = parentName && Number(parentName.split('-')[1]);

        if (flowId && !uniqueFlowIds[flowId]) {
          acc.push(flowId);
          uniqueFlowIds[flowId] = flowId;
        }

        return acc;
      }, []);
      dispatch(requestHybrids(formId, flowIds));
      return fetchHybridWorkflows(flowIds);
    }).then(response => {
      const finalResponse = Array.isArray(response) ? response.reduce((acc, resp) => {
        return Object.assign({}, acc, {}, resp);
      }, {}) : response;
      dispatch(receive(formId, flowIds, finalResponse));
      return response;
    }).catch(e => {
      dispatch(error(formId, e));
      return e;
    });
  };
};

fetchFormWorkflowDependencies.request = formId => {
  return {
    type: FORMS_FETCH_WORKFLOW_DEPENDENCIES,
    formId
  };
};

fetchFormWorkflowDependencies.requestHybrids = (formId, flowIds) => {
  return {
    type: FORMS_FETCH_WORKFLOW_DEPENDENCIES,
    formId,
    flowIds
  };
};

fetchFormWorkflowDependencies.receive = (formId, flowIds, hybridsResponse) => {
  return {
    type: FORMS_FETCH_WORKFLOW_DEPENDENCIES,
    formId,
    flowIds,
    hybridsResponse
  };
};

fetchFormWorkflowDependencies.error = (formId, error) => {
  return {
    type: FORMS_FETCH_WORKFLOW_DEPENDENCIES,
    formId,
    error
  };
};

export const addFormAsWorkflowEnrollmentCriteria = (flowId, formId, contentId) => {
  const {
    request,
    receive,
    error
  } = addFormAsWorkflowEnrollmentCriteria;
  return (dispatch, getState) => {
    dispatch(request(flowId));
    const workflowHybrid = getWorkflowHybrid(getState(), {
      id: flowId
    });

    if (workflowHybrid) {
      const hybridWithNewCriteria = addFormEnrollmentCriteriaToWorkflow(workflowHybrid, formId, contentId);
      return workflowsClient.updateWorkflowHybrid(hybridWithNewCriteria).then(response => {
        dispatch(receive(flowId, formId, response));
        return response;
      }).catch(e => {
        dispatch(error(flowId, e));
        return e;
      });
    } else {
      return workflowsClient.fetchHybridWorkflowsBatch([flowId]).then(response => {
        const hybridWithNewCriteria = addFormEnrollmentCriteriaToWorkflow(response[flowId], formId, contentId);
        return workflowsClient.updateWorkflowHybrid(hybridWithNewCriteria);
      }).then(response => {
        dispatch(receive(flowId, formId, response));
        return response;
      }).catch(e => {
        dispatch(error(flowId, e));
        return e;
      });
    }
  };
};

addFormAsWorkflowEnrollmentCriteria.request = flowId => {
  return {
    type: FORMS_ADD_WORKFLOW_DEPENDENCY,
    flowId
  };
};

addFormAsWorkflowEnrollmentCriteria.receive = (flowId, formId, newHybrid) => {
  return {
    type: FORMS_ADD_WORKFLOW_DEPENDENCY,
    flowId,
    formId,
    newHybrid
  };
};

addFormAsWorkflowEnrollmentCriteria.error = (flowId, error) => {
  return {
    type: FORMS_ADD_WORKFLOW_DEPENDENCY,
    flowId,
    error
  };
};

export const createWorkflowAndFormAsEnrollmentCriteria = (workflowName, formId, contentId) => {
  const {
    request,
    receive,
    error
  } = addFormAsWorkflowEnrollmentCriteria;
  return dispatch => {
    dispatch(request(undefined));
    return workflowsClient.createWorkflowHybrid(createNewWorkflowWithEnrollmentCriteria(workflowName, formId, contentId), contentId).then(response => {
      dispatch(receive(response.flowId, formId, response));
      return response;
    }).catch(e => {
      dispatch(error(undefined, e));
      return e;
    });
  };
};
export const deleteFormWorkflowDependency = (flowId, formId, contentId) => {
  const {
    request,
    receive,
    error
  } = deleteFormWorkflowDependency;
  return (dispatch, getState) => {
    const workflowHybrid = getWorkflowHybrid(getState(), {
      id: flowId
    });
    const hybridWithoutCriteria = removeFormEnrollmentCriteriaFromWorkflow(workflowHybrid, formId, contentId);
    dispatch(request(flowId, formId));
    return workflowsClient.updateWorkflowHybrid(hybridWithoutCriteria).then(response => {
      dispatch(receive(flowId, formId, response));
      return response;
    }).catch(e => {
      dispatch(error(flowId, formId, e));
      return e;
    });
  };
};
export const deleteFormWorkflowDependencyAndWorkflow = (flowId, formId) => {
  const {
    request,
    error
  } = deleteFormWorkflowDependency;
  const {
    receive
  } = deleteFormWorkflowDependencyAndWorkflow;
  return (dispatch, getState) => {
    const workflowHybrid = getWorkflowHybrid(getState(), {
      id: flowId
    }) || {};
    const {
      sourceApp
    } = workflowHybrid.createMetadata || {};
    dispatch(request(flowId, formId));
    return workflowsClient.deleteWorkflowHybrid(flowId, sourceApp).then(response => {
      // If delete fails due to the workflow being referenced in other places,
      // the endpoint will still return 200 but @result would be "ERR"
      if (response && response['@result'] === 'OK') {
        dispatch(receive(flowId, formId));
      } else {
        dispatch(error(flowId, formId, response && response.errorType));
      }

      return response;
    }).catch(e => {
      dispatch(error(flowId, formId, e));
      return e;
    });
  };
};

deleteFormWorkflowDependency.request = (flowId, formId) => {
  return {
    type: FORMS_DELETE_WORKFLOW_DEPENDENCY,
    flowId,
    formId
  };
};

deleteFormWorkflowDependency.receive = (flowId, formId, updatedHybrid) => {
  return {
    type: FORMS_DELETE_WORKFLOW_DEPENDENCY,
    flowId,
    formId,
    updatedHybrid
  };
};

deleteFormWorkflowDependencyAndWorkflow.receive = (flowId, formId) => {
  return {
    type: FORMS_DELETE_WORKFLOW_DEPENDENCY,
    flowId,
    formId,
    deleted: true
  };
};

deleteFormWorkflowDependency.error = (flowId, formId, error) => {
  return {
    type: FORMS_DELETE_WORKFLOW_DEPENDENCY,
    flowId,
    formId,
    error
  };
};

export function fetchForm(formGuid) {
  return fetchFormAction(formGuid);
}
export function fetchDraftForm(formGuid) {
  return fetchFormDraftAction(formGuid);
}
export function createForm(name) {
  return createFormAction(name);
}
export function fetchForms(query) {
  const {
    request,
    receive,
    error
  } = fetchForm;
  return (dispatch, {
    httpClient = http
  } = {}) => {
    dispatch(request());
    return CrmObjectsClient.fetchCrmObjects({
      objectTypeId: FORM_OBJECT_TYPE_ID,
      query
    }, httpClient).then(response => {
      const {
        total,
        offset,
        results
      } = response;
      const objects = results.map(crmObject => {
        const properties = crmObject.properties;
        return {
          name: properties[PROPERTIES.NAME].value,
          guid: properties[PROPERTIES.FORM_ID].value
        };
      });
      const formattedResponse = {
        limit: query.limit,
        objects,
        total,
        offset
      };
      dispatch(receive(formattedResponse));
      return formattedResponse;
    }).catch(__error => {
      dispatch(error(__error));
      return __error;
    });
  };
}
export function fetchContactProperties() {
  const {
    request,
    receive,
    error
  } = fetchContactProperties;
  return (dispatch, getState, {
    httpClient = http
  } = {}) => {
    dispatch(request());
    return formsClient.fetchContactProperties(httpClient).then(response => dispatch(receive(response))).catch(err => dispatch(error(err)));
  };
}
export function fetchSubscriptionDefinitions() {
  const {
    request,
    receive,
    error
  } = fetchSubscriptionDefinitions;
  return (dispatch, getState, {
    httpClient = http
  } = {}) => {
    dispatch(request());
    return formsClient.fetchSubscriptionDefinitions(httpClient).then(response => dispatch(receive(response))).catch(err => dispatch(error(err)));
  };
}
export function fetchDefaultConsentCopy(fetchConsentCopyFn) {
  const {
    request,
    receive,
    error
  } = fetchDefaultConsentCopy;
  return dispatch => {
    dispatch(request());
    return fetchConsentCopyFn().then(response => dispatch(receive(response))).catch(err => dispatch(error(err)));
  };
}

fetchForm.request = () => {
  return {
    type: FORMS_FETCH
  };
};

fetchForm.receive = response => {
  return {
    type: FORMS_FETCH,
    response
  };
};

fetchForm.error = error => {
  return {
    type: FORMS_FETCH,
    error
  };
};

fetchContactProperties.request = () => {
  return {
    type: FORMS_PROPERTIES_FETCH
  };
};

fetchContactProperties.receive = response => {
  return {
    type: FORMS_PROPERTIES_FETCH,
    response
  };
};

fetchContactProperties.error = error => {
  return {
    type: FORMS_PROPERTIES_FETCH,
    error
  };
};

fetchSubscriptionDefinitions.request = () => {
  return {
    type: FORMS_SUBSCRIPTIONS_FETCH
  };
};

fetchSubscriptionDefinitions.receive = response => {
  return {
    type: FORMS_SUBSCRIPTIONS_FETCH,
    response
  };
};

fetchSubscriptionDefinitions.error = error => {
  return {
    type: FORMS_SUBSCRIPTIONS_FETCH,
    error
  };
};

fetchDefaultConsentCopy.request = () => {
  return {
    type: FORMS_DEFAULT_CONSENT_FETCH
  };
};

fetchDefaultConsentCopy.receive = response => {
  return {
    type: FORMS_DEFAULT_CONSENT_FETCH,
    response
  };
};

fetchDefaultConsentCopy.error = error => {
  return {
    type: FORMS_DEFAULT_CONSENT_FETCH,
    error
  };
};

fetchDraftForm.request = () => {
  return {
    type: FORMS_FETCH
  };
};

fetchDraftForm.error = error => {
  return {
    type: FORMS_FETCH,
    error
  };
};

fetchDraftForm.receive = response => {
  return {
    type: FORMS_FETCH,
    response: Object.assign({}, response, {
      id: response.guid
    })
  };
};

export function saveCache(formGuid) {
  return (dispatch, getState) => {
    const form = getFormCache(getState(), {
      formGuid
    });
    dispatch({
      type: FORMS_SAVE_FORM_CACHE,
      form
    });
  };
}
export function saveFormDraft(formGuid) {
  return (dispatch, getState, {
    httpClient = http
  } = {}) => {
    const form = getDraftForm(getState(), {
      formGuid
    });
    dispatch({
      type: FORMS_SAVE_FORM_DRAFT_REQUEST
    });
    formsClient.saveFormDraft(formGuid, form, httpClient).then(({
      updatedAt
    }) => {
      dispatch({
        type: FORMS_SAVE_FORM_DRAFT,
        response: {
          updatedAt,
          guid: formGuid
        }
      });
    }).catch(__error => {
      dispatch({
        type: __error.status === PRECONDITION_FAILED ? FORMS_SAVE_FORM_DRAFT_PRECONDITION_FAIL : FORMS_SAVE_FORM_DRAFT_FAIL
      });
      return __error;
    });
  };
}
export function reloadFormDraft(formGuid) {
  return (dispatch, {
    httpClient = http
  } = {}) => {
    dispatch({
      type: FORMS_SAVE_FORM_DRAFT_REQUEST
    });
    formsClient.fetchFormDraft(formGuid, httpClient).then(response => {
      dispatch({
        type: FORMS_FETCH_FORM_DRAFT,
        response
      });
    }).catch(__error => {
      dispatch({
        type: FORMS_SAVE_FORM_DRAFT_FAIL
      });
      return __error;
    });
  };
}
const debouncedUpdate = debounce((form, updatePreviewFunc, dispatch) => {
  dispatch(saveFormDraft(form.guid));
  updatePreviewFunc(form);
}, 200);
export function updateFormDraft(form, updatePreviewFunc) {
  return dispatch => {
    debouncedUpdate(form, updatePreviewFunc, dispatch);
    dispatch({
      type: FORMS_UPDATE_FORM_DRAFT,
      response: form
    });
  };
}
export function fetchParentPages(formGuid) {
  return (dispatch, __unusedArg, {
    httpClient = http
  } = {}) => {
    dispatch({
      type: FORMS_FETCH_PARENT_PAGES_REQUEST
    });
    formsClient.fetchCMSParentPages(formGuid, httpClient).then(parents => {
      dispatch({
        type: FORMS_FETCH_PARENT_PAGES_SUCCEEDED,
        response: parents
      });
    }).catch(error => {
      dispatch({
        type: FORMS_FETCH_PARENT_PAGES_FAIL
      });
      return error;
    });
  };
}
export function clearCache() {
  return {
    type: FORMS_CLEAR_CACHE
  };
}
export function fetchIsOptedIntoInlineEditing() {
  return dispatch => {
    dispatch(OptinActions.fetchOptin(FormInlineEditingOptinName));
  };
}
export function optInToFormInlineEditing() {
  return dispatch => {
    dispatch(OptinActions.optIn(FormInlineEditingOptinName));
  };
}
export function optOutToFormInlineEditing(followUp) {
  return dispatch => {
    dispatch(OptinActions.optOut(FormInlineEditingOptinName));

    if (!isEmpty(followUp)) {
      const surveyId = enviro.isQa() ? '8' : '213';
      const {
        user: {
          user_id: contactId
        }
      } = userInfoSync();
      feedbackClient.submitFeedback({
        followUp,
        surveyId,
        contactId
      });
    }
  };
}
export function cloneForm(formGuid, cloneName = null) {
  return (dispatch, getState) => {
    const {
      name
    } = getDraftForm(getState(), {
      formGuid
    });
    let nameToSave;

    if (cloneName !== null) {
      nameToSave = cloneName;
    } else {
      nameToSave = I18n.text('ContentComponents.cmv2.fields.form.withInlineEditing.copyName', {
        name,
        timestamp: I18n.moment().format('LL LTS')
      });
    }

    return formsClient.cloneForm(formGuid, nameToSave).then(response => {
      dispatch({
        type: FORMS_FETCH_FORM_DRAFT,
        response
      });
      dispatch({
        type: FORMS_CREATE,
        response
      });
      dispatch(clearCache());
      return response;
    });
  };
}
export function onDismissOtherPagesWarning() {
  return {
    type: FORMS_DISMISSED_PAGES_WARNING
  };
}