import { CompletionStatuses } from '../../../constants';
const MAX_TITLE_LENGTH = 70;
export const shortenTitleAudit = artifacts => {
  const pageTitle = artifacts.pageTitle;
  let status;
  let titleLength;

  if (pageTitle === '' || pageTitle === undefined) {
    status = CompletionStatuses.BLOCKED;
    titleLength = 0;
  } else if (pageTitle.length > MAX_TITLE_LENGTH) {
    status = CompletionStatuses.INCOMPLETE;
    titleLength = pageTitle.length;
  } else {
    status = CompletionStatuses.COMPLETE;
    titleLength = pageTitle.length;
  }

  return {
    status,
    maxTitleLength: MAX_TITLE_LENGTH,
    titleLength
  };
};