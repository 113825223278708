export const shouldShowBeta = approvalType => {
  switch (approvalType) {
    case 'MARKETING_EMAIL':
      {
        return false;
      }

    default:
      {
        return true;
      }
  }
};