import Immutable from 'immutable';
import { SELECT_BREAKPOINT, FETCH_CONTENT_SCHEMA_SUCCEEDED } from 'ContentEditorUI/redux/actions/actionTypes';
const initialState = Immutable.Map({
  activeBreakpointName: 'default',
  activeBreakpointPreviewWidth: null,
  breakpoints: [{
    name: 'default'
  }]
});

const styleBreakpointsReducer = (state = initialState, {
  type,
  response,
  payload
}) => {
  switch (type) {
    case SELECT_BREAKPOINT:
      if (payload.previewWidth) {
        const {
          units,
          value
        } = payload.previewWidth;
        const previewWidth = `${value}${units}`;
        return state.merge({
          activeBreakpointName: payload.selectedBreakpoint,
          activeBreakpointPreviewWidth: previewWidth
        });
      }

      return state.merge({
        activeBreakpointName: payload.selectedBreakpoint,
        activeBreakpointPreviewWidth: null
      });

    default:
      return state;

    case FETCH_CONTENT_SCHEMA_SUCCEEDED:
      {
        const {
          breakpoint_config
        } = response;
        let newState = state;

        if (breakpoint_config) {
          newState = newState.set('breakpoints', breakpoint_config.breakpoints);
        }

        return newState;
      }
  }
};

export default styleBreakpointsReducer;