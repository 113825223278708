import isEmpty from 'hs-lodash/isEmpty';
import { Iterable } from 'immutable';
import enviro from 'enviro'; // @ts-expect-error not typed yet

import { calculateDiff } from 'ContentEditorUI/utils/diffTools';
import Raven from 'raven-js';
const initialStateLookup = {};
export function addInitialStateLookupForDiffedReducer(reducerName, state) {
  initialStateLookup[reducerName] = state;
}
const initialStateBuilderCallbacks = [];
export function setInitialStateForDiffedReducers() {
  initialStateBuilderCallbacks.forEach(callback => {
    callback();
  });
}

const convertFromImmutableIfNeeded = value => Iterable.isIterable(value) ? value.toJS() : value;

function checkDiffAndLog(diff, reducerName, action) {
  if (!isEmpty(diff)) {
    if (!enviro.deployed()) {
      // If running locally, add some dev logs
      console.warn(`Found a diff in the two ${reducerName} reducers, please check the two states below \n Note, the immutable state has been converted to JS`);
      console.log('Action that led to diff: ', action);
      console.log(diff);
    }

    const message = `Diff found in immer reducer differ for reducer: ${reducerName}`;
    Raven.captureMessage(message, {
      extra: {
        diff,
        action
      }
    });
    return true;
  }

  return false;
}

window.__hsReduxDiffer = {};

function immutableToImmerReducer(immutableReducer, immerReducer, {
  reducerToReturn,
  reducerName,
  prodConfig
}) {
  let diffedReducerState;
  let hasDiffAlready = false;

  const initialStateBuilderCallback = () => {
    diffedReducerState = initialStateLookup[reducerName];
  };

  initialStateBuilderCallbacks.push(initialStateBuilderCallback);
  return (state, action) => {
    if (enviro.isProd() && enviro.deployed()) {
      const skipComputingDiffOnProd = prodConfig.computeDiffOnProd === 'never' || prodConfig.computeDiffOnProd === 'conditionally' && !prodConfig.condition();

      if (skipComputingDiffOnProd) {
        const reducer = reducerToReturn === 'immutable' ? immutableReducer : immerReducer;
        return reducer(state, action);
      }
    }

    let immutableState;
    let immerState;
    let prevImmutableState;
    let prevImmerState;

    if (reducerToReturn === 'immutable') {
      prevImmutableState = state;
      immutableState = immutableReducer(state, action);

      try {
        prevImmerState = diffedReducerState;
        immerState = immerReducer(diffedReducerState, action);
        diffedReducerState = immerState;
      } catch (e) {
        const errorToLog = new Error(`Exception found in immer reducer: ${reducerName}`); // If we hit an exception, skip doing any more diffs since the state will likely be different

        hasDiffAlready = true;
        Raven.captureException(errorToLog, {
          extra: {
            immerReducerSource: reducerName,
            exceptionMessage: e.message
          }
        });
        return immutableState;
      }
    } else {
      prevImmerState = state;
      prevImmutableState = diffedReducerState;
      immutableState = immutableReducer(diffedReducerState, action);
      immerState = immerReducer(state, action);
      diffedReducerState = immutableState;
    }

    const someStateChanged = prevImmerState !== immerState || prevImmutableState !== immutableState;

    if (!someStateChanged) {
      // If neither the immutable nor the immer state changed, then just return state
      // and don't compute the diff to save some compute
      return reducerToReturn === 'immutable' ? immutableState : immerState;
    } // Only log a message to sentry if we haven't seen a diff yet because...
    // if we find one diff, then more than likely, most state changes after that will still result in a diff
    // because the state is already incorrect


    if (!hasDiffAlready) {
      // maybe wrap the below in a setTimeout/requestIdleCallback for perf?
      const immutableStateAsJS = convertFromImmutableIfNeeded(immutableState); // Need to serialize native JS Sets and Maps because immutable serializes its own Sets and Maps to
      // arrays and objects, respectively. Since we can't compare Sets to arrays nor Maps to objects,
      // we need to serialize the native JS Sets and Map equivalents in our immer (or POJO) reducer
      // to arrays and objects respectively as well

      const diff = calculateDiff(immutableStateAsJS, immerState, '', {
        shouldSerializeSetsAndMaps: true
      });
      hasDiffAlready = checkDiffAndLog(diff, reducerName, action);
    }

    window.__hsReduxDiffer[reducerName] = diffedReducerState;
    return reducerToReturn === 'immutable' ? immutableState : immerState;
  };
}

export default immutableToImmerReducer;