'use es6';

import _objectWithoutPropertiesLoose from "@babel/runtime/helpers/esm/objectWithoutPropertiesLoose";
import { useDispatch, useSelector } from 'react-redux';
import { useMemo } from 'react';
import { CommentObjectType, FEATURES, SUPPORTED_HUBS } from 'collaboration-sidebar';
import merge from 'hs-lodash/merge';
import { FEATURE_FLAGS } from 'ContentEditorUI/redux/constants';
import EditorConfigSingleton from 'ContentEditorUI/EditorConfigSingleton';
import { getIsBlogPost, getIsLandingPage, getIsSitePage } from 'ContentEditorUI/redux/selectors/contentReadOnlyDataSelectors';
import EditorApprovalSidebar from 'ContentEditorUI/components/approvals/EditorApprovalSidebar';
const EMPTY_CONFIG = {};

const getCollaborationSidebarConfigFromState = state => {
  if (!EditorConfigSingleton.getIsOnScalableEditor()) {
    return EMPTY_CONFIG;
  }

  const {
    mapStateToCollaborationSidebarConfig
  } = EditorConfigSingleton.getFeatureFlagOrDefault(FEATURE_FLAGS.collaborationSidebar, false);

  if (mapStateToCollaborationSidebarConfig && typeof mapStateToCollaborationSidebarConfig !== 'function') {
    throw new Error('mapStateToCollaborationSidebarConfig must be a function');
  }

  return mapStateToCollaborationSidebarConfig ? mapStateToCollaborationSidebarConfig(state) : EMPTY_CONFIG;
};

const ENABLED_FEATURES = [FEATURES.COMMENTS, FEATURES.TASKS, FEATURES.CALENDAR, FEATURES.CAMPAIGNS];
export default function useCollaborationSidebarConfig({
  isApprovalsEnabled = false
} = {}) {
  const configFromState = useSelector(getCollaborationSidebarConfigFromState);
  const dispatch = useDispatch();
  const isBlogPost = useSelector(getIsBlogPost);
  const isSitePage = useSelector(getIsSitePage);
  const isLandingPage = useSelector(getIsLandingPage);
  const nonScalableEditorsProps = useMemo(() => {
    if (isBlogPost) {
      return {
        objectType: CommentObjectType.BLOG_POST,
        appHub: [SUPPORTED_HUBS.CMS_HUB, SUPPORTED_HUBS.MARKETING_HUB],
        appName: 'blog-editor'
      };
    } else if (isSitePage) {
      return {
        objectType: CommentObjectType.WEBSITE_PAGE,
        appHub: SUPPORTED_HUBS.CMS_HUB,
        appName: 'page-editor'
      };
    } else if (isLandingPage) {
      return {
        objectType: CommentObjectType.LANDING_PAGE,
        appHub: [SUPPORTED_HUBS.CMS_HUB, SUPPORTED_HUBS.MARKETING_HUB],
        appName: 'page-editor'
      };
    } else {
      return EMPTY_CONFIG;
    }
  }, [isBlogPost, isLandingPage, isSitePage]);
  const collaborationSidebarConfig = useMemo(() => {
    if (EditorConfigSingleton.getIsOnScalableEditor()) {
      const _EditorConfigSingleto = EditorConfigSingleton.getFeatureFlagOrDefault(FEATURE_FLAGS.collaborationSidebar, false),
            {
        mapDispatchToCollaborationSidebarConfig
      } = _EditorConfigSingleto,
            staticConfig = _objectWithoutPropertiesLoose(_EditorConfigSingleto, ["mapStateToCollaborationSidebarConfig", "mapDispatchToCollaborationSidebarConfig"]);

      let config = Object.assign({}, staticConfig, {}, configFromState);

      if (typeof mapDispatchToCollaborationSidebarConfig === 'function') {
        config = merge(config, mapDispatchToCollaborationSidebarConfig(dispatch));
      }

      if (isApprovalsEnabled) {
        return Object.assign({}, config, {
          enabledFeatures: [...config.enabledFeatures, FEATURES.APPROVALS],
          ApprovalsContent: EditorApprovalSidebar
        });
      }

      return config;
    }

    if (isApprovalsEnabled) {
      return Object.assign({}, nonScalableEditorsProps, {
        enabledFeatures: [...ENABLED_FEATURES, FEATURES.APPROVALS],
        ApprovalsContent: EditorApprovalSidebar
      });
    }

    return Object.assign({}, nonScalableEditorsProps, {
      enabledFeatures: ENABLED_FEATURES
    });
  }, [configFromState, dispatch, isApprovalsEnabled, nonScalableEditorsProps]);
  const isValid = Boolean(collaborationSidebarConfig.objectType && collaborationSidebarConfig.appHub && collaborationSidebarConfig.appName && collaborationSidebarConfig.enabledFeatures);
  return {
    collaborationSidebarConfig,
    isValid
  };
}