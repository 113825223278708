import { useEffect, useMemo, useState } from 'react';
import { getLocalStorageItem } from 'collaboration-sidebar/utils/localStorage';
import { getTourStatus, updateTourStatus } from '../api/OnboardingTours';
import { TourCompletionStatus } from '../constants/TourCompletionStatus';
import { shouldHideOnboardingForSelenium } from '../utils/env';
import Raven from 'raven-js';
export default function useOnboardingTour({
  featureIsVisible,
  tourId,
  localStorageKey
}) {
  const [loading, setLoading] = useState(true);
  const [tourStatus, setTourStatus] = useState({
    tourKey: tourId,
    status: TourCompletionStatus.INCOMPLETE
  });
  useEffect(() => {
    getTourStatus(tourId).then(status => {
      setTourStatus(status);
    }).catch(error => {
      Raven.captureException(error, {
        fingerprint: ['error fetching the tour status']
      });
    }).finally(() => {
      setLoading(false);
    });
  }, [tourId]);
  const showTour = useMemo(() => {
    if (!featureIsVisible || shouldHideOnboardingForSelenium()) {
      return false;
    }

    if (loading) {
      return false;
    }

    const searchParams = new URLSearchParams(document.location.search);
    const onboardingTourId = searchParams.get('onboardingTourId');
    const isTourInQueryParam = onboardingTourId === tourId; // Keep reading the local storage value for a value so
    // the current onboarding tour state is preserved and we don't display it
    // to customer who were shown the tour before we persisted on the BE

    const isTourCompleteInLocalStorage = localStorageKey ? getLocalStorageItem(localStorageKey) === 'true' : true;
    return isTourInQueryParam || isTourCompleteInLocalStorage === false && tourStatus.status !== TourCompletionStatus.COMPLETE;
  }, [featureIsVisible, localStorageKey, tourId, loading, tourStatus]);
  useEffect(() => {
    // Mark the tour as complete if tour is showing
    if (!loading && tourStatus.status !== TourCompletionStatus.COMPLETE) {
      updateTourStatus(tourId, TourCompletionStatus.COMPLETE).catch(error => {
        Raven.captureException(error, {
          fingerprint: ['error updating the tour status']
        });
      });
    }
  }, [loading, tourStatus, tourId]);
  return showTour;
}