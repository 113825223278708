export const NAV_TABS_SHOW_SIDEBAR = {
  content: true,
  design: true,
  settings: false,
  optimize: true,
  publishing: false,
  preview: true,
  revisions: true
};
export const PATAGONIA_ADD_PANEL_CATEGORIES = {
  MODULES: 'MODULES',
  SECTIONS: 'SECTIONS',
  LAYOUTS: 'LAYOUTS'
};
export const PATAGONIA_ADD_PANEL_SUB_CATEGORIES = {
  MODULES: {
    THEME: 'THEME',
    COMMON: 'COMMON',
    ALL: 'ALL'
  },
  SECTIONS: {
    SAVED: 'SAVED',
    THEME: 'THEME'
  },
  LAYOUTS: {
    NONE: 'NONE'
  }
}; // Default to the first instance, but can be overridden in sidebarStateReducer

export const PATAGONIA_DEFAULT_SELECTED_SUB_CATEGORIES = {
  MODULES: 'THEME',
  SECTIONS: 'SAVED',
  LAYOUTS: 'NONE'
};
export const PATAGONIA_ADD_PANEL_CATEGORIES_YAML_MAPPING = {
  MODULES: 'patagonia.sidebar.addPanel.categories.modules',
  SECTIONS: 'patagonia.sidebar.addPanel.categories.sections',
  LAYOUTS: 'patagonia.sidebar.addPanel.categories.layouts'
};
export const PATAGONIA_ADD_PANEL_SUB_CATEGORIES_YAML_MAPPING = {
  THEME: 'patagonia.sidebar.addPanel.subcategories.theme',
  COMMON: 'patagonia.sidebar.addPanel.subcategories.common',
  ALL: 'patagonia.sidebar.addPanel.subcategories.all',
  SAVED: 'patagonia.sidebar.addPanel.subcategories.saved'
};
export const PATAGONIA_TOOLS_MENU = [{
  panelName: 'optimizer',
  modal: false
}, {
  panelName: 'publish',
  modal: true
}, {
  panelName: 'test',
  modal: false
}];