import Raven from 'raven-js';
import enviro from 'enviro'; // @ts-expect-error Untyped module

import { setupCsat } from 'wootric-nps/csat/csat-setup';

const triggerWootricSurvey = surveyName => {
  try {
    if (enviro.debug('CMS_WOOTRIC_SURVEY')) {
      console.log('Triggering wootric survey');
    }

    setupCsat(surveyName);
  } catch (error) {
    Raven.captureException(error, {
      extra: {
        from: 'triggerWootricSurvey'
      }
    });
  }
};

export const triggerCmsCsatWootricSurvey = () => {
  triggerWootricSurvey('CMS_CONTENT_DETAIL');
};