import { CompletionStatuses } from '../../../constants';
export const ViewportTagIssues = {
  NO_VIEWPORT_FOUND: 'NO_VIEWPORT_FOUND',
  VIEWPORT_FOUND_WITH_INCORRECT_CONTENT: 'VIEWPORT_FOUND_WITH_INCORRECT_CONTENT',
  MULTIPLE_VIEWPORT_TAGS_FOUND: 'MULTIPLE_VIEWPORT_TAGS_FOUND'
};

const isMobileFriendly = viewportTag => {
  const {
    content
  } = viewportTag;
  return !content || !content.includes('width=');
};

export const addViewportTagAudit = artifacts => {
  const {
    metaTags
  } = artifacts;
  const viewportTags = metaTags && metaTags.filter(metaTag => metaTag.name === 'viewport');
  let viewportTagIssue;

  if (!viewportTags) {
    viewportTagIssue = ViewportTagIssues.NO_VIEWPORT_FOUND;
  } else if (viewportTags.length > 1) {
    viewportTagIssue = ViewportTagIssues.MULTIPLE_VIEWPORT_TAGS_FOUND;
  } else if (isMobileFriendly(viewportTags[0])) {
    viewportTagIssue = ViewportTagIssues.VIEWPORT_FOUND_WITH_INCORRECT_CONTENT;
  }

  if (viewportTagIssue) {
    return {
      status: CompletionStatuses.INCOMPLETE,
      viewportTagIssue
    };
  }

  return {
    status: CompletionStatuses.COMPLETE
  };
};