'use es6';

import { set } from '../helpers/update';
import { withResponseHandlers, buildResponse, buildErrorResponse, withRetry, withTracking, trackSuccess, trackFailureBasedOnErrorResponse, buildRequestError } from './adapterUtils';
import { withStaticAppInfo, ensureStaticAppInfo } from '../middlewares/staticAppInfo';
import { setMockAuth } from '../middlewares/mockAuth';
import { createStack } from '../index';
import { reportDomain, reportStatusCode } from '../tracking/trackRequests';
import { Metrics } from '../tracking/metrics';
import { parseUrl } from '../helpers/url';
export const maybeWithIframeXMLHttpRequest = options => {
  const canUseSyncIframeRequest = options.useIframeRequest && window.iframeXMLHttpRequest && window.apiIframe && window.apiIframe.contentDocument;
  const canUseAsyncIframeRequest = options.useIframeRequest && window.iframeXMLHttpRequestPromise;
  const canUseIframeHack = canUseSyncIframeRequest || canUseAsyncIframeRequest;

  if (!canUseIframeHack) {
    return set('Request', options.Request || XMLHttpRequest)(options);
  } // see https://git.hubteam.com/HubSpot/hub-http/pull/372


  window.apiIframeUsed = true;
  const newHeaders = Object.assign({
    'X-HS-Referer': window.location.href
  }, options.headers);
  const optionsWithAdditionalHeaders = withStaticAppInfo(set('headers', newHeaders)(options)); // If iframeXMLHttpRequestPromise is set, wait for it to resolve before issuing a request
  // This is done when all appropriate requests must be sent via the frame

  if (canUseAsyncIframeRequest) {
    return window.iframeXMLHttpRequestPromise.then(iframeXMLHttpRequest => set('Request', iframeXMLHttpRequest)(optionsWithAdditionalHeaders)).catch(() => set('Request', options.Request || XMLHttpRequest)(options));
  }

  return set('Request', window.iframeXMLHttpRequest)(optionsWithAdditionalHeaders);
};

const withOptions = options => {
  return new Promise(resolve => {
    const Request = options.Request || XMLHttpRequest;
    const xhr = new Request();

    if (options.error) {
      resolve(withResponseHandlers(buildErrorResponse(xhr, options.error.message, 'OPTIONSERROR'), options));
      return;
    } // the http request was done by a separate client and is being piped back
    // into this one for response handling


    if (options.externalResponse) {
      const fromExternalResponse = options.externalResponse instanceof XMLHttpRequest ? buildResponse(options.externalResponse) : Object.assign(buildResponse(xhr), options.externalResponse);
      resolve(withResponseHandlers(fromExternalResponse, options));
      return;
    }

    reportDomain(options.url);
    xhr.open(options.method || 'GET', options.url, true);

    if (typeof options.timeout === 'number') {
      xhr.timeout = options.timeout;
    }

    xhr.withCredentials = options.withCredentials;

    if (options.responseType) {
      xhr.responseType = options.responseType;
    }

    if (typeof options.withXhr === 'function') {
      options.withXhr(xhr);
    }

    Object.keys(options.headers || {}).forEach(headerName => {
      if (options.headers[headerName] !== false) {
        xhr.setRequestHeader(headerName, options.headers[headerName]);
      }
    });
    const sendTime = performance.now();
    xhr.addEventListener('load', () => {
      reportStatusCode({
        url: xhr.responseURL,
        sendTime,
        statusCode: xhr.status
      });
      return resolve(withResponseHandlers(buildResponse(xhr), options));
    });
    xhr.addEventListener('error', () => {
      reportStatusCode({
        url: xhr.responseURL,
        sendTime,
        statusCode: xhr.status,
        statusDesc: 'NETWORKERROR'
      });
      return resolve(withResponseHandlers(buildErrorResponse(xhr, 'Network request failed', 'NETWORKERROR'), options));
    });
    xhr.addEventListener('timeout', () => {
      reportStatusCode({
        url: xhr.responseURL,
        sendTime,
        statusCode: xhr.status,
        statusDesc: 'TIMEOUT'
      });
      return resolve(withResponseHandlers(buildErrorResponse(xhr, 'Request timeout', 'TIMEOUT'), options));
    });
    xhr.addEventListener('abort', () => {
      reportStatusCode({
        url: xhr.responseURL,
        sendTime,
        statusCode: xhr.status,
        statusDesc: 'ABORT'
      });
      return resolve(withResponseHandlers(buildErrorResponse(xhr, 'Request aborted', 'ABORT'), options));
    });
    xhr.send(typeof options.data === 'undefined' ? null : options.data);
  });
};

const handleRequestErrors = reason => Promise.reject(buildRequestError(reason));

const essentialMiddleware = createStack(withTracking, maybeWithIframeXMLHttpRequest, ensureStaticAppInfo);
export let _originalClientImplCalled = false;
let mockAuth = false;

const createClientImpl = optionMiddleware => {
  _originalClientImplCalled = true;

  const client = (url, options) => {
    const parsed = parseUrl(url);

    if (parsed.hostname && parsed.hostname.match(/^api(-[a-z]{2}\d{1})?\.hubspot(qa)?\.com/)) {
      Metrics.counter('hardcoded-api-hubspot-domain').increment();
    }

    return withRetry(Object.assign({}, options, {
      url
    }), o => optionMiddleware(o).catch(handleRequestErrors).then(essentialMiddleware).then(withOptions)).then(trackSuccess, response => Promise.reject(trackFailureBasedOnErrorResponse(response)));
  };

  const responseWithMethod = method => (url, options) => client(url, Object.assign({}, options, {
    method
  }));

  const withMethod = method => (url, options) => responseWithMethod(method)(url, options).then(({
    data
  }) => data);

  return Object.assign(client, {
    get: withMethod('GET'),
    post: withMethod('POST'),
    put: withMethod('PUT'),
    patch: withMethod('PATCH'),
    delete: withMethod('DELETE'),
    options: withMethod('OPTIONS'),
    getWithResponse: responseWithMethod('GET'),
    postWithResponse: responseWithMethod('POST'),
    putWithResponse: responseWithMethod('PUT'),
    patchWithResponse: responseWithMethod('PATCH'),
    deleteWithResponse: responseWithMethod('DELETE'),
    optionsWithResponse: responseWithMethod('OPTIONS')
  });
};
/**
 * Should only be invoked by the fe test runner
 */


export const enableMockAuth = () => {
  mockAuth = true;
};
export default (optionMiddleware => createClientImpl(createStack(setMockAuth(mockAuth), optionMiddleware)));