import useCurrentUserType from './useCurrentUserType';
import { useIsSuperAdmin } from './useIsSuperAdmin';
import { ApprovalStatus, ApprovalUserType, ApprovalStepStatus, ApprovalOperation } from '../types';
import useApprovalStepsInfo from './useApprovalStepsInfo';
import { useMemo, useState } from 'react';
import { useCurrentUser } from './useCurrentUser';

const useApprovalMeta = props => {
  const {
    approvalType,
    approvalAction,
    approvableObjectId,
    assetName,
    approvalData,
    approvalConfig,
    userSelector,
    approvalSkipConfig,
    restartOnConclude = false
  } = props;
  const [showModal, setShowModal] = useState(false);
  const [showApprovalEditPanel, setShowApprovalEditPanel] = useState(false);
  const currentUser = useCurrentUser(userSelector);
  const isSuperAdmin = useIsSuperAdmin(currentUser);
  const currentUserType = useCurrentUserType(approvalData.data, currentUser);
  const {
    stepCount,
    approvedCount,
    pendingCount,
    stepStatusForCurrentUser
  } = useApprovalStepsInfo(approvalData.data ? approvalData.data.approvalSteps : [], currentUser.user_id);
  const isLoading = useMemo(() => {
    return approvalData.isLoading || approvalConfig.isLoading || approvalSkipConfig.isLoading;
  }, [approvalConfig, approvalData, approvalSkipConfig]);
  const approvalOperation = useMemo(() => {
    if (!approvalConfig.config.enabled || isLoading) {
      return null;
    }

    const approvalStatus = approvalData.data ? approvalData.data.approvalStatus : undefined;

    switch (approvalStatus) {
      case ApprovalStatus.APPROVAL_PENDING:
        if (currentUserType === ApprovalUserType.APPROVER && stepStatusForCurrentUser === ApprovalStepStatus.APPROVAL_PENDING) {
          return ApprovalOperation.APPROVE;
        } else if (currentUserType === ApprovalUserType.REQUESTER) {
          return ApprovalOperation.RECALL;
        } else {
          return null;
        }

      case undefined:
      case ApprovalStatus.CANCELLED:
        return ApprovalOperation.REQUEST;

      case ApprovalStatus.APPROVED:
        if (restartOnConclude && approvalData.data && approvalData.data.approvalConcluded) {
          return ApprovalOperation.REQUEST;
        }

        return null;

      default:
        return null;
    }
  }, [approvalConfig, approvalData.data, currentUserType, restartOnConclude, stepStatusForCurrentUser, isLoading]);
  const userCanSkipThisApproval = useMemo(() => {
    // No one can skip if the approval is pending.
    if (approvalData.data && approvalData.data.approvalStatus === ApprovalStatus.APPROVAL_PENDING) {
      return false;
    } // Super admins can skip when the approval is NOT pending.


    if (isSuperAdmin) {
      return true;
    }

    if (approvalSkipConfig.skipConfig) {
      // The user can skip if the skip strategy is 'EVERYONE_CAN_SKIP_APPROVAL'.
      switch (approvalSkipConfig.skipConfig.skipApprovalOption) {
        case 'EVERYONE_CAN_SKIP_APPROVAL':
          return true;
        // The user can skip if the skip strategy is 'SPECIFIC_LIST_CAN_SKIP_APPROVAL' AND
        // their user ID is in the list.

        case 'SPECIFIC_LIST_CAN_SKIP_APPROVAL':
          return approvalSkipConfig.skipConfig.usersIdsListThatCanSkipApproval.includes(currentUser.user_id);

        case 'NO_ONE_CAN_SKIP_APPROVAL':
        default:
          return false;
      }
    }

    return false;
  }, [approvalData, approvalSkipConfig, currentUser, isSuperAdmin]);
  const httpErrors = {
    fetchData: approvalData.fetchError
  };
  return {
    approvableObjectId,
    approvalAction,
    approvalOperation,
    approvalType,
    approvedCount,
    assetName,
    currentUser,
    currentUserType,
    httpErrors,
    isLoading,
    isSuperAdmin,
    pendingCount,
    restartOnConclude,
    setShowModal,
    setShowApprovalEditPanel,
    showApprovalEditPanel,
    showModal,
    stepCount,
    stepStatusForCurrentUser,
    userCanSkipThisApproval,
    approvalStatus: approvalData.data ? approvalData.data.approvalStatus : undefined
  };
};

export default useApprovalMeta;