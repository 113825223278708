'use es6';

import { Map as ImmutableMap } from 'immutable';
import { UPDATE_USER_ATTRIBUTE_ALSO_CACHED_IN_SUPERSTORE, UPDATE_SUPERSTORE_USER_SETTING, UPDATE_SUPERSTORE_PER_CONTENT_SETTING } from 'ContentEditorUI/redux/actions/actionTypes';
import { USER_SEEN_STATE_SETTINGS_OBJECT_KEY, HAS_SEEN_SCOPES_HAVE_CHANGED_MODAL_KEY, HAS_SEEN_ONBOARDING_MODAL_LOCAL } from 'ContentEditorUI/lib/userAndLocalCacheSettings/constants';
import { getUserSeenStateSettingsObject } from 'ContentEditorUI/redux/selectors/localCachedSettingsSelectors';
export const setSuperstoreUserSetting = (key, value) => {
  return {
    type: UPDATE_SUPERSTORE_USER_SETTING,
    key,
    value
  };
};
export const setSuperstorePerContentSetting = (key, value) => {
  return {
    type: UPDATE_SUPERSTORE_PER_CONTENT_SETTING,
    key,
    value
  };
};
export const setUserAttributeAlsoCachedInSuperstore = (key, value) => {
  return {
    type: UPDATE_USER_ATTRIBUTE_ALSO_CACHED_IN_SUPERSTORE,
    key,
    value
  };
};
export const setUserAttributeAlsoCachedInSuperstoreAsJsonString = (key, value) => {
  return {
    type: UPDATE_USER_ATTRIBUTE_ALSO_CACHED_IN_SUPERSTORE,
    key,
    value,
    stringifiedValue: JSON.stringify(value)
  };
}; // Specific actions to set specific user attributes (and sync to superstore via
// syncAndCacheUserSettingsMiddleware in pages and blog)

const setSeenStateUserSetting = newOnboardingSettings => {
  return setUserAttributeAlsoCachedInSuperstoreAsJsonString(USER_SEEN_STATE_SETTINGS_OBJECT_KEY, newOnboardingSettings);
};

export const setSeenStateSettingToNow = key => {
  return (dispatch, getState) => {
    let seenStateSettings = getUserSeenStateSettingsObject(getState()) || new ImmutableMap();
    seenStateSettings = seenStateSettings.set(key, new Date());
    dispatch(setSeenStateUserSetting(seenStateSettings));
  };
};
export const setHasSeenOnboardingModalLocal = () => {
  try {
    localStorage.setItem('hasSeenPatagoniaOnboarding', 'true');
  } catch (e) {
    console.error(e);
  }

  return setSuperstoreUserSetting(HAS_SEEN_ONBOARDING_MODAL_LOCAL, true);
};
export const setHasSeenScopesHaveChangedModalToNow = () => {
  return setSuperstorePerContentSetting(HAS_SEEN_SCOPES_HAVE_CHANGED_MODAL_KEY, new Date());
};