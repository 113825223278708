/* hs-eslint ignored failing-rules */

/* eslint-disable prefer-promise-reject-errors */
'use es6';

import { createException } from '../utils';
export default (() => {
  return new Promise((resolve, reject) => {
    if (!window.quickFetch && !window.earlyRequester) {
      throw createException('QuickFetch is not set up on this document.');
    }

    const earlyRequesterObj = window.quickFetch || window.earlyRequester;
    const earlyRequest = earlyRequesterObj.getRequestStateByName('api-verify');

    if (!earlyRequest) {
      reject('QuickFetch hasnt called login-verify.', 'Please configure wootric-nps yourself.');
    }

    let {
      result
    } = earlyRequest;
    const {
      errored,
      data,
      finished
    } = earlyRequest; // QuickFetch "lite" includes `data` key instead of `result`

    if (!result && data) {
      result = data;
    }

    if (!finished) {
      reject('QuickFetch login-verify call hasnt finished.', 'Please only set up wootric-nps when user data is avaiable.');
    }

    if (errored) {
      reject('QuickFetch login-verify call failed.', 'Not setting up wootric-nps.');
    }

    return resolve(result);
  });
});