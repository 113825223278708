import { CompletionStatuses } from '../../../constants';
export const SearchEnginesCrawlBlockers = {
  NOINDEX_META_TAG: 'NOINDEX_META_TAG'
};
export const makePageIndexableBySearchEnginesAudit = artifacts => {
  const {
    metaTags
  } = artifacts;
  const robotsTags = metaTags && metaTags.filter(metaTag => metaTag.name === 'robots');
  const hasRobotsWithNoIndex = robotsTags && robotsTags.find(robotsTag => {
    const {
      content
    } = robotsTag;
    return content && content.toLowerCase().includes('noindex');
  });

  if (hasRobotsWithNoIndex) {
    return {
      status: CompletionStatuses.INCOMPLETE,
      searchEnginesCrawlBlocker: SearchEnginesCrawlBlockers.NOINDEX_META_TAG
    };
  }

  return {
    status: CompletionStatuses.COMPLETE
  };
};