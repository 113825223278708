import { Map as ImmutableMap } from 'immutable';
import { FETCH_CONTENT_SCHEMA_SUCCEEDED, FORCE_SHOW_END_TEST_MODAL } from 'ContentEditorUI/redux/actions/actionTypes';
import produce from 'immer';

const abTestReducer = (state = ImmutableMap(), {
  type,
  response,
  value
}) => {
  switch (type) {
    case FETCH_CONTENT_SCHEMA_SUCCEEDED:
      return state.merge(Object.assign({
        abTestId: response.content.abTestId,
        abStatus: response.content.abStatus,
        abVariation: response.content.abVariation
      }, response.abtest));

    case FORCE_SHOW_END_TEST_MODAL:
      return state.merge({
        forceEndAb: value
      });

    default:
      return state;
  }
};

export const abTestImmerReducer = (state = {}, action) => produce(state, draft => {
  const {
    type,
    response,
    value
  } = action;

  switch (type) {
    case FETCH_CONTENT_SCHEMA_SUCCEEDED:
      {
        draft.abTestId = response.content.abTestId;
        draft.abStatus = response.content.abStatus;
        draft.abVariation = response.content.abVariation;
        return Object.assign(draft, response.abtest);
      }

    case FORCE_SHOW_END_TEST_MODAL:
      {
        draft.forceEndAb = value;
        return draft;
      }

    default:
      return draft;
  }
});
export default abTestReducer;