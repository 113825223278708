import { ALL_GATHERERS_MAP } from 'xray-recommendation-lib/gatherers/allGatherers';
import { PAGE_SETTINGS_TAB_FIELD, POST_SETTINGS_TAB_FIELD // @ts-expect-error TS not migrated
} from 'ContentEditorUI/lib/xray/constants';
export const getGathererKeys = configs => {
  const gathererSet = new Set();

  if (configs) {
    configs.forEach(config => {
      const {
        groups
      } = config;

      if (groups) {
        groups.forEach(group => {
          const {
            gatherers
          } = group;

          if (gatherers) {
            gatherers.forEach(gatherer => {
              gathererSet.add(gatherer);
            });
          }
        });
      }
    });
  }

  return Array.from(gathererSet);
};
export const getGathererFunction = gathererKey => {
  return ALL_GATHERERS_MAP[gathererKey];
};
export const getSettingsFieldForContentType = (field, isBlogPost) => {
  return isBlogPost ? POST_SETTINGS_TAB_FIELD[field] : PAGE_SETTINGS_TAB_FIELD[field];
};