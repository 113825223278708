import { createGenericFetchAction, createGenericCreateAction } from 'ContentData/helpers/reduxHelpers';
import * as emailsClient from 'ContentData/api/Emails';
import { getEmailCache } from 'ContentData/selectors/emails';
import { EMAILS_FETCH_EMAIL, EMAILS_FETCH_EMAILS, EMAILS_FETCH_AUTOMATED_EMAILS, EMAILS_SAVE_EMAIL_CACHE, EMAILS_CLEAR_CACHE, EMAILS_CREATE_EMAIL, EMAILS_UPDATE_EMAIL } from 'ContentData/actions/ActionTypes';
const fetchEmailAction = createGenericFetchAction(EMAILS_FETCH_EMAIL, emailsClient.fetchEmail);
const fetchEmailsAction = createGenericFetchAction(EMAILS_FETCH_EMAILS, emailsClient.fetchEmails);
const fetchAutomatedEmailsAction = createGenericFetchAction(EMAILS_FETCH_AUTOMATED_EMAILS, emailsClient.fetchAutomatedEmails);
const createEmailAction = createGenericCreateAction(EMAILS_CREATE_EMAIL, emailsClient.createEmail);
export function fetchEmail(id) {
  return fetchEmailAction(id);
}
export function fetchEmails(query) {
  return fetchEmailsAction(query);
}
export function fetchAutomatedEmails(query) {
  return fetchAutomatedEmailsAction(query);
}
export function createEmail(name) {
  return createEmailAction(name);
}
export function saveCache(id) {
  return (dispatch, getState) => {
    const email = getEmailCache(getState(), {
      id
    });
    dispatch({
      type: EMAILS_SAVE_EMAIL_CACHE,
      email
    });
  };
}
export function saveEmail(emailData, emailId) {
  return dispatch => {
    if (emailId) {
      const saveEmailPromise = emailsClient.saveEmail(emailData, emailId);
      return saveEmailPromise.then(response => {
        dispatch({
          type: EMAILS_UPDATE_EMAIL,
          response
        });
        return saveEmailPromise;
      }).catch(e => {
        dispatch({
          type: EMAILS_UPDATE_EMAIL,
          error: e
        });
        return saveEmailPromise;
      });
    } else {
      const createEmailPromise = emailsClient.createPlainEmail(emailData);
      return createEmailPromise.then(response => {
        dispatch({
          type: EMAILS_CREATE_EMAIL,
          response
        });
        return createEmailPromise;
      }).catch(e => {
        dispatch({
          type: EMAILS_CREATE_EMAIL,
          error: e
        });
        return createEmailPromise;
      });
    }
  };
}
export function clearCache() {
  return {
    type: EMAILS_CLEAR_CACHE
  };
}