/* hs-eslint ignored failing-rules */

/* eslint-disable promise/catch-or-return */
'use es6';

import _objectWithoutPropertiesLoose from "@babel/runtime/helpers/esm/objectWithoutPropertiesLoose";
import enviro from 'enviro';
import hubspot from 'hubspot';
import { configureCsat } from './csat-config';
import * as isEligible from '../eligibility';
import { combineAll as combineAllFilters } from '../filters';
import { initializeAll as initializeDataSources } from '../sources';
import { defer, fetchScript, log, shortenTimestamp, getBeaconUrl } from '../utils';

const setupParams = (_ref, surveyKey) => {
  let {
    language,
    locale,
    portalCreationUtc,
    portalId,
    portalIsPartner,
    userCreationUtc,
    userEmail,
    userFirstName,
    userId,
    userLastName,
    userIsPartner
  } = _ref,
      extraProperties = _objectWithoutPropertiesLoose(_ref, ["language", "locale", "portalCreationUtc", "portalId", "portalIsPartner", "userCreationUtc", "userEmail", "userFirstName", "userId", "userLastName", "userIsPartner"]);

  const properties = Object.assign({
    language: locale,
    portalCreation_date: shortenTimestamp(portalCreationUtc),
    portalId,
    portalIsPartner,
    userCreation_date: shortenTimestamp(userCreationUtc),
    userEmail,
    userFirstName,
    userId,
    userIsPartner,
    userLastName
  }, extraProperties);
  configureCsat({
    created_at: shortenTimestamp(userCreationUtc),
    email: userEmail,
    language,
    properties
  }, surveyKey);
  console.log('CSAT survey ${surveyKey} is configured.');
  window.wootric('run');
};

const setupCsatWithSurveyKey = ({
  apiClient
}, surveyKey) => {
  if (!isEligible.earlyCheck()) {
    return;
  }

  Promise.all([fetchScript(getBeaconUrl()), new Promise((resolve, reject) => {
    defer(() => {
      initializeDataSources({
        apiClient,
        isQa: enviro.getShort() === 'qa'
      }).then(combineAllFilters).then(resolve, reject);
    });
  })]).then(([__, properties]) => {
    if (isEligible.fullCheck(properties)) {
      setupParams(properties, surveyKey);
    }
  }).catch(e => {
    log(e);
  });
};

export const setupCsat = surveyKey => {
  console.log('Setup CSAT called with survey key ${surveyKey}');

  hubspot.require(['hubapi-prebuilt'], ({
    apiClient
  }) => {
    setupCsatWithSurveyKey({
      apiClient
    }, surveyKey);
  });
};