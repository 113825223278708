'use es6';

import enviro from 'enviro';
import { isUrlAllowed } from './urls';
import { isHubSpotter, isForceOpen } from './utils';
export const earlyCheck = (location = window.location) => {
  if (isForceOpen()) {
    return true;
  }

  if (enviro.getShort() === 'qa') {
    return false;
  }

  return isUrlAllowed(location);
};
export const fullCheck = (userData, location = window.location) => {
  const {
    userEmail = ''
  } = userData || {};
  const isProd = enviro.getShort() === 'prod';

  if (isForceOpen()) {
    return true;
  }

  if (isProd) {
    if (isHubSpotter(userEmail)) {
      return false;
    }

    return isUrlAllowed(location);
  }

  return false;
};