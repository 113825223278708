import http from 'hub-http/clients/apiClient';
const BASE_URL = 'commenting/v2/comments';
export const updateCommentMetaDescriptionOptions = (id, updatedMetaDescription) => http.patch(`${BASE_URL}/${id}`, {
  data: [{
    op: 'replace',
    path: '/metaDescription',
    value: updatedMetaDescription
  }]
});
const fetchCommentsPromises = {};

const getCommentFetchKey = (objectType, objectId) => `${objectType}:${objectId}`;

export const fetchCommentsCached = params => {
  const {
    objectType,
    objectId
  } = params;
  const key = getCommentFetchKey(objectType, objectId);

  if (!fetchCommentsPromises[key]) {
    fetchCommentsPromises[key] = http.get(BASE_URL, {
      query: {
        objectType,
        objectId
      }
    });
  }

  return fetchCommentsPromises[key];
};
export const updateCommentsCache = ({
  objectType,
  objectId,
  comments
}) => {
  const key = getCommentFetchKey(objectType, objectId);

  if (key in fetchCommentsPromises) {
    fetchCommentsPromises[key] = Promise.resolve({
      results: comments,
      total: comments.length
    });
  }
};