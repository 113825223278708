// Copy of https://git.hubteam.com/HubSpot/Commenting/blob/master/CommentingBase/src/main/java/com/hubspot/commenting/base/CommentObjectType.java
// Remember to update also the objectType array prop for collabSidebarInteraction and collabSidebarUsage
// here https://git.hubteam.com/HubSpot/collaboration-sidebar/blob/master/collaboration-sidebar-embeddable/static/events.yaml
const CommentObjectType = {
  ENGAGEMENT: 'ENGAGEMENT',
  UNKNOWN: 'UNKNOWN',
  FLOW_ACTION: 'FLOW_ACTION',
  COMMENTING_SANDBOX: 'COMMENTING_SANDBOX',
  MARKETING_EMAIL: 'MARKETING_EMAIL',
  FORM: 'FORM',
  LEAD_FLOW: 'LEAD_FLOW',
  LANDING_PAGE: 'LANDING_PAGE',
  WEBSITE_PAGE: 'WEBSITE_PAGE',
  BLOG_POST: 'BLOG_POST',
  SOCIAL_BROADCAST: 'SOCIAL_BROADCAST',
  AD_CAMPAIGN: 'AD_CAMPAIGN',
  CAMPAIGN: 'CAMPAIGN',
  CTA: 'CTA',
  WORKFLOW: 'WORKFLOW',
  REPORTING_DASHBOARD: 'REPORTING_DASHBOARD',
  BLOG_LISTING_PAGE: 'BLOG_LISTING_PAGE',
  MARKETING_EVENT: 'MARKETING_EVENT',
  WEB_INTERACTIVE: 'WEB_INTERACTIVE',
  MARKETING_SMS: 'MARKETING_SMS'
};
export default CommentObjectType;