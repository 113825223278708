export const getMetaTags = ({
  userDOM,
  setGathererArtifact,
  editorStateSelectors
}) => {
  const metaTagsHTML = userDOM.querySelectorAll('head meta');
  const metaTags = metaTagsHTML && [...metaTagsHTML].map(metaTag => {
    const {
      name,
      content
    } = metaTag;
    return {
      name,
      content
    };
  });
  const metaDescriptionContent = editorStateSelectors.selectContentModelMetaDescription();
  const metaDescription = metaTags.find(metaTag => metaTag.name === 'description');

  if (metaDescription) {
    metaDescription.content = metaDescriptionContent;
  } else {
    metaTags.push({
      name: 'description',
      content: metaDescriptionContent
    });
  }

  setGathererArtifact('metaTags', metaTags);
};