'use es6';

export const pages = {
  billing: '/account-and-billing',
  dashboards: '/reports-dashboard',
  workflows: '/workflows',
  inbox: '/inbox',
  contentDetail: '/pages/[0-9]*/editor/[0-9]*/content',
  designManager: '/design-manager',
  advancedBuilder: '/advanced-builder/[0-9]*/$',
  advancedBuilderReport: '/advanced-builder/[0-9]*/report/',
  forecasting: '/forecasting',
  social: '/social',
  marketingEmail: '/email',
  submissions: '/submissions',
  campaigns: '/marketing/[0-9]*/campaigns',
  deal: '/0-3/views/.*/board',
  ads: '/ads',
  tasks: '/tasks',
  sequences: '/sequences',
  quotes: '/quotes/',
  analytics: '/analytics',
  indexCRM: '/objects/0-(?!(?:101))[0-9]*/views',
  // all objects except payments (object type Id 0-101)
  recordCRMContact: '/contact/',
  recordCRMCompany: '/company',
  chat: '/chatflows',
  userGuide: '/user-guide',
  dataSync: '/data-sync',
  crmLists: '/lists',
  crmSettings: '/properties',
  payments: '/objects/0-101/views/all/list',
  academy: '/academy',
  adminSetup: '/settings/[0-9]*/users$',
  import: '/import/[0-9]*',
  adminGovernanceImportExport: '/importexport',
  adminGovernancePermissionHistory: '/users/[0-9]*/logs',
  adminGovernanceProductUpdate: '/whats-new/[0-9]*/all',
  adminGovernanceDataProtection: '/data-protection',
  adminGovernanceConsentOptions: '/consent-options',
  adminGovernanceAccountDefaults: '/account-defaults/notifications/account-defaults',
  adminGovernanceNotificationProfile: '/account-defaults/notifications/profile',
  singleReportViewer: '/reports-list',
  reportLibrary: '/create/reports',
  purchaseExperience: '/purchases',
  journeyAnalytics: '/journey-builder',
  singleObjectBuilder: '/report-builder',
  multiTouchAttribution: '/attribution-builder',
  datasets: '/advanced-builder/[0-9]*/dataset/'
};
const urlWhitelist = Object.keys(pages).map(key => pages[key]);
export const matchesUrl = (urlFilter, location = window.location) => {
  const pathname = location && location.pathname || '';
  return pathname.match(urlFilter);
};
export const isUrlAllowed = location => {
  return urlWhitelist.some(url => matchesUrl(url, location));
};
export const matchesDemoAppUrl = location => location && location.pathname && location.pathname.indexOf('/nps-demo-app') >= 0;