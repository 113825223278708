'use es6';

import { requestStates } from 'redux-request-state';
import createReducer from 'redux-request-state/reducers/createReducer';
import * as requestKeys from 'ContentEditorUI/utils/requestStatesKeys';
import * as actionTypes from 'ContentEditorUI/redux/actions/actionTypes';
import { UNINITIALIZED } from 'redux-request-state/requestStates';
const {
  FAILED,
  STARTED,
  SUCCEEDED
} = requestStates;
const saveContentArtifactBuilders = {
  [actionTypes.CONTENT_SAVE_REQUEST_STARTED]: () => ({
    requestKey: requestKeys.CONTENT_SAVE_REQUEST,
    requestState: STARTED
  }),
  [actionTypes.CONTENT_SAVE_REQUEST_SUCCEEDED]: () => ({
    requestKey: requestKeys.CONTENT_SAVE_REQUEST,
    requestState: SUCCEEDED
  }),
  [actionTypes.CONTENT_SAVE_REQUEST_FAILED]: () => ({
    requestKey: requestKeys.CONTENT_SAVE_REQUEST,
    requestState: FAILED
  })
};
const fetchContentSchemaArtifactBuilders = {
  [actionTypes.FETCH_CONTENT_SCHEMA_STARTED]: () => ({
    requestKey: requestKeys.FETCH_CONTENT_SCHEMA_REQUEST,
    requestState: STARTED
  }),
  [actionTypes.FETCH_CONTENT_SCHEMA_SUCCEEDED]: () => ({
    requestKey: requestKeys.FETCH_CONTENT_SCHEMA_REQUEST,
    requestState: SUCCEEDED
  }),
  [actionTypes.FETCH_CONTENT_SCHEMA_FAILED]: ({
    error
  }) => ({
    fatalError: error,
    requestKey: requestKeys.FETCH_CONTENT_SCHEMA_REQUEST,
    requestState: FAILED
  })
};
const fetchModuleSchemasArtifactBuilders = {
  [actionTypes.FETCH_MODULE_SCHEMAS_STARTED]: () => ({
    requestKey: requestKeys.FETCH_MODULE_SCHEMAS_REQUEST,
    requestState: STARTED
  }),
  [actionTypes.FETCH_MODULE_SCHEMAS_SUCCEEDED]: () => ({
    requestKey: requestKeys.FETCH_MODULE_SCHEMAS_REQUEST,
    requestState: SUCCEEDED
  }),
  [actionTypes.FETCH_MODULE_SCHEMAS_FAILED]: ({
    error
  }) => ({
    fatalError: error,
    requestKey: requestKeys.FETCH_MODULE_SCHEMAS_REQUEST,
    requestState: FAILED
  })
};
const fetchSupportedLanguagesArtifactBuilders = {
  [actionTypes.FETCH_SUPPORTED_LANGUAGES_STARTED]: () => ({
    requestKey: requestKeys.FETCH_SUPPORTED_LANGUAGES_REQUEST,
    requestState: STARTED
  }),
  [actionTypes.FETCH_SUPPORTED_LANGUAGES_SUCCEEDED]: () => ({
    requestKey: requestKeys.FETCH_SUPPORTED_LANGUAGES_REQUEST,
    requestState: SUCCEEDED
  }),
  [actionTypes.FETCH_SUPPORTED_LANGUAGES_FAILED]: () => ({
    requestKey: requestKeys.FETCH_SUPPORTED_LANGUAGES_REQUEST,
    requestState: FAILED
  })
};
const fetchLayoutDataArtifactBuilders = {
  [actionTypes.FETCH_LAYOUT_DATA_STARTED]: () => ({
    requestKey: requestKeys.FETCH_LAYOUT_DATA_REQUEST,
    requestState: STARTED
  }),
  [actionTypes.FETCH_LAYOUT_DATA_SUCCEEDED]: () => ({
    requestKey: requestKeys.FETCH_LAYOUT_DATA_REQUEST,
    requestState: SUCCEEDED
  }),
  [actionTypes.FETCH_LAYOUT_DATA_FAILED]: ({
    payload = {}
  }) => ({
    requestKey: requestKeys.FETCH_LAYOUT_DATA_REQUEST,
    requestState: FAILED,
    retryable: payload.status !== 404
  })
};
const fetchTemplateInfoArtifactBuilders = {
  [actionTypes.FETCH_TEMPLATE_INFO_REQUEST]: () => ({
    requestKey: requestKeys.FETCH_TEMPLATE_INFO_REQUEST,
    requestState: STARTED
  }),
  [actionTypes.FETCH_TEMPLATE_INFO_SUCCESS]: () => ({
    requestKey: requestKeys.FETCH_TEMPLATE_INFO_REQUEST,
    requestState: SUCCEEDED
  }),
  [actionTypes.FETCH_TEMPLATE_INFO_FAIL]: () => ({
    requestKey: requestKeys.FETCH_TEMPLATE_INFO_REQUEST,
    requestState: FAILED
  })
};
const fetchRevisionsArtifactBuilders = {
  [actionTypes.FETCH_REVISIONS_STARTED]: () => ({
    requestKey: requestKeys.FETCH_REVISIONS_REQUEST,
    requestState: STARTED
  }),
  [actionTypes.FETCH_REVISIONS_SUCCEEDED]: () => ({
    requestKey: requestKeys.FETCH_REVISIONS_REQUEST,
    requestState: SUCCEEDED
  }),
  [actionTypes.FETCH_REVISIONS_FAILED]: () => ({
    requestKey: requestKeys.FETCH_REVISIONS_REQUEST,
    requestState: FAILED
  })
};
const fetchScheduledVersionArtifactBuilders = {
  [actionTypes.FETCH_SCHEDULED_VERSION_STARTED]: () => ({
    requestKey: requestKeys.FETCH_SCHEDULED_VERSION_REQUEST,
    requestState: STARTED
  }),
  [actionTypes.FETCH_SCHEDULED_VERSION_SUCCEEDED]: () => ({
    requestKey: requestKeys.FETCH_SCHEDULED_VERSION_REQUEST,
    requestState: SUCCEEDED
  }),
  [actionTypes.FETCH_SCHEDULED_VERSION_FAILED]: () => ({
    requestKey: requestKeys.FETCH_SCHEDULED_VERSION_REQUEST,
    requestState: FAILED
  })
};
const fetchThemeArtifactBuilders = {
  [actionTypes.FETCH_THEME_STARTED]: () => ({
    requestKey: requestKeys.FETCH_THEME_REQUEST,
    requestState: STARTED
  }),
  [actionTypes.FETCH_THEME_SUCCEEDED]: () => ({
    requestKey: requestKeys.FETCH_THEME_REQUEST,
    requestState: SUCCEEDED
  }),
  [actionTypes.FETCH_THEME_FAILED]: () => ({
    requestKey: requestKeys.FETCH_THEME_REQUEST,
    requestState: FAILED
  })
};
const fetchCustomFontsArtifactBuilders = {
  [actionTypes.FETCH_CUSTOM_FONTS_STARTED]: () => ({
    requestKey: requestKeys.FETCH_CUSTOM_FONTS_REQUEST,
    requestState: STARTED
  }),
  [actionTypes.FETCH_CUSTOM_FONTS_SUCCEEDED]: () => ({
    requestKey: requestKeys.FETCH_CUSTOM_FONTS_REQUEST,
    requestState: SUCCEEDED
  }),
  [actionTypes.FETCH_CUSTOM_FONTS_FAILED]: () => ({
    requestKey: requestKeys.FETCH_CUSTOM_FONTS_REQUEST,
    requestState: FAILED
  })
};
const fetchEditableSlugInfoArtifactBuilders = {
  [actionTypes.FETCH_EDITABLE_SLUG_INFO_REQUEST]: () => ({
    requestKey: requestKeys.FETCH_EDITABLE_SLUG_INFO_REQUEST,
    requestState: STARTED
  }),
  [actionTypes.FETCH_EDITABLE_SLUG_INFO_SUCCESS]: () => ({
    requestKey: requestKeys.FETCH_EDITABLE_SLUG_INFO_REQUEST,
    requestState: SUCCEEDED
  }),
  [actionTypes.FETCH_EDITABLE_SLUG_INFO_FAILED]: () => ({
    requestKey: requestKeys.FETCH_EDITABLE_SLUG_INFO_REQUEST,
    requestState: FAILED
  })
};
const fetchPortalScopesArtifactBuilders = {
  [actionTypes.FETCH_PORTAL_SCOPES_REQUEST]: () => ({
    requestKey: requestKeys.FETCH_PORTAL_SCOPES_REQUEST,
    requestState: STARTED
  }),
  [actionTypes.FETCH_PORTAL_SCOPES_SUCCESS]: () => ({
    requestKey: requestKeys.FETCH_PORTAL_SCOPES_REQUEST,
    requestState: SUCCEEDED
  }),
  [actionTypes.FETCH_PORTAL_SCOPES_FAILED]: () => ({
    requestKey: requestKeys.FETCH_PORTAL_SCOPES_REQUEST,
    requestState: FAILED
  })
};
const fetchMultiDomainPublishingScopeChangeArtifactBuilders = {
  [actionTypes.FETCH_MULTI_DOMAIN_PUBLISHING_SCOPE_CHANGE_REQUEST]: () => ({
    requestKey: requestKeys.FETCH_MULTI_DOMAIN_PUBLISHING_SCOPE_CHANGE_REQUEST,
    requestState: STARTED
  }),
  [actionTypes.FETCH_MULTI_DOMAIN_PUBLISHING_SCOPE_CHANGE_SUCCESS]: () => ({
    requestKey: requestKeys.FETCH_MULTI_DOMAIN_PUBLISHING_SCOPE_CHANGE_REQUEST,
    requestState: SUCCEEDED
  }),
  [actionTypes.FETCH_MULTI_DOMAIN_PUBLISHING_SCOPE_CHANGE_FAILED]: () => ({
    requestKey: requestKeys.FETCH_MULTI_DOMAIN_PUBLISHING_SCOPE_CHANGE_REQUEST,
    requestState: FAILED
  })
};
const fetchCustomDomainsPurgeDateArtifactBuilders = {
  [actionTypes.FETCH_CUSTOM_DOMAINS_PURGE_REQUEST]: () => ({
    requestKey: requestKeys.FETCH_CUSTOM_DOMAINS_PURGE_REQUEST,
    requestState: STARTED
  }),
  [actionTypes.FETCH_CUSTOM_DOMAINS_PURGE_SUCCESS]: () => ({
    requestKey: requestKeys.FETCH_CUSTOM_DOMAINS_PURGE_REQUEST,
    requestState: SUCCEEDED
  }),
  [actionTypes.FETCH_CUSTOM_DOMAINS_PURGE_FAILED]: () => ({
    requestKey: requestKeys.FETCH_CUSTOM_DOMAINS_PURGE_REQUEST,
    requestState: FAILED
  })
};
const fetchSitePagesPasswordProtectionPurgeDateArtifactBuilders = {
  [actionTypes.FETCH_SITE_PAGES_PASSWORD_PROTECTION_PURGE_DATE_REQUEST]: () => ({
    requestKey: requestKeys.FETCH_SITE_PAGES_PASSWORD_PROTECTION_PURGE_DATE_REQUEST,
    requestState: STARTED
  }),
  [actionTypes.FETCH_SITE_PAGES_PASSWORD_PROTECTION_PURGE_DATE_SUCCESS]: () => ({
    requestKey: requestKeys.FETCH_SITE_PAGES_PASSWORD_PROTECTION_PURGE_DATE_REQUEST,
    requestState: SUCCEEDED
  }),
  [actionTypes.FETCH_SITE_PAGES_PASSWORD_PROTECTION_PURGE_DATE_FAILED]: () => ({
    requestKey: requestKeys.FETCH_SITE_PAGES_PASSWORD_PROTECTION_PURGE_DATE_REQUEST,
    requestState: FAILED
  })
};
const fetchLandingPagesPasswordProtectionPurgeDateArtifactBuilders = {
  [actionTypes.FETCH_LANDING_PAGES_PASSWORD_PROTECTION_PURGE_DATE_REQUEST]: () => ({
    requestKey: requestKeys.FETCH_LANDING_PAGES_PASSWORD_PROTECTION_PURGE_DATE_REQUEST,
    requestState: STARTED
  }),
  [actionTypes.FETCH_LANDING_PAGES_PASSWORD_PROTECTION_PURGE_DATE_SUCCESS]: () => ({
    requestKey: requestKeys.FETCH_LANDING_PAGES_PASSWORD_PROTECTION_PURGE_DATE_REQUEST,
    requestState: SUCCEEDED
  }),
  [actionTypes.FETCH_LANDING_PAGES_PASSWORD_PROTECTION_PURGE_DATE_FAILED]: () => ({
    requestKey: requestKeys.FETCH_LANDING_PAGES_PASSWORD_PROTECTION_PURGE_DATE_REQUEST,
    requestState: FAILED
  })
};
const fetchAudienceAccessPurgeDateArtifactBuilders = {
  [actionTypes.FETCH_AUDIENCE_ACCESS_PURGE_DATE_REQUEST]: () => ({
    requestKey: requestKeys.FETCH_AUDIENCE_ACCESS_PURGE_DATE_REQUEST,
    requestState: STARTED
  }),
  [actionTypes.FETCH_AUDIENCE_ACCESS_PURGE_DATE_SUCCESS]: () => ({
    requestKey: requestKeys.FETCH_AUDIENCE_ACCESS_PURGE_DATE_REQUEST,
    requestState: SUCCEEDED
  }),
  [actionTypes.FETCH_AUDIENCE_ACCESS_PURGE_DATE_FAILED]: () => ({
    requestKey: requestKeys.FETCH_AUDIENCE_ACCESS_PURGE_DATE_REQUEST,
    requestState: FAILED
  })
};
const fetchDynamicPageAccessPurgeDateArtifactBuilders = {
  [actionTypes.FETCH_HUBDB_PAGE_TEMPLATE_PURGE_DATE_REQUEST]: () => ({
    requestKey: requestKeys.FETCH_HUBDB_PAGE_TEMPLATE_PURGE_DATE_REQUEST,
    requestState: STARTED
  }),
  [actionTypes.FETCH_HUBDB_PAGE_TEMPLATE_PURGE_DATE_SUCCESS]: () => ({
    requestKey: requestKeys.FETCH_HUBDB_PAGE_TEMPLATE_PURGE_DATE_REQUEST,
    requestState: SUCCEEDED
  }),
  [actionTypes.FETCH_HUBDB_PAGE_TEMPLATE_PURGE_DATE_FAILED]: () => ({
    requestKey: requestKeys.FETCH_HUBDB_PAGE_TEMPLATE_PURGE_DATE_REQUEST,
    requestState: FAILED
  })
};
const fetchHardSavedContentModelArtifactBuilders = {
  [actionTypes.FETCH_HARD_SAVED_CONTENT_MODEL_STARTED]: () => ({
    requestKey: requestKeys.FETCH_HARD_SAVED_CONTENT_MODEL_REQUEST,
    requestState: STARTED
  }),
  [actionTypes.FETCH_HARD_SAVED_CONTENT_MODEL_SUCCEEDED]: () => ({
    requestKey: requestKeys.FETCH_HARD_SAVED_CONTENT_MODEL_REQUEST,
    requestState: SUCCEEDED
  }),
  [actionTypes.FETCH_HARD_SAVED_CONTENT_MODEL_FAILED]: () => ({
    requestKey: requestKeys.FETCH_HARD_SAVED_CONTENT_MODEL_REQUEST,
    requestState: FAILED
  })
};
const fetchUsersArtifactBuilders = {
  [actionTypes.FETCH_USERS_STARTED]: () => ({
    requestKey: requestKeys.FETCH_USERS_REQUEST,
    requestState: STARTED
  }),
  [actionTypes.FETCH_USERS_SUCCEEDED]: () => ({
    requestKey: requestKeys.FETCH_USERS_REQUEST,
    requestState: SUCCEEDED
  }),
  [actionTypes.FETCH_USERS_FAILED]: () => ({
    requestKey: requestKeys.FETCH_USERS_REQUEST,
    requestState: FAILED
  })
};
const fetchInitialUserAttributesArtifactBuilders = {
  [actionTypes.FETCH_USER_ATTRIBUTES_STARTED]: () => ({
    requestKey: requestKeys.FETCH_INITIAL_USER_ATTRIBUTES_REQUEST,
    requestState: STARTED
  }),
  [actionTypes.FETCH_USER_ATTRIBUTES_SUCCEEDED]: () => ({
    requestKey: requestKeys.FETCH_INITIAL_USER_ATTRIBUTES_REQUEST,
    requestState: SUCCEEDED
  }),
  [actionTypes.FETCH_USER_ATTRIBUTES_FAILED]: () => ({
    requestKey: requestKeys.FETCH_INITIAL_USER_ATTRIBUTES_REQUEST,
    requestState: FAILED
  })
};
const fetchModuleErrorsArtifactBuilders = {
  [actionTypes.FETCH_MODULE_ERRORS_STARTED]: () => ({
    requestKey: requestKeys.FETCH_MODULE_ERRORS_REQUEST,
    requestState: STARTED
  }),
  [actionTypes.FETCH_MODULE_ERRORS_SUCCEEDED]: () => ({
    requestKey: requestKeys.FETCH_MODULE_ERRORS_REQUEST,
    requestState: SUCCEEDED
  }),
  [actionTypes.FETCH_MODULE_ERRORS_FAILED]: () => ({
    requestKey: requestKeys.FETCH_MODULE_ERRORS_REQUEST,
    requestState: FAILED
  })
};
const fetchMarketerSectionsArtifactBuilders = {
  [actionTypes.FETCH_MARKETER_SECTIONS_REQUEST]: () => ({
    requestKey: requestKeys.FETCH_MARKETER_SECTIONS_REQUEST,
    requestState: STARTED
  }),
  [actionTypes.FETCH_MARKETER_SECTIONS_SUCCESS]: () => ({
    requestKey: requestKeys.FETCH_MARKETER_SECTIONS_REQUEST,
    requestState: SUCCEEDED
  }),
  [actionTypes.FETCH_MARKETER_SECTIONS_FAILURE]: () => ({
    requestKey: requestKeys.FETCH_MARKETER_SECTIONS_REQUEST,
    requestState: FAILED
  })
};
const fetchDomainExclusionReasonArtifactBuilders = {
  [actionTypes.FETCH_DOMAIN_EXCLUSION_REASON_REQUEST]: () => ({
    requestKey: requestKeys.FETCH_DOMAIN_EXCLUSION_REASON,
    requestState: STARTED
  }),
  [actionTypes.FETCH_DOMAIN_EXCLUSION_REASON_SUCCESS]: () => ({
    requestKey: requestKeys.FETCH_DOMAIN_EXCLUSION_REASON,
    requestState: SUCCEEDED
  }),
  [actionTypes.FETCH_DOMAIN_EXCLUSION_REASON_FAILURE]: () => ({
    requestKey: requestKeys.FETCH_DOMAIN_EXCLUSION_REASON,
    requestState: FAILED
  })
};
const fetchGeneratedHublArtifactBuilders = {
  [actionTypes.FETCH_GENERATED_LAYOUT_HUBL_STARTED]: () => ({
    requestKey: requestKeys.FETCH_GENERATED_LAYOUT_HUBL_REQUEST,
    requestState: STARTED
  }),
  [actionTypes.FETCH_GENERATED_LAYOUT_HUBL_SUCCESS]: () => ({
    requestKey: requestKeys.FETCH_GENERATED_LAYOUT_HUBL_REQUEST,
    requestState: SUCCEEDED
  }),
  [actionTypes.FETCH_GENERATED_LAYOUT_HUBL_FAILURE]: () => ({
    requestKey: requestKeys.FETCH_GENERATED_LAYOUT_HUBL_REQUEST,
    requestState: FAILED
  })
};
const fetchCustomSectionsArtifactBuilders = {
  [actionTypes.FETCH_ADDABLE_SECTIONS_REQUEST]: () => ({
    requestKey: requestKeys.FETCH_CUSTOM_SECTIONS_REQUEST,
    requestState: STARTED
  }),
  [actionTypes.FETCH_ADDABLE_SECTIONS_SUCCESS]: () => ({
    requestKey: requestKeys.FETCH_CUSTOM_SECTIONS_REQUEST,
    requestState: SUCCEEDED
  }),
  [actionTypes.FETCH_ADDABLE_SECTIONS_FAILURE]: () => ({
    requestKey: requestKeys.FETCH_CUSTOM_SECTIONS_REQUEST,
    requestState: FAILED
  }),
  [actionTypes.MARK_ADDABLE_SECTIONS_AS_STATE]: () => ({
    requestKey: requestKeys.FETCH_CUSTOM_SECTIONS_REQUEST,
    requestState: UNINITIALIZED
  })
};
const baseArtifactBuilderLookup = Object.assign({}, fetchContentSchemaArtifactBuilders, {}, fetchModuleSchemasArtifactBuilders, {}, fetchSupportedLanguagesArtifactBuilders, {}, saveContentArtifactBuilders, {}, fetchLayoutDataArtifactBuilders, {}, fetchTemplateInfoArtifactBuilders, {}, fetchRevisionsArtifactBuilders, {}, fetchThemeArtifactBuilders, {}, fetchCustomFontsArtifactBuilders, {}, fetchEditableSlugInfoArtifactBuilders, {}, fetchPortalScopesArtifactBuilders, {}, fetchMultiDomainPublishingScopeChangeArtifactBuilders, {}, fetchHardSavedContentModelArtifactBuilders, {}, fetchUsersArtifactBuilders, {}, fetchInitialUserAttributesArtifactBuilders, {}, fetchModuleErrorsArtifactBuilders, {}, fetchCustomDomainsPurgeDateArtifactBuilders, {}, fetchSitePagesPasswordProtectionPurgeDateArtifactBuilders, {}, fetchLandingPagesPasswordProtectionPurgeDateArtifactBuilders, {}, fetchAudienceAccessPurgeDateArtifactBuilders, {}, fetchDynamicPageAccessPurgeDateArtifactBuilders, {}, fetchMarketerSectionsArtifactBuilders, {}, fetchDomainExclusionReasonArtifactBuilders, {}, fetchScheduledVersionArtifactBuilders, {}, fetchGeneratedHublArtifactBuilders, {}, fetchCustomSectionsArtifactBuilders);

const createRequestStatesReducer = (extraArtifactBuilders = {}) => {
  const artifactBuilderLookup = Object.assign({}, baseArtifactBuilderLookup, {}, extraArtifactBuilders);

  const getArtifactFromAction = action => {
    const buildArtifactFromAction = artifactBuilderLookup[action.type];
    return buildArtifactFromAction && buildArtifactFromAction(action) || null;
  };

  return createReducer(getArtifactFromAction);
};

export default createRequestStatesReducer;