import { useEffect, useState } from 'react';
import { fetchCommentsCached } from '../api/Commenting';
import { RESOLVABLE_COMMENTS_TOUR_COMPLETE } from '../constants/localStorageKeys';
import { RESOLVABLE_COMMENTS_TOUR_ID } from '../constants/tourIDs';
import useOnboardingTour from './useOnboardingTour';
export default function useResolvableCommentsTour({
  objectType,
  objectId,
  isOpen
}) {
  const [hasComments, setHasComments] = useState(false);
  useEffect(() => {
    if (objectType && objectId) {
      fetchCommentsCached({
        objectType,
        objectId
      }).then(({
        results
      }) => {
        setHasComments(results.length > 0);
      }).catch(__e => {// Do nothing
      });
    }
  }, [objectType, objectId]);
  return useOnboardingTour({
    featureIsVisible: isOpen && hasComments,
    localStorageKey: RESOLVABLE_COMMENTS_TOUR_COMPLETE,
    tourId: RESOLVABLE_COMMENTS_TOUR_ID
  });
}