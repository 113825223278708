import { createSelector } from 'reselect'; // @ts-expect-error file is untyped JS

import { getActiveApexDomain } from 'ContentEditorUI/redux/selectors/portalDomainsSelectors';
import { removeDomainFromTitle, removeTitleFromMetaDescription, addMetaDescription, addPageTitle, shortenMetaDescription, shortenPageTitle, openImageModule // @ts-expect-error file is untyped JS
} from 'ContentEditorUI/redux/actions/recommenderActions';
export const getDefaultXrayEditorApi = createSelector([getActiveApexDomain], apexDomain => {
  return {
    apexDomain,
    addMetaDescription,
    addPageTitle,
    removeDomainFromTitle,
    removeTitleFromMetaDescription,
    openImageModule,
    shortenMetaDescription,
    shortenPageTitle
  };
});