import { createSelector } from 'reselect';
import { basicSelector } from 'ContentEditorUI/redux/selectors/helpers';
import { getIsUngatedForInlineDataTokenEditing } from './authSelectors';
import { hubDBFieldtoCmvField, mapHubDBValueToModuleValue } from '../../utils/hubDb'; // @ts-expect-error not converted yet

import { getDynamicPageDataSourceId } from './baseContentModelSelectors';
import { getPublishedTablesSortedByPublishedTime, getTablesUsedForPages } from '../../containers/hubdb/utils';
export const getAllTables = state => state.pageSettings.get('availableHubDbTables').toJS();
export const getTableId = (state, props) => props.tableId;
export const getSelectedTable = (state, props) => state.instanceEditing && state.instanceEditing.hubDbTable || state.pageSettings.get('availableHubDbTables').toJS().find(t => t.id.toString() === props.tableId);
export const getInstanceEditingState = basicSelector(state => state.instanceEditing);
export const getHubDBTableSchemaColumns = createSelector([getSelectedTable], selectedTable => selectedTable.columns);
export const getAllTablesSortedByPublished = createSelector(getAllTables, getPublishedTablesSortedByPublishedTime);
export const getSortByPublishedAndDynamic = createSelector(getAllTablesSortedByPublished, getTablesUsedForPages);
export const selectForeignTableOptions = createSelector([getSortByPublishedAndDynamic, getTableId], (tables, tableId) => {
  if (!tables) return [];
  return tables.filter(table => table.id.toString() !== tableId).map(table => {
    return [table.id, table.label];
  });
});
export const getTableAsModuleSchema = createSelector([getInstanceEditingState], instanceEditing => instanceEditing && instanceEditing.tableSchema);
export const getTableRows = createSelector([getInstanceEditingState], instanceEditing => instanceEditing && instanceEditing.tableRows);
export const getActiveHubDbRowId = createSelector([getInstanceEditingState], instanceEditing => instanceEditing && instanceEditing.currentHubDbRowId);
export const getCurrentRow = createSelector([getTableRows, getActiveHubDbRowId], (rows, activeId) => rows.find(row => row.id === activeId));
export const getModuleValues = createSelector([getCurrentRow, getTableAsModuleSchema], (currentRow, moduleSchema) => {
  if (currentRow) {
    return mapHubDBValueToModuleValue(currentRow, moduleSchema);
  }

  return {};
}); // TODO: Mostly stolen from Troys work. We should probably refactor this into a util and out of selectors

export const getMappedHubDbValuesToModuleFieldsForCurrentRow = createSelector([getTableAsModuleSchema, getCurrentRow, getHubDBTableSchemaColumns, selectForeignTableOptions, getModuleValues], (tableSchema, currentRow, columns, foreignTableOptions, moduleValues) => {
  if (!tableSchema || !currentRow || !currentRow.id || columns) {
    return {};
  }

  return hubDBFieldtoCmvField(tableSchema.fields, currentRow.id, columns, moduleValues, {
    foreignTableOptions
  });
});
export const getCurrentHubDbFieldsAsSimpleMap = createSelector([getCurrentRow, getTableAsModuleSchema], (currentRow, tableSchema) => {
  const fields = [];

  if (currentRow) {
    tableSchema.fields.forEach(field => {
      const rowValueIndex = parseInt(field.id, 10);
      const value = currentRow.values[rowValueIndex];
      fields.push({
        name: field.name,
        id: field.id,
        type: field.type,
        value
      });
    });
  }

  return fields;
});
export const getInstanceEditingPreviewParams = createSelector([getActiveHubDbRowId], hubDbRowId => {
  // Mostly a means by which downstream iframe url selectors continue to work
  // even if the instance editing reducer is not in this editor
  return hubDbRowId ? {
    hubdb_row_id: hubDbRowId
  } : {};
});
export const getIsInstanceEditingModeActive = createSelector([getInstanceEditingState], instanceEditing => instanceEditing && instanceEditing.instanceEditingModeActive);
export const getShouldShowInstanceEditingMode = createSelector([getIsUngatedForInlineDataTokenEditing, getDynamicPageDataSourceId], (isUngatedForInlineDataTokenEditing, dynamicPageDataSourceId) => {
  return isUngatedForInlineDataTokenEditing && !!dynamicPageDataSourceId;
});