'use es6';

import EarlyRequester from './EarlyRequester';
import Iris from './Iris';
import User from './User';
/**
 * @param {object} config - contains `apiClient` and `portalId` which can be used to initialize
 **/

export const initializeAll = config => {
  return Promise.all([EarlyRequester(config), Iris(config), User(config)]).then(([early, iris, user]) => {
    return {
      EarlyRequester: early,
      Iris: iris,
      User: user
    };
  });
};