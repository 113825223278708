/* hs-eslint ignored failing-rules */

/* eslint-disable promise/catch-or-return */
'use es6';

import PortalIdParser from 'PortalIdParser';
import FloatingAlertStore from 'UIComponents/alert/FloatingAlertStore';
import { configureGetErrorTitle, configureGetErrorMessage } from 'error-messaging-js';
import { getContentTypeStringFromCategoryId, getContentAppPathFromCategoryId } from 'ContentEditorUI/utils/publishUtils';
import userInfo from 'hub-http/userInfo';
import { getLanguageNameFromKey } from 'ContentEditorUI/utils/multiLangUtils';
import { captureMessage } from 'ContentEditorUI/lib/exceptions';
import { EditorMetricsTracker } from 'ContentEditorUI/utils/metricsTracker';
const CLIENT_ERRORS = [412, 409, 404, 401];
const QUERY_TOO_LARGE_ERROR = 'QUERY_TOO_LARGE';
const ErrorAlertTypes = {
  SAVE: 'save'
};
const ErrorActionTypes = {
  SAVE: 'save',
  SAVE_TOO_LARGE: 'saveTooLarge'
};
export const getErrorTitle = configureGetErrorTitle({
  logSentry: true
});
export const getErrorMessage = configureGetErrorMessage({
  logSentry: true
});
const prefixKey = 'app.errors';
const reportedSaveErrors = {};
export const CONFLICT_ERRORS = 409;
export const showErrorWithHTML = data => {
  const {
    additionalTranslationOptions = {},
    errorAlertType
  } = data;
  FloatingAlertStore.addAlert({
    titleText: getErrorTitle(Object.assign({
      prefixKey
    }, data, {}, additionalTranslationOptions)),
    message: getErrorMessage(Object.assign({
      prefixKey
    }, data)),
    type: 'danger',
    errorAlertType
  });
};
export const isLocked = errorResponse => {
  return errorResponse && errorResponse.status === 412 && (!errorResponse.responseJSON || !errorResponse.responseJSON.retryable);
};
export const getAdditionalTranslationOptionsForSaveError = (responseJSON, categoryId, subcategory) => {
  if (!responseJSON) {
    return {};
  }

  const additionalTranslationOptions = {};
  const {
    errorTokens
  } = responseJSON;

  if (errorTokens) {
    const contentTypeToken = getContentTypeStringFromCategoryId(categoryId, subcategory); // default translated tokens to pass to message

    additionalTranslationOptions.portalId = PortalIdParser.get();
    additionalTranslationOptions.translatedContentType = contentTypeToken; // specific tokens needing translations from API errorTokens

    const conflictCategoryId = errorTokens.CONFLICT_CATEGORY_ID;

    if (conflictCategoryId && conflictCategoryId.length) {
      additionalTranslationOptions.conflictCategory = getContentTypeStringFromCategoryId(conflictCategoryId[0]);
      additionalTranslationOptions.contentPath = getContentAppPathFromCategoryId(conflictCategoryId[0]);
      additionalTranslationOptions.contentType = contentTypeToken;
    }

    if (errorTokens.language && errorTokens.language.length > 0) {
      additionalTranslationOptions.language = errorTokens.language.map(getLanguageNameFromKey);
    }
  }

  return additionalTranslationOptions;
};
export const clearSaveAlerts = () => {
  const alerts = FloatingAlertStore.getAlerts();
  Object.keys(alerts).forEach(key => {
    if (alerts[key].errorAlertType === ErrorAlertTypes.SAVE) {
      FloatingAlertStore.removeAlert(key);
    }
  });
};

const saveAction = errorResponse => {
  if (errorResponse && errorResponse.errorType === QUERY_TOO_LARGE_ERROR) {
    return ErrorActionTypes.SAVE_TOO_LARGE;
  }

  return ErrorActionTypes.SAVE;
};

const showSaveError = (response, category, subcategory) => {
  if (isLocked(response)) {
    return; // Handled by the LockedModal
  }

  showErrorWithHTML({
    response,
    action: saveAction(response.responseJSON),
    errorAlertType: ErrorAlertTypes.SAVE,
    additionalTranslationOptions: getAdditionalTranslationOptionsForSaveError(response.responseJSON, category, subcategory)
  });
};

const logSaveError = errorResponse => {
  if (!errorResponse.responseJSON && errorResponse.status === undefined) {
    console.log(errorResponse);
    return;
  }

  if (errorResponse.status && !reportedSaveErrors[errorResponse.status] && !CLIENT_ERRORS.includes(errorResponse.status)) {
    try {
      userInfo().then(({
        user
      }) => {
        const url = errorResponse.xhr && errorResponse.xhr.responseURL ? errorResponse.xhr.responseURL : '?';
        const extra = {
          url,
          responseText: errorResponse.responseText,
          user: user.email
        };
        EditorMetricsTracker.counter('content-save-errors').increment();
        captureMessage(`Content save error ${errorResponse.status}`, {
          extra
        });
        reportedSaveErrors[errorResponse.status] = true;
      });
    } catch (e) {
      return;
    }
  }
};

export const showAndLogSaveError = (response, category, subcategory) => {
  showSaveError(response, category, subcategory);
  logSaveError(response);
};