import { getHttpClientAsLastParam } from 'ContentData/helpers/apiHelpers';
import { CREATE_DEFAULTS } from 'ContentData/constants/apiDefaults';

function getQuery(query) {
  return Object.assign({
    state__in: ['automated_for_form', 'automated_for_form_draft']
  }, query);
}
/**
 * GET cosemail/v1/emails/{id}
 * @param {number} id Email id
 * @returns {Promise} fetch email by id
 */


export function fetchEmail(id, ...rest) {
  const httpClient = getHttpClientAsLastParam(rest);
  return httpClient.get(`cosemail/v1/emails/${id}`);
}
/**
 * PUT cosemail/v1/emails/{id}/update-and-publish
 * @param {number} id Email id
 * @param {Object} emailData Email data
 * @returns {Promise} save email data by id
 */

export function saveEmail(emailData, id, ...rest) {
  const httpClient = getHttpClientAsLastParam(rest);
  return httpClient.put(`cosemail/v1/emails/${id}/update-and-publish`, {
    data: emailData
  });
}
/**
 * POST cosemail/v1/emails/create-plain-email
 * @param {Object} emailData Email data
 * @returns {Promise} created new email with initial email data
 */

export function createPlainEmail(emailData, ...rest) {
  const httpClient = getHttpClientAsLastParam(rest);
  return httpClient.post(`cosemail/v1/emails/create-plain-email`, {
    data: emailData
  });
}
/**
 * GET cosemail/v1/emails
 * @param {Object} query Query params
 * @returns {Promise} fetch paginated emails
 */

export function fetchEmails(query = {}, ...rest) {
  const httpClient = getHttpClientAsLastParam(rest);
  return httpClient.get('cosemail/v1/emails', {
    query: getQuery(query)
  });
}
/**
 * GET cosemail/v1/emails/search/automated-with-ticket-and-deal
 * @param {Object} query Query params
 * @returns {Promise} fetch paginated automated emails
 */

export function fetchAutomatedEmails(query = {}, ...rest) {
  const httpClient = getHttpClientAsLastParam(rest);
  return httpClient.get('cosemail/v1/emails/search/automated-with-ticket-and-deal', {
    query: Object.assign({}, query, {
      emailTypes: ['AUTOMATED_EMAIL', 'AUTOMATED_AB_EMAIL'],
      emailSortField: 'UPDATED',
      sortOrder: 'DESC'
    })
  });
}
/**
 * POST cosemail/v1/emails
 * @param {string} name Email name
 * @returns {Promise} create email
 */

export function createEmail(name, ...rest) {
  const httpClient = getHttpClientAsLastParam(rest);
  return httpClient.post('cosemail/v1/emails', {
    query: CREATE_DEFAULTS.emails.query,
    data: Object.assign({
      name
    }, CREATE_DEFAULTS.emails.data)
  });
}
/**
 * GET email-ownership/v1/owned-addresses
 */

export const fetchOwnedEmailAddresses = (...rest) => {
  const httpClient = getHttpClientAsLastParam(rest);
  return httpClient.get('email-ownership/v1/owned-addresses');
};
/**
 * GET email-ownership/v1/owned-addresses
 */

export const fetchConnectedInboxAccounts = (...rest) => {
  const httpClient = getHttpClientAsLastParam(rest);
  return httpClient.get('conversations-email/v1/connected-accounts/all');
};
export const fetchCanSpam = (...rest) => {
  const httpClient = getHttpClientAsLastParam(rest);
  return httpClient.get('content/api/v4/can-spam-settings', {
    query: {
      casing: 'snake',
      limit: 500
    }
  });
};
export function convertLegacyFollowupEmail(email, ...rest) {
  const httpClient = getHttpClientAsLastParam(rest);
  const {
    id,
    language,
    name
  } = email;
  return httpClient.post(`marketing-emails/v1/emails/${id}/convert-forms-followup-to-automated`, {
    data: {
      language,
      name
    }
  });
}