'use es6';

import { createSelector } from 'reselect';
import { getContentModelId, stateSelector } from 'ContentEditorUI/redux/selectors/baseContentModelSelectors';
import { basicSelector } from 'ContentEditorUI/redux/selectors/helpers';
import { getHasPermissionsToCreateOrEditABTest } from 'ContentEditorUI/redux/selectors/authSelectors';
import { getRoutingPathname } from 'ContentEditorUI/redux/selectors/routingSelectors';
const AB_VARIANT_STATUS = ['variant', 'automated_variant', 'automated_loser_variant'];
export const getABTest = basicSelector(state => state.abTest);
export const getABTestID = createSelector([getABTest], abTest => abTest && abTest.get('abTestId'));
export const getDisplayEndTestModal = createSelector([getABTest], abTest => abTest && abTest.get('forceEndAb'));
export const getIsABState = stateSelector('_AB');
export const getIsAbTest = createSelector([getIsABState, getABTestID], (isAbState, ABTestId) => {
  return isAbState || Boolean(ABTestId);
});
export const getABMasterUrl = createSelector([getContentModelId, getABTest, getRoutingPathname], (contentId, abtest, __pathname) => {
  if (!abtest || !abtest.get('master')) {
    return '';
  }

  return window.location.pathname.replace(contentId, abtest.getIn(['master', 'id']));
});
export const getABVariantUrl = createSelector([getContentModelId, getABTest, getRoutingPathname], (contentId, abtest, __pathname) => {
  if (!abtest || !abtest.get('activeVariant')) {
    return '';
  }

  return window.location.pathname.replace(contentId, abtest.getIn(['activeVariant', 'id']));
});
export const getIsABVariant = createSelector([getABTest], abTest => !!(abTest && AB_VARIANT_STATUS.includes(abTest.get('abStatus'))));
export const getIsABVariantWithoutAccess = createSelector([getIsABVariant, getHasPermissionsToCreateOrEditABTest], (isABVariant, hasPermissionsToCreateOrEditABTest) => isABVariant && !hasPermissionsToCreateOrEditABTest);
export const getAbVariation = createSelector([getABTest], abTest => abTest.get('abVariation'));
export const getAbActiveVariant = createSelector([getABTest], abTest => abTest && abTest.get('activeVariant'));
export const getAbActiveVariantContent = createSelector([getABTest], abTest => abTest && abTest.getIn(['activeVariant', 'content']));
export const getAbActiveVariantId = createSelector([getABTest], abTest => abTest && abTest.getIn(['activeVariant', 'id']));
export const getAbActiveVariantName = createSelector([getABTest], abTest => abTest && abTest.getIn(['activeVariant', 'name']));
export const getAbMaster = createSelector([getABTest], abTest => abTest && abTest.get('master'));
export const getAbMasterContent = createSelector([getABTest], abTest => abTest && abTest.getIn(['master', 'content']));
export const getAbMasterId = createSelector([getABTest], abTest => abTest.getIn(['master', 'id']));
export const getAbMasterName = createSelector([getABTest], abTest => abTest.getIn(['master', 'name']));
export const getTotalNumberOfActiveAbVariations = createSelector([getABTest], abtest => abtest.get('totalActive') ? abtest.get('totalActive') : 1 // TODO, is the right answer 1 or 0 here !!!
);
export const getAbTestPages = createSelector([getABTestID, getAbMasterContent, getAbActiveVariantContent], (abTestId, abMaster, abVariation) => {
  return abTestId && abVariation ? [{
    id: abMaster.get('id'),
    language: abMaster.get('language'),
    name: abMaster.get('name'),
    masterId: abMaster.get('translatedFromId'),
    translatedFromId: abMaster.get('translatedFromId'),
    ab: true,
    mab: false
  }, {
    id: abVariation.get('id'),
    language: abMaster.get('language'),
    name: abVariation.get('name'),
    masterId: abVariation.get('translatedFromId'),
    translatedFromId: abVariation.get('translatedFromId'),
    ab: true,
    mab: false
  }] : null;
});
export const getHasAbTestStarted = createSelector([getABTest], abTest => abTest && abTest.get('allPublished'));
export const getIsAbTestWithoutAccess = createSelector([getHasPermissionsToCreateOrEditABTest, getIsAbTest], (hasPermissionsToCreateAbTest, isAbTest) => isAbTest && !hasPermissionsToCreateAbTest);
export const getIsABTestActivelyRunning = createSelector([getIsAbTest, getTotalNumberOfActiveAbVariations], (isAbTest, activeVariations) => {
  return isAbTest && activeVariations > 1;
});