import { CompletionStatuses } from '../../../constants';
const MAX_H1_COUNT = 1;
export const addH1TagsAudit = artifacts => {
  const {
    h1Count
  } = artifacts;
  let status;

  if (h1Count === MAX_H1_COUNT) {
    status = CompletionStatuses.COMPLETE;
  } else {
    status = CompletionStatuses.INCOMPLETE;
  }

  return {
    status,
    h1Count
  };
};