/* hs-eslint ignored failing-rules */

/* eslint-disable no-extra-boolean-cast */
import { useEffect, useState } from 'react';
import Raven from 'raven-js';
import { useUpgradeModal } from './useUpgradeModal';
import { UpgradeMessageEventKeys } from 'ui-addon-upgrades/_core/common/constants/upgradeMessageEventKeys';
import { getUpgradeDataTrackingProperties, getUpgradePointPromise, upgradePointViews } from './utils';
import { track } from '../_core/common/eventTracking/tracker';

const handleFetchUpgradePoint = (modalKey, isFeatureLocked) => {
  const upgradePointPromise = getUpgradePointPromise({
    upgradeKey: modalKey,
    delayCall: true
  });
  upgradePointPromise.then(upgradePoint => {
    if (isFeatureLocked && upgradePoint) {
      if (!upgradePointViews[modalKey]) {
        upgradePointViews[modalKey] = true;
        track('interaction', Object.assign({
          action: 'viewed upgrade point'
        }, getUpgradeDataTrackingProperties(upgradePoint, true, true)));
      }
    }
  }).catch(error => {
    Raven.captureException(error);
  });
};
/**
 * `useUpgradeEventListener` should be called in the top window of your
 * application when using `useUpgradeModal` inside of an iframe. This will
 * mount an event listener to listen for upgrade events, which will in turn
 * open the upgrade modal in the parent window instead of the iframe.
 *
 * @example
 * ```tsx
 * const WrapperComponent = () => {
 *   useUpgradeEventListener();
 *
 *   return <iframe src="your-source.hubspot.com" />;
 * }
 * ```
 */


export const useUpgradeEventListener = () => {
  // We store this item in state as an object in order
  // to re-evaluate this component on every message.
  // This is because when the modal key changes,
  // we need `useUpgradeModal` to re-run so that
  // `openUpgradeModal` opens for the right upgrade point.
  const [key, setModalKey] = useState({
    inner: ''
  });
  const {
    openUpgradeModal
  } = useUpgradeModal(key.inner);
  useEffect(() => {
    const handleMessage = messageEvent => {
      const {
        data: {
          modalKey,
          event,
          isFeatureLocked
        }
      } = messageEvent;

      switch (event) {
        case UpgradeMessageEventKeys.OPEN_MODAL:
          setModalKey({
            inner: modalKey
          });
          break;

        case UpgradeMessageEventKeys.FETCH_UPGRADE_POINT:
          handleFetchUpgradePoint(modalKey, isFeatureLocked);
          break;

        default:
          break;
      }
    };

    window.addEventListener('message', handleMessage);
    return () => window.removeEventListener('message', handleMessage);
  }, []);
  useEffect(() => {
    if (!!key.inner) {
      openUpgradeModal();
    }
  }, [key, openUpgradeModal]);
};