import { CompletionStatuses } from '../../../constants';
export const addTitleAudit = artifacts => {
  const pageTitle = artifacts.pageTitle;
  let status;

  if (pageTitle === '' || pageTitle === undefined) {
    status = CompletionStatuses.INCOMPLETE;
  } else {
    status = CompletionStatuses.COMPLETE;
  }

  return {
    status
  };
};