'use es6';

import _objectWithoutPropertiesLoose from "@babel/runtime/helpers/esm/objectWithoutPropertiesLoose";
import FloatingAlertStore from 'UIComponents/alert/FloatingAlertStore';

const createNotificationAction = notificationType => ({
  message,
  sticky = false,
  timeout,
  title
}) => FloatingAlertStore.addAlert({
  message,
  sticky,
  timeout,
  titleText: title,
  type: notificationType
});

export const showErrorNotification = createNotificationAction('danger');
export const showWarningNotification = createNotificationAction('warning');
export const showSuccessNotification = createNotificationAction('success');
export const showRemovableNotification = (_ref) => {
  let {
    type = 'success'
  } = _ref,
      notificationArgs = _objectWithoutPropertiesLoose(_ref, ["type"]);

  const id = `alert-${Math.floor(Math.random() * 10000)}`;
  FloatingAlertStore.addAlert(Object.assign({}, notificationArgs, {
    id,
    type
  }));
  return () => FloatingAlertStore.removeAlert(id);
};