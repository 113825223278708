import _objectWithoutPropertiesLoose from "@babel/runtime/helpers/esm/objectWithoutPropertiesLoose";
import { createSelector } from 'reselect';
import { memoize } from 'underscore';
import { createGenericOptionsSelector } from 'ContentData/helpers/reduxHelpers';
import { getWorkflowHybridPermissions, getFollowupEmailAction, isSimpleWorkflowForForm } from 'ContentData/helpers/workflowsHelpers';
import { getFormWorkflowDependencies } from 'ContentData/selectors/forms';
import { SUCCEEDED, FAILED } from 'ContentUtils/constants/RequestStatus';
export const getWorkflow = (state, props) => state.resources.workflows.workflows[props.id];
export const getWorkflows = state => state.resources.workflows.workflows;
export const getWorkflowsOptions = createGenericOptionsSelector(getWorkflows);
export const getWorkflowFetchStatus = (state, props) => state.resources.workflows.requestStatus[props.id];
export const getWorkflowHybrids = state => state.resources.workflows.hybrids;
export const getWorkflowHybridsRequestStatus = state => state.resources.workflows.hybridsRequestStatus;
export const getWorkflowHybrid = (state, props) => {
  return getWorkflowHybrids(state)[props.id];
};
export const getWorkflowSummaries = state => state.resources.workflows.summaries;
export const getAllWorkflowHybridsHaveLoaded = createSelector([getFormWorkflowDependencies, getWorkflowHybridsRequestStatus], (formWorkflowDependencies, workflowHybridsRequestStatus) => {
  if (!formWorkflowDependencies) return false;
  return formWorkflowDependencies.every(flowId => {
    const requestStatus = workflowHybridsRequestStatus[flowId];
    return requestStatus === SUCCEEDED || requestStatus === FAILED;
  });
});

const getFormGuid = (state, props) => props.formGuid;

const getContentId = (state, props) => props.contentId;

const getShowFollowupEmail = (state, props) => props.showFollowupEmail;

const memoizedGetFilteredHydratedFormWorkflowDependencies = memoize((formWorkflowDependencies, workflowHybrids, formGuid, contentId, showFollowupEmail = false) => {
  const workflows = [];

  if (formWorkflowDependencies && formWorkflowDependencies.length) {
    formWorkflowDependencies.forEach(flowId => {
      const workflowHybrid = workflowHybrids[flowId];
      const workflowHybridPermissions = workflowHybrid && getWorkflowHybridPermissions(workflowHybrid, formGuid, contentId, showFollowupEmail);

      const _ref = workflowHybridPermissions || {},
            {
        shouldShow
      } = _ref,
            permissions = _objectWithoutPropertiesLoose(_ref, ["shouldShow"]);

      const hybridWithPermissions = Object.assign({}, workflowHybrid, {}, permissions);

      if (shouldShow) {
        workflows.push(hybridWithPermissions);
      }
    });
  }

  return workflows;
}, (formWorkflowDependencies, workflowHybrids, formGuid, contentId) => {
  return `
      ${formWorkflowDependencies && formWorkflowDependencies.join('.')}-
      ${JSON.stringify(workflowHybrids)}-
      ${formGuid}-${contentId}
      `;
});
export const getFilteredHydratedFormWorkflowDependencies = createSelector([getFormWorkflowDependencies, getWorkflowHybrids, getFormGuid, getContentId, getShowFollowupEmail], memoizedGetFilteredHydratedFormWorkflowDependencies);
export const getWorkflowsThatSendEmails = createSelector([getFilteredHydratedFormWorkflowDependencies], formWorkflowDependencies => {
  return formWorkflowDependencies.filter(workflowHybrid => {
    if (workflowHybrid.isEnabled) {
      const emailAction = getFollowupEmailAction(workflowHybrid);

      if (emailAction) {
        const {
          contentId: emailId
        } = emailAction.metadata || {};
        return emailId;
      }
    }

    return false;
  });
});
const memoizedGetFormSimpleWorkflowsCount = memoize((formWorkflowDependencies, workflowHybrids, formGuid) => {
  if (formWorkflowDependencies && formWorkflowDependencies.length) {
    return formWorkflowDependencies.reduce((acc, flowId) => {
      const workflowHybrid = workflowHybrids[flowId];
      return workflowHybrid && isSimpleWorkflowForForm(workflowHybrid, formGuid) ? acc + 1 : acc;
    }, 0);
  }

  return 0;
}, (formWorkflowDependencies, workflowHybrids, formGuid) => {
  return `
      ${formWorkflowDependencies && formWorkflowDependencies.join('.')}-
      ${JSON.stringify(workflowHybrids)}-
      ${formGuid}
      `;
});
export const getFormSimpleWorkflowsCount = createSelector([getFormWorkflowDependencies, getWorkflowHybrids, getFormGuid], memoizedGetFormSimpleWorkflowsCount);
const memoizedGetFormWorkflowDependenciesMap = memoize(filteredHydratedFormWorkflowDependencies => {
  return filteredHydratedFormWorkflowDependencies.reduce((acc, flow) => {
    return Object.assign({}, acc, {
      [flow.flowId]: flow
    });
  }, {});
}, filteredHydratedFormWorkflowDependencies => {
  return filteredHydratedFormWorkflowDependencies.map(flow => flow.flowId).join('.');
});
const getFormWorkflowDependenciesMap = createSelector([getFilteredHydratedFormWorkflowDependencies], memoizedGetFormWorkflowDependenciesMap);
export const getWorkflowSummariesOptions = createSelector([getFormWorkflowDependenciesMap, getWorkflowSummaries], (formWorkflowDependenciesMap, workflowSummaries) => {
  return workflowSummaries.map(summary => {
    return {
      text: summary.name,
      value: summary.flowId,
      disabled: !!formWorkflowDependenciesMap[summary.flowId]
    };
  });
});