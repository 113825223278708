import PropTypes from 'prop-types';
import { pick } from 'underscore';
export const ContentTypes = Object.freeze({
  PAGE: 'PAGE',
  EMAIL: 'EMAIL',
  CTA: 'CTA',
  TEMPLATE: 'TEMPLATE',
  MODULE: 'MODULE',
  GLOBAL_MODULE: 'GLOBAL_MODULE'
});
export const TargetTypes = Object.freeze({
  WIDGET: 'WIDGET',
  CUSTOM_HTML_WIDGET: 'CUSTOM_HTML_WIDGET',
  FORM_WIDGET: 'FORM_WIDGET',
  EMAIL_FIELD: 'EMAIL_FIELD'
});
export const CriterionTypes = Object.freeze({
  GEO_IP: 'GEO_IP',
  DEVICE_TYPE: 'DEVICE_TYPE',
  TRAFFIC_SOURCE: 'TRAFFIC_SOURCE',
  LANGUAGE: 'LANGUAGE',
  CONTACT_LIST: 'CONTACT_LIST',
  AD_SOURCE: 'AD_SOURCE',
  CONTACT_LIFECYCLE: 'CONTACT_LIFECYCLE',
  QUERY_PARAM: 'QUERY_PARAM',
  NOT_SMART: 'NOT_SMART'
});
export const AnonymousCriterionTypes = Object.freeze(pick(CriterionTypes, CriterionTypes.GEO_IP, CriterionTypes.DEVICE_TYPE, CriterionTypes.TRAFFIC_SOURCE, CriterionTypes.LANGUAGE, CriterionTypes.AD_SOURCE, CriterionTypes.QUERY_PARAM));
export const CriterionTypeParameterKeys = Object.freeze({
  [CriterionTypes.GEO_IP]: 'countries',
  [CriterionTypes.DEVICE_TYPE]: 'deviceCategory',
  [CriterionTypes.TRAFFIC_SOURCE]: 'visitSource',
  [CriterionTypes.LANGUAGE]: 'languages',
  [CriterionTypes.CONTACT_LIST]: 'lists',
  [CriterionTypes.CONTACT_LIFECYCLE]: 'lifecycles',
  [CriterionTypes.QUERY_PARAM]: 'operation',
  [CriterionTypes.AD_SOURCE]: 'adCampaigns'
});
export const CriterionPreviewParameterKeys = Object.freeze({
  [CriterionTypes.GEO_IP]: 'tc_country',
  [CriterionTypes.DEVICE_TYPE]: 'tc_deviceCategory',
  [CriterionTypes.TRAFFIC_SOURCE]: 'tc_visitSource',
  [CriterionTypes.LANGUAGE]: 'tc_language',
  [CriterionTypes.CONTACT_LIST]: 'tc_listId',
  [CriterionTypes.CONTACT_LIFECYCLE]: 'tc_lifecycle',
  [CriterionTypes.AD_SOURCE]: 'tc_adSources'
});
export const AD_GRANUALRITY_LEVEL = Object.freeze({
  AD_SOURCES: 'adSources',
  AD_GROUPS: 'adGroups',
  AD_CAMPAIGNS: 'adCampaigns'
});
export const TRAFFIC_SOURCE_RULES_KEY = 'drillDownRules';
export const TRAFFIC_SOURCE_PREVIEW_PARMS_KEY = 'tc_drillDownRule';
export const AdSourcePreviewParamsByGranularity = Object.freeze({
  [AD_GRANUALRITY_LEVEL.AD_SOURCES]: 'tc_adSources',
  [AD_GRANUALRITY_LEVEL.AD_GROUPS]: 'tc_adGroups',
  [AD_GRANUALRITY_LEVEL.AD_CAMPAIGNS]: 'tc_adCampaigns'
});
export const TrafficSourceTypes = Object.freeze({
  ORGANIC_SEARCH: 'ORGANIC_SEARCH',
  REFERRALS: 'REFERRALS',
  SOCIAL_MEDIA: 'SOCIAL_MEDIA',
  EMAIL_MARKETING: 'EMAIL_MARKETING',
  PAID_SEARCH: 'PAID_SEARCH',
  DIRECT_TRAFFIC: 'DIRECT_TRAFFIC',
  OTHER_CAMPAIGNS: 'OTHER_CAMPAIGNS'
});
export const DeviceTypes = Object.freeze({
  MOBILE: 'MOBILE',
  TABLET: 'TABLET',
  DESKTOP: 'DESKTOP'
});
export const SocialMediaTypes = Object.freeze({
  FACEBOOK: 'FACEBOOK',
  TWITTER: 'TWITTER',
  LINKEDIN: 'LINKEDIN',
  GOOGLEPLUS: 'GOOGLEPLUS'
});
export const QueryParamOperations = Object.freeze({
  IS: 'IS',
  BEGINS_WITH: 'BEGINS_WITH',
  CONTAINS: 'CONTAINS'
});
export const RequestStatus = Object.freeze({
  UNINITIALIZED: 'UNINITIALIZED',
  PENDING: 'PENDING',
  SUCCEEDED: 'SUCCEEDED',
  FAILED: 'FAILED'
});
export const TRACK_EVENT = '@@usage';
export const TrackEventTypes = Object.freeze({
  INTERACTION: 'INTERACTION',
  ERROR: 'ERROR',
  USAGE: 'USAGE'
});
export const CustomPropTypes = Object.freeze({
  contentType: PropTypes.oneOf(Object.keys(ContentTypes)),
  criterionType: PropTypes.oneOf(Object.keys(CriterionTypes)),
  requestStatus: PropTypes.oneOf(Object.keys(RequestStatus)),
  targetType: PropTypes.oneOf(Object.keys(TargetTypes))
});
export const Scopes = Object.freeze({
  CONTACTS_READ: 'contacts-read'
});
export const CriterionTypeScopes = Object.freeze({
  [CriterionTypes.CONTACT_LIST]: Scopes.CONTACTS_READ,
  [CriterionTypes.CONTACT_LIFECYCLE]: Scopes.CONTACTS_READ
});
export const SMART_TYPES = [CriterionTypes.AD_SOURCE, CriterionTypes.CONTACT_LIST, CriterionTypes.CONTACT_LIFECYCLE, CriterionTypes.GEO_IP, CriterionTypes.DEVICE_TYPE, CriterionTypes.TRAFFIC_SOURCE, CriterionTypes.LANGUAGE, CriterionTypes.QUERY_PARAM];
export const DEFAULT_AUTH = Object.freeze({
  gates: [],
  portal: {},
  user: {
    scopes: []
  }
});
export const ModalTypes = Object.freeze({
  ADD_SMART_RULE_MODAL: 'ADD_SMART_RULE_MODAL',
  CREATE_DEFINITION_MODAL: 'CREATE_DEFINITION_MODAL',
  MANAGE_DEFINITION_PANEL: 'MANAGE_DEFINITION_PANEL'
});
export const SMART_CONTENT_LEARN_MORE_LINK = 'https://knowledge.hubspot.com/articles/kcs_article/cos-general/add-smart-content-to-your-website-pages-landing-pages-and-emails';
export const HAS_SEEN_AD_SOURCE_SHEPHERD = 'HAS_SEEN_AD_SOURCE_SHEPHERD'; // Regex to match any reserved characters
// https://datatracker.ietf.org/doc/html/rfc3986#section-3.3
// https://datatracker.ietf.org/doc/html/rfc3986#section-3.4

export const QUERY_PARAM_RESERVED_CHARS_REGEX = new RegExp(/[#%=&;]/g);