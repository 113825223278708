import Raven from 'raven-js';
export default function logPurchaseOrderSentry(location, err, additionalProperties) {
  const {
    errorCode,
    errorMessage,
    message,
    responseJSON,
    status,
    data
  } = err;
  Raven.captureMessage('Purchase order generation failed', {
    extra: Object.assign({
      location,
      errorCode,
      errorMessage,
      message,
      responseJSON,
      status,
      data
    }, additionalProperties)
  });
}