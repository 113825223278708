import { stringify } from 'hub-http/helpers/params';
import { getUserInfo } from 'ui-addon-upgrades/_core/common/api/getUserInfo';
let linkParams;
const BASE_PARAMS = {
  embed: true
};
export function prefetchMeetingData() {
  getUserInfo().then(({
    user,
    portal
  }) => {
    linkParams = {
      firstName: user.first_name,
      lastName: user.last_name,
      email: user.email,
      // @ts-expect-error domain is not defined in type Portal
      website: portal.domain
    };
  }).catch(() => {
    linkParams = {
      firstName: null,
      lastName: null,
      email: null,
      website: null
    };
  });
}
export function getInlineMeetingParams(upgradeData, repInfo, meeting_funnel = 'in-app', meeting_medium = 'pql') {
  const hasAssignedRep = !!(repInfo && repInfo.link);
  const attributionSource = hasAssignedRep ? 'rep' : 'ISC';
  const meetingParams = {
    meeting_funnel,
    meeting_medium,
    meeting_product: upgradeData.upgradeProduct,
    meeting_source: upgradeData.source,
    meeting_type: attributionSource,
    meeting_campaign: attributionSource
  };
  return stringify(Object.assign({}, linkParams, {}, meetingParams, {}, BASE_PARAMS));
}