import { CLEAR_ACTIVE_HUBDB_ROW, SET_ACTIVE_HUBDB_ROW, SET_HUBDB_ROWS_AND_SCHEMA, UPDATE_TABLE_ROWS } from 'ContentEditorUI/redux/actions/actionTypes';

const instanceEditingReducer = (state = {
  instanceEditingModeActive: false,
  currentHubDbRowId: null,
  tableSchema: null,
  tableRows: []
}, {
  type,
  payload
}) => {
  switch (type) {
    case SET_ACTIVE_HUBDB_ROW:
      {
        const {
          rowId
        } = payload;
        return Object.assign({}, state, {
          currentHubDbRowId: rowId,
          instanceEditingModeActive: true
        });
      }

    case CLEAR_ACTIVE_HUBDB_ROW:
      {
        return Object.assign({}, state, {
          currentHubDbRowId: null,
          instanceEditingModeActive: false
        });
      }

    case SET_HUBDB_ROWS_AND_SCHEMA:
      {
        const {
          rows,
          tableSchema,
          hubDbTable
        } = payload;
        return Object.assign({}, state, {
          tableSchema,
          tableRows: rows,
          hubDbTable
        });
      }

    case UPDATE_TABLE_ROWS:
      {
        const {
          rows
        } = payload;
        return Object.assign({}, state, {
          tableRows: rows
        });
      }

    default:
      return state;
  }
};

export default instanceEditingReducer;