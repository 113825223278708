import { findDOMNode } from 'react-dom';
import I18n from 'I18n';
import isValidI18nKey from 'I18n/utils/isValidI18nKey';
import Raven from 'raven-js';
import PortalIdParser from 'PortalIdParser';
import http from 'hub-http/clients/apiClient';
import { getFullUrl } from 'hubspot-url-utils';
import { parse, stringify } from 'hub-http/helpers/params';
import { getIframeModalHost } from '../_core/utils/getIframeModalHost';
import { sources } from 'ui-addon-upgrades/_core/common/data/upgradeData/properties/sources';
import { sourceKeys } from 'ui-addon-upgrades/_core/common/data/upgradeData/properties/sources';
import { i18nKeysByUpgradeProduct, i18nKeysByApiName } from 'ui-addon-upgrades/_core/common/adapters/getProductNameText';
// @ts-expect-error dependency missing types
import UpgradeProductToApiNameMap from 'self-service-api/constants/UpgradeProductToApiNameMap';
import { trackFailedApiNameMap } from '../_core/utils/metricUtils';
import { submitInteraction } from 'self-service-api/api/submitInteraction';
const upgradePointPromises = {};
export const upgradePointViews = {};
export function getApp() {
  return window.location.pathname.split('/')[1];
}
export function getScreen() {
  return window.location.pathname;
}
export function getHubAndTier(upgradeProduct) {
  return {
    hub: upgradeProduct.split('-')[0],
    tier: upgradeProduct.split('-')[1]
  };
}
export function getHubAndTierFromApiName(apiName) {
  const hub = apiName.split('-')[0];
  return {
    hub: hub === 'crm' ? 'suite' : hub,
    tier: apiName.split('-')[2]
  };
}
export function verifyMessage(ref, event) {
  const node = findDOMNode(ref.current);
  return node && node.contentWindow === event.source;
}
export function matchMessage(event, message) {
  return event.data === message;
}
export function createFrameSrc({
  upgradeKey,
  app,
  screen,
  portalId,
  params
}) {
  const formattedParams = params ? `&${params}` : '';
  return `${getIframeModalHost()}/ums-modal/${portalId}/${upgradeKey}?app=${app}&screen=${screen}${formattedParams}`;
}
export function getFrameSrc(upgradeKey) {
  return createFrameSrc({
    upgradeKey,
    app: getApp(),
    screen: getScreen(),
    portalId: PortalIdParser.get(),
    params: stringify(parse(window.location.search.substring(1)))
  });
}

function fetchUpgradePoint(upgradeKey) {
  if (!upgradeKey) {
    return Promise.resolve();
  }

  return http.get(`${getFullUrl('app-api', {})}/upgrade-management-service/v1/upgrade-configs/${upgradeKey}`).catch(error => {
    // 0 indicates user aborted requests, which we don't want to log as errors
    if (error && error.status === 0) {
      return;
    }

    const message = error && error.status === 404 ? `upgradeKey ${upgradeKey} may not exist in the UMS.` : 'UpgradeManagement API Failure';
    Raven.captureException(error, {
      extra: {
        message
      }
    });
    throw error;
  });
}

export function delayUntilIdle(callback) {
  window.setTimeout(callback, 5000);
}
export function getUpgradePointPromise({
  upgradeKey,
  delayCall = true //modals aren't needed right away so delay loading them to improve page performance

}) {
  if (upgradePointPromises[upgradeKey]) {
    return upgradePointPromises[upgradeKey];
  }

  const upgradePointPromise = new Promise(resolve => {
    if (delayCall) {
      delayUntilIdle(() => {
        resolve(fetchUpgradePoint(upgradeKey));
      });
    } else {
      resolve(fetchUpgradePoint(upgradeKey));
    }
  });
  upgradePointPromises[upgradeKey] = upgradePointPromise;
  return upgradePointPromise;
}
export const getRepName = (repInfo = {}) => {
  if (repInfo && repInfo.name) {
    return repInfo.name;
  }

  return 'Not assigned';
};

const getApiName = (upgradeData, canViewNewSeats = false) => {
  // this is set upstream and we blindly trust it was done correctly
  if (upgradeData.apiName) {
    return upgradeData.apiName;
  }

  if (canViewNewSeats) {
    return upgradeData.apiNames && upgradeData.apiNames[0] || UpgradeProductToApiNameMap[upgradeData.upgradeProduct];
  }

  return undefined;
};

export function getUpgradeDataTrackingProperties(upgradeData, isUpgradePoint = false, isUpgradePointResponse = false, canViewNewSeats = false) {
  const {
    upgradeProduct,
    upgradeProducts,
    apiNames
  } = upgradeData;
  const apiNameToUse = getApiName(upgradeData, canViewNewSeats);

  const getHubAndTierValues = () => {
    if (!canViewNewSeats) {
      return getHubAndTier(isUpgradePointResponse && upgradeProducts ? upgradeProducts[0] : upgradeProduct);
    } else {
      return getHubAndTierFromApiName(isUpgradePointResponse && apiNames ? apiNames[0] : apiNameToUse);
    }
  };

  const {
    hub,
    tier
  } = getHubAndTierValues();
  const trackingProperties = {
    app: upgradeData.app || getApp(),
    screen: upgradeData.screen || getScreen(),
    hub,
    tier,
    upgradeProduct,
    apiName: apiNameToUse
  };

  if (isUpgradePoint) {
    Object.assign(trackingProperties, {
      feature: upgradeData.feature,
      upgradeKey: upgradeData.upgradeKey,
      pointType: upgradeData.pointType,
      pointStatus: upgradeData.pointStatus,
      primaryColor: upgradeData.primaryColor,
      team: upgradeData.team,
      title: upgradeData.title,
      subtitle: upgradeData.subtitle
    });
  }

  if (!isUpgradePointResponse) {
    Object.assign(trackingProperties, {
      uniqueId: upgradeData.uniqueId,
      source: upgradeData.source,
      upgradeSource: upgradeData.upgradeSource
    });
  }

  return trackingProperties;
}
export const getSource = (app, screen, uniqueId) => `${app}-${screen}-${sources[sourceKeys.GENERAL]}-${uniqueId}`;
export const pointTypes = {
  default: 'LOCKED_FEATURE',
  modal: 'LOCKED_FEATURE',
  removeBrandingBanner: 'REMOVE_BRANDING_BANNER',
  usageLimitBanner: 'USAGE_LIMIT_BANNER',
  lockedFeatureBanner: 'LOCKED_FEATURE_BANNER',
  usageLimit: 'USAGE_LIMIT',
  limitPackBanner: 'LIMIT_PACK_BANNER'
};
export const upgradeBannerTypes = [pointTypes.removeBrandingBanner, pointTypes.usageLimitBanner, pointTypes.limitPackBanner];
export const getBannerI18nTitleKey = (titleI18nPrefix, featureTranslationKey) => {
  if (isValidI18nKey(`${titleI18nPrefix}.${featureTranslationKey}`)) {
    return `${titleI18nPrefix}.${featureTranslationKey}`;
  }

  return `${titleI18nPrefix}.placeholder`;
}; // remove branding banners have static subtext and don't call this function

export const getBannerI18nSubTitleKey = (subtitleI18nPrefix, featureTranslationKey) => {
  if (isValidI18nKey(`${subtitleI18nPrefix}.${featureTranslationKey}`)) {
    return `${subtitleI18nPrefix}.${featureTranslationKey}`;
  }

  return `${subtitleI18nPrefix}.placeholder`;
};
export const getProductI18nName = (subtitleI18nPrefix, upgradeProduct) => {
  const keyByProduct = i18nKeysByUpgradeProduct[upgradeProduct];

  if (keyByProduct && isValidI18nKey(`${subtitleI18nPrefix}.${keyByProduct}`)) {
    return I18n.text(`${subtitleI18nPrefix}.${keyByProduct}`);
  }

  return null;
};
export const getProductI18nNameFromApiName = (subtitleI18nPrefix, apiName) => {
  const keyByProduct = i18nKeysByApiName[apiName];

  if (keyByProduct && isValidI18nKey(`${subtitleI18nPrefix}.${keyByProduct}`)) {
    return I18n.text(`${subtitleI18nPrefix}.${keyByProduct}`);
  }

  return null;
};
export const warnArchivedUpgradePointUse = upgradeKey => {
  console.warn(`Upgrade point '${upgradeKey}' is archived, and should not be used in-app. If this upgrade point should not be archived, it can be restored through the UMS at https://tools.hubteamqa.com/ums/upgrade-points/${upgradeKey}.`);
};

const getStarterTierPurchaseMotionsData = purchaseMotionsObj => {
  const starterTierPurchaseMotionsData = {};
  Object.keys(purchaseMotionsObj).forEach(apiName => {
    const {
      tier
    } = getHubAndTierFromApiName(apiName);

    if (tier === 'starter') {
      const purchaseMotionsByApiName = purchaseMotionsObj[apiName];
      const purchaseMotions = purchaseMotionsByApiName && purchaseMotionsByApiName.map(purchaseMotionEntry => purchaseMotionEntry.key);
      starterTierPurchaseMotionsData[apiName] = purchaseMotions;
    }
  });
  return starterTierPurchaseMotionsData;
};

export const getSuiteStarterPurchaseMotions = (purchaseMotionsObj, fallbackPurchaseMotions) => {
  const starterTierPurchaseMotionData = getStarterTierPurchaseMotionsData(purchaseMotionsObj);
  const purchaseMotionValues = Object.values(starterTierPurchaseMotionData);
  const ownsSuiteStarter = purchaseMotionValues.every(purchaseMotions => purchaseMotions[0] === purchaseMotionValues[0][0]);

  if (ownsSuiteStarter) {
    return purchaseMotionValues[0];
  } else {
    return fallbackPurchaseMotions;
  }
};
export const mapPurchaseMotionApiName = (canViewNewSeats = false, upgradeProduct, source, apiName) => {
  if (canViewNewSeats && !apiName) {
    if (UpgradeProductToApiNameMap[upgradeProduct]) {
      return UpgradeProductToApiNameMap[upgradeProduct];
    } else {
      trackFailedApiNameMap(source, 'no api name', upgradeProduct);
      Raven.captureMessage(`failed to map upgrade product to apiName ${apiName} in ${source}`);
    }
  }

  return apiName;
};
export const trackPotentialBannerInteraction = (pointType, upgradeKey) => {
  if (pointType && upgradeKey && upgradeBannerTypes.includes(pointType)) {
    submitInteraction(upgradeKey).catch(error => {
      Raven.captureException(new Error('Failed to submit interaction with banner'), {
        extra: {
          error
        }
      });
    });
  }
};