'use es6';

import { isHubSpotter, isForceOpen, warn } from '../utils';
import { pages } from '../urls';
import translations from '../wootric-translations';
import * as UserAttributes from '../sources/UserAttributes';
const csatTokenQA = 'NPS-3489af5b';
const csatTokenProd = 'NPS-61a7bd63';
const SurveyKeys = {
  ACCOUNTS_AND_BILLING: 'ACCOUNTS_AND_BILLING',
  WORKFLOWS: 'WORKFLOWS',
  INBOX: 'INBOX',
  CMS_CONTENT_DETAIL: 'CMS_CONTENT_DETAIL',
  CMS_DESIGN_MANAGER: 'CMS_DESIGN_MANAGER',
  ADVANCED_BUILDER: 'ADVANCED_BUILDER',
  FORECASTING: 'FORECASTING',
  SOCIAL: 'SOCIAL',
  MARKETING_EMAIL: 'MARKETING_EMAIL',
  FORMS: 'FORMS',
  CAMPAIGNS: 'CAMPAIGNS',
  INDEX_CRM: 'INDEX_CRM',
  RECORD_CRM: 'RECORD_CRM',
  DEAL: 'DEAL',
  ADS: 'ADS',
  TASKS: 'TASKS',
  SEQUENCES: 'SEQUENCES',
  QUOTES: 'QUOTES',
  ANALYTICS: 'ANALYTICS',
  CHAT: 'CHAT',
  DATA_SYNC: 'DATA_SYNC',
  CRM_LISTS: 'CRM_LISTS',
  CRM_SETTINGS: 'CRM_SETTINGS',
  PAYMENTS: 'PAYMENTS',
  ADMIN_SETUP: 'ADMIN_SETUP',
  IMPORT: 'IMPORT',
  ADMIN_GOVERNANCE: 'ADMIN_GOVERNANCE',
  SINGLE_REPORT_VIEWER: 'SINGLE_REPORT_VIEWER',
  REPORT_LIBRARY: 'REPORT_LIBRARY',
  JOURNEY_ANALYTICS: 'JOURNEY_ANALYTICS',
  SINGLE_OBJECT_BUILDER: 'SINGLE_OBJECT_BUILDER',
  MULTI_TOUCH_ATTRIBUTION: 'MULTI_TOUCH_ATTRIBUTION'
};
const SurveyConfig = {
  [SurveyKeys.ACCOUNTS_AND_BILLING]: {
    event_name: 'AccountVisit',
    allowedPages: [pages.billing]
  },
  [SurveyKeys.WORKFLOWS]: {
    event_name: 'workflows_visit',
    allowedPages: [pages.workflows]
  },
  [SurveyKeys.INBOX]: {
    event_name: 'inbox_visit',
    allowedPages: [pages.inbox]
  },
  [SurveyKeys.CMS_CONTENT_DETAIL]: {
    event_name: 'content_pageview',
    allowedPages: [pages.contentDetail]
  },
  [SurveyKeys.CMS_DESIGN_MANAGER]: {
    event_name: 'designmanager_editor',
    allowedPages: [pages.designManager]
  },
  [SurveyKeys.ADVANCED_BUILDER]: {
    event_name: 'open_filters',
    allowedPages: [pages.advancedBuilder]
  },
  [SurveyKeys.FORECASTING]: {
    event_name: 'forecasting_pageview',
    allowedPages: [pages.forecasting]
  },
  [SurveyKeys.SOCIAL]: {
    event_name: 'social_manage',
    allowedPages: [pages.social]
  },
  [SurveyKeys.MARKETING_EMAIL]: {
    event_name: 'email_pageview',
    allowedPages: [pages.marketingEmail]
  },
  [SurveyKeys.FORMS]: {
    event_name: 'form_pageview',
    allowedPages: [pages.submissions]
  },
  [SurveyKeys.CAMPAIGNS]: {
    event_name: 'campaigns_details',
    allowedPages: [pages.campaigns]
  },
  [SurveyKeys.DEAL]: {
    event_name: 'deal_preview',
    allowedPages: [pages.deal]
  },
  [SurveyKeys.ADS]: {
    event_name: 'ads_pageview',
    allowedPages: [pages.ads]
  },
  [SurveyKeys.TASKS]: {
    event_name: 'create_task',
    allowedPages: [pages.tasks]
  },
  [SurveyKeys.SEQUENCES]: {
    event_name: 'sequence_pageview',
    allowedPages: [pages.sequences]
  },
  [SurveyKeys.QUOTES]: {
    event_name: 'quotes_pageview',
    allowedPages: [pages.quotes]
  },
  [SurveyKeys.ANALYTICS]: {
    event_name: 'analytics_nav',
    allowedPages: [pages.analytics]
  },
  [SurveyKeys.INDEX_CRM]: {
    event_name: 'open_saved_view',
    allowedPages: [pages.indexCRM]
  },
  [SurveyKeys.RECORD_CRM]: {
    event_name: 'pin_engagement',
    allowedPages: [pages.recordCRMContact, pages.recordCRMCompany]
  },
  [SurveyKeys.CHAT]: {
    event_name: 'chat_pageview',
    allowedPages: [pages.chat]
  },
  [SurveyKeys.DATA_SYNC]: {
    event_name: 'save_sync',
    allowedPages: [pages.dataSync]
  },
  [SurveyKeys.CRM_LISTS]: {
    event_name: 'save_list',
    allowedPages: [pages.crmLists]
  },
  [SurveyKeys.CRM_SETTINGS]: {
    event_name: 'save_property',
    allowedPages: [pages.crmSettings]
  },
  [SurveyKeys.PAYMENTS]: {
    event_name: 'payments_sidebar',
    allowedPages: [pages.payments]
  },
  [SurveyKeys.ADMIN_SETUP]: {
    event_name: 'create_new_user',
    allowedPages: [pages.adminSetup]
  },
  [SurveyKeys.IMPORT]: {
    event_name: 'finish_import',
    allowedPages: [pages.import]
  },
  [SurveyKeys.ADMIN_GOVERNANCE]: {
    event_name: 'admin_governance',
    allowedPages: [pages.adminGovernanceImportExport, pages.adminGovernancePermissionHistory, pages.adminGovernanceProductUpdate, pages.adminGovernanceDataProtection, pages.adminGovernanceConsentOptions, pages.adminGovernanceAccountDefaults, pages.adminGovernanceNotificationProfile]
  },
  [SurveyKeys.SINGLE_REPORT_VIEWER]: {
    event_name: 'single_report_view',
    allowedPages: [pages.singleReportViewer]
  },
  [SurveyKeys.REPORT_LIBRARY]: {
    event_name: 'report_library_view',
    allowedPages: [pages.reportLibrary]
  },
  [SurveyKeys.JOURNEY_ANALYTICS]: {
    event_name: 'journey_builder_pageview',
    allowedPages: [pages.journeyAnalytics]
  },
  [SurveyKeys.SINGLE_OBJECT_BUILDER]: {
    event_name: 'open_single_object_builder',
    allowedPages: [pages.singleObjectBuilder]
  },
  [SurveyKeys.MULTI_TOUCH_ATTRIBUTION]: {
    event_name: 'mt_attribution',
    allowedPages: [pages.multiTouchAttribution]
  }
};
export const getConfig = (token, props = {}, surveyKey) => {
  const {
    field_required,
    permission_labels,
    customMessages: overrideMessages,
    complete,
    supported
  } = translations;
  const lang = supported[props.language] ? props.language : 'EN'; // Wootric doesn't support translating custom messages for other languages:
  // http://docs.wootric.com/javascript/#custom-language-setting
  // So we have to build our own light translation setup here

  let wootricTarget;
  let customMessages = {};
  let translatedConfig = {};
  const surveysWithNPSFollowupQuestion = [SurveyKeys.SINGLE_REPORT_VIEWER, SurveyKeys.REPORT_LIBRARY, SurveyKeys.ADVANCED_BUILDER, SurveyKeys.JOURNEY_ANALYTICS, SurveyKeys.SINGLE_OBJECT_BUILDER, SurveyKeys.MULTI_TOUCH_ATTRIBUTION];

  if (complete[lang]) {
    const {
      csat_questions,
      followup_question,
      followup_question_nps,
      followup_thank_you,
      placeholder_text,
      wootric_recommend_target
    } = overrideMessages[lang];
    wootricTarget = wootric_recommend_target;
    customMessages = {
      followup_question: surveysWithNPSFollowupQuestion.includes(surveyKey) ? followup_question_nps : followup_question,
      followup_thank_you,
      placeholder_text
    };

    if (csat_questions && csat_questions[surveyKey]) {
      customMessages.csat_question = csat_questions[surveyKey];
    }
  }

  translatedConfig = {
    field_required,
    permission_labels,
    wootric_recommend_target: wootricTarget
  };
  const wootricSettings = Object.assign({}, props, {
    account_token: token,
    ask_permission_to_share_feedback: true,
    // this generates the checkbox (pre-checked)
    modal_footprint: 'normal',
    callbacks: {
      after_show: config => {
        UserAttributes.touch('nav:seen');

        if (config && config.localized_texts && translations.complete[props.language]) {
          try {
            config.localized_texts.send = translations.customMessages[props.language].send;
          } catch (e) {
            warn(`Could not set localized "Send" text for language: ${props.language}; message: ${e.message}`);
          }
        }
      },

      after_decline() {
        UserAttributes.touch('nav:declined');
      },

      after_response() {
        UserAttributes.touch('nav:responded');
      }

    }
  }, translatedConfig);
  return {
    customMessages,
    wootricSettings
  };
};
export const configureCsat = (props, surveyKey) => {
  const {
    event_name
  } = SurveyConfig[surveyKey];

  if (event_name) {
    props.event_name = event_name;
  }

  const token = isHubSpotter(props.email) ? csatTokenQA : csatTokenProd;

  if (isForceOpen()) {
    window.wootric_no_surveyed_cookie = true;
    console.debug('"wootric-nps-open" flag is set.', 'Wootric should be forced open.', 'Disregard the message it gives saying "remove survey immediately".');
  } // seconds to delay before opening (default 10)


  window.wootric_time_delay = 1;
  const {
    wootricSettings,
    customMessages
  } = getConfig(token, props, surveyKey);
  window.customMessages = customMessages;
  window.wootricSettings = wootricSettings;
  console.log(window.wootricSettings);
};