import { CONTENT, STYLE } from 'ContentUtils/constants/CustomWidgetFieldGroupTabTypes';
export const SIDEBAR_PANELS = {
  addWidget: 'addWidget',
  edit: 'edit',
  optimizer: 'optimizer',
  preview: 'preview',
  widgetList: 'widgetList',
  // Email only
  abTest: 'abTest',
  clientTest: 'clientTest',
  testSend: 'testSend',
  // New editor only
  design: 'design',
  //patagonia only
  pageContents: 'pageContents',
  pageManagement: 'pageManagement',
  tools: 'tools',
  publish: 'publish',
  runTest: 'runTest',
  instanceEditing: 'instanceEditing'
};
export const EDIT_SIDEBAR_PANELS = {
  edit: 'edit',
  styles: 'style'
};
export const BUILTIN_MODULE_MANUAL_ORDER = ['rich_text', 'linked_image', 'header', 'cta', 'form', 'text', 'horizontal_spacer', 'image_slider_gallery', 'image_grid', 'button', 'tabbed_card', 'icon', 'payments', 'divider', 'follow_me_lp', 'video', 'meetings']; // Module paths that are not available to drag-and-drop in the post body.
// These names are based on the paths of HubSpot default modules e.g. `@hubspot/button`,
// but also used to filter modules overridden in child themes.

export const BLOG_DND_RESTRICTED_MODULES = ['@hubspot/cta', '@hubspot/divider', '@hubspot/icon', '@hubspot/linked_image', '@hubspot/header', '@hubspot/rich_text', '@hubspot/section_header', '@hubspot/text', '@hubspot/video'];
export const APPLY_CHANGES_PORTAL_SELECTOR = 'apply-changes-button-portal-target';
export const TABS_TRANSLATION = {
  edit: CONTENT,
  style: STYLE //when data is integrated it shall be added here

};
export const MODULES_SIDEBAR_WIDTH = 664;
export const SECTIONS_SIDEBAR_WIDTH = 821;
export const LAYOUT_SIDE_BAR_WIDTH = 661;
export const PUSHY_SIDEBAR_WIDTH = 380;
export const LAYERS_SIDEBAR_WIDTH = 369;
export const OPTIMIZER_SIDEBAR_WIDTH = 369;
export const EDIT_MODULE_SIDEBAR_WIDTH = 380;
export const COLLAPSED_SIDEBAR_WIDTH = 56;
export const PATAGONIA_NAVIGATION_BAR = 52;
export const TOOLS_MENU_SIDEBAR_WIDTH = 250;
export const PAGE_MANAGEMENT_SIDEBAR_WIDTH = 833;
export const PUSHY = 'pushy';
export const FLOATY = 'floaty';