import { createSelector } from 'reselect';
import { getDefaultXrayEditorApi } from 'ContentEditorUI/lib/xray/defaultEditorXrayApi';
import { getSeoSectionConfig } from 'xray-recommendation-lib/seo/configs/seoSectionConfig';
import { SeoRecommendationGroups } from 'xray-recommendation-lib/seo/constants';
export const getXrayEditorApi = createSelector([getDefaultXrayEditorApi], defaultXrayEditorApi => {
  return Object.assign({}, defaultXrayEditorApi);
}); // ZR-TODO Get specified xray configs from editor config file

export const getXrayConfigsFromEditorConfig = createSelector([getXrayEditorApi], xrayEditorApi => {
  const config = getSeoSectionConfig(xrayEditorApi);
  const filteredGroups = config.groups.filter(group => group.type !== SeoRecommendationGroups.CONTENT);
  config.groups = filteredGroups;
  return [config];
});