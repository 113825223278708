import _objectWithoutPropertiesLoose from "@babel/runtime/helpers/esm/objectWithoutPropertiesLoose";
const SYNC_CONTENT_ASSISTANCE_URL = 'content-assistance/v1/assist?rawResponse=true';
const CONTENT_ASSISTANCE_URL = 'content-assistance/v2/command-executions';
const RESULT_POLL_TIMEOUT = 200;
const ML_FEEDBACK_URL = 'ml-nlp/v1/feedbacks/text-generation';

const getExecutionIdUrl = executionId => {
  return `content-assistance/v2/command-executions/${executionId}`;
}; // This should be set once per app load with the value
// returned from the first time we hit the content assistance endpoint,
// and then sent to the content assistance end point for every other request made.


let appSessionId;

const escapeMarkup = result => {
  const tempDiv = document.createElement('div');
  tempDiv.innerText = result;
  return tempDiv.innerHTML;
};

const trimNewLines = result => result.replace(/^\s+|\s+$/g, '');

const brToNewLine = result => {
  return result.replace(/<br\/?>/g, '\n');
};

const sanitize = result => {
  const trimmedResult = trimNewLines(result);
  const escapedResult = escapeMarkup(trimmedResult); // returning innerHtml has the effect of turning newlines into brs we only support plain text results at the moment

  const plainTextResult = brToNewLine(escapedResult);
  return plainTextResult;
}; // see https://tools.hubteamqa.com/puma/framework-app-settings/contentAssistanceCommands/entries
// new commands should be added there


export const fetchContentAssistanceGeneratedContent = ({
  http,
  commandId,
  parameters,
  resultCount = 1,
  // Language code, e.g. `es-es`
  language,
  objectId
}) => {
  return http.post(CONTENT_ASSISTANCE_URL, {
    data: {
      commandId,
      parameters,
      resultCount,
      language,
      sessionId: appSessionId,
      objectId
    },
    timeout: 30000
  }).then(({
    executionId,
    sessionId
  }) => {
    if (!appSessionId) {
      appSessionId = sessionId;
    }

    const executionIdUrl = getExecutionIdUrl(executionId);

    const poll = () => {
      return http.getWithResponse(executionIdUrl).then(resp => {
        // 202 means the results aren't ready yet, call again
        if (resp.status === 202) {
          return new Promise(resolve => {
            setTimeout(() => {
              return poll().then(result => resolve(result));
            }, RESULT_POLL_TIMEOUT);
          });
        }

        return resp.responseJSON;
      });
    };

    return poll();
  }).then(data => {
    const response = data;
    const {
      results,
      correlationId
    } = response;
    const normalizedResults = [];
    results.forEach(apiResult => {
      if (apiResult.value) {
        // Sanitize the text
        const unsanitizedText = apiResult.value;
        const sanitizedText = sanitize(unsanitizedText);
        apiResult.text = sanitizedText; // Annotate the result with the correlationId so we can use it for tracking later

        apiResult.correlationId = correlationId;
        normalizedResults.push(apiResult);
      }
    });
    return normalizedResults;
  });
};
export const syncFetchContentAssistanceGeneratedContent = ({
  http,
  commandId,
  parameters,
  resultCount = 1,
  // Language code, e.g. `es-es`
  language,
  objectId
}) => {
  return http.post(SYNC_CONTENT_ASSISTANCE_URL, {
    data: {
      commandId,
      parameters,
      resultCount,
      language,
      objectId
    },
    timeout: 30000
  }).then(data => {
    const response = data;
    const {
      results,
      correlationId
    } = response;
    const normalizedResults = [];
    results.forEach(apiResult => {
      // Flatten the result object to make the results
      // easier to deal with
      const {
        result
      } = apiResult,
            restResult = _objectWithoutPropertiesLoose(apiResult, ["result"]);

      const normalizedResult = Object.assign({}, restResult, {}, result); // Sanitize the text

      const unsanitizedText = normalizedResult.text;
      const sanitizedText = sanitize(unsanitizedText);
      normalizedResult.text = sanitizedText; // Annotate the result with the correlationId so we can use it for tracking later

      normalizedResult.correlationId = correlationId;
      normalizedResults.push(normalizedResult);
    });
    return normalizedResults;
  });
};
export const generateFeedbackAnnotatedResults = (generatedContent, results, shouldSendPositiveFeedback = true) => {
  const {
    text,
    correlationId
  } = generatedContent;
  return results.map(result => {
    const resultCopy = Object.assign({}, result);

    if (shouldSendPositiveFeedback && correlationId === resultCopy.correlationId && text === resultCopy.text) {
      // Override the text generated from the endpoint with
      // what the user actually inserted (if they changed some stuff)
      resultCopy.text = text;
      resultCopy.isPositiveFeedback = true;
    } else {
      resultCopy.isPositiveFeedback = false;
    }

    return resultCopy;
  });
};
export const getFeedbackTriggerButtonResults = (currentInputText, generatedContent, results) => {
  const {
    correlationId,
    text
  } = generatedContent;
  return results.map(result => {
    const resultCopy = Object.assign({}, result); // Default feedback to negative

    resultCopy.isPositiveFeedback = false;

    if (correlationId === resultCopy.correlationId && text === resultCopy.text) {
      // Only successful when it was inserted and not changed
      if (resultCopy.text === currentInputText && results.length === 1) {
        resultCopy.isPositiveFeedback = true;
      } else {
        // Override the text generated from the endpoint with
        // what the user actually inserted (if they changed some stuff)
        resultCopy.text = currentInputText;
      }
    }

    return resultCopy;
  });
};
export const sendFeedbackToML = ({
  http,
  feedbackAnnotatedCompletions
}) => {
  const allFeedbackRequests = [];
  feedbackAnnotatedCompletions.forEach(feedback => {
    const {
      isPositiveFeedback,
      value,
      correlationId
    } = feedback;
    const data = {
      correlationId,
      sessionId: appSessionId,
      feedbacks: [{
        value,
        label: isPositiveFeedback
      }]
    };
    allFeedbackRequests.push(http.post(ML_FEEDBACK_URL, {
      data
    }));
  });
  return Promise.all(allFeedbackRequests);
};