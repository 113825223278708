// Please update the Google Doc when making changes to the events:
// https://docs.google.com/document/d/1eYOmuhbzxhDE9xFa38qY5tEarAmroD8WYh8JE0dFzhk/edit?usp=sharing
// APPROVAL MODAL
export const APPROVAL_MODAL_OPENED = 'approval-modal-opened';
export const APPROVAL_MODAL_CLOSED_WITHOUT_SUBMISSION = 'approval-modal-closed-without-submission';
export const APPROVAL_MODAL_FORM_SUBMITTED = 'approval-modal-form-submitted';
export const APPROVAL_MODAL_CHANGED_APPROVER = 'approval-modal-changed-approver'; // APPROVAL SIDEBAR

export const APPROVAL_SIDEBAR_OPENED = 'approval-sidebar-opened'; // APPROVAL BUTTONS

export const APPROVAL_REQUEST_CHANGES = 'approval-request-changes';