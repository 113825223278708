import { combineReducers } from 'redux';
import * as RequestStatus from 'ContentUtils/constants/RequestStatus';
import { CUSTOM_FONTS_FETCH_FONT, CUSTOM_FONTS_FETCH_FONTS, GOOGLE_FONTS_FETCH_FONT, GOOGLE_FONTS_FETCH_FONTS, GOOGLE_FONTS_FETCH_COMMON_FONTS, GOOGLE_FONTS_CLEAR, SELECT_CUSTOM_FONT, SELECT_GOOGLE_FONT } from 'ContentData/actions/ActionTypes';

const fonts = (state = {}, action) => {
  switch (action.type) {
    case GOOGLE_FONTS_FETCH_FONTS:
      {
        const {
          response
        } = action;
        if (!(response && response.results)) return state;
        return response.results.reduce((hash, font) => {
          hash[font.family] = font;
          return hash;
        }, Object.assign({}, state));
      }

    case GOOGLE_FONTS_CLEAR:
      {
        if (Object.keys(state).length) return {};
        return state;
      }

    default:
      return state;
  }
};

const selectedFonts = (state = {}, action) => {
  switch (action.type) {
    case GOOGLE_FONTS_FETCH_FONT:
      {
        const {
          response
        } = action;
        if (!(response && response.results)) return state;
        return response.results.reduce((hash, font) => {
          hash[font.family] = font;
          return hash;
        }, Object.assign({}, state));
      }

    case SELECT_GOOGLE_FONT:
      {
        const {
          font
        } = action;
        return Object.assign({}, state, {
          [font.family]: font
        });
      }

    default:
      return state;
  }
};

const commonFonts = (state = [], action) => {
  switch (action.type) {
    case GOOGLE_FONTS_FETCH_COMMON_FONTS:
      {
        const {
          response
        } = action;
        if (!(response && response.results)) return state;
        return response.results;
      }

    default:
      return state;
  }
};

const commonFontsRequestStatus = (state = RequestStatus.UNINITIALIZED, action) => {
  switch (action.type) {
    case GOOGLE_FONTS_FETCH_COMMON_FONTS:
      if (action.response) return RequestStatus.SUCCEEDED;
      if (action.error) return RequestStatus.FAILED;
      return RequestStatus.PENDING;

    default:
      return state;
  }
};

const customFonts = (state = {}, action) => {
  switch (action.type) {
    case CUSTOM_FONTS_FETCH_FONTS:
      {
        const {
          response,
          options
        } = action;
        const {
          path
        } = options || {};
        if (!(response && response.length && path)) return state;
        const fontsObj = response.reduce((hash, font) => {
          hash[font.family] = font;
          return hash;
        }, Object.assign({}, state[path]));
        return Object.assign({}, state, {
          [path]: fontsObj
        });
      }

    default:
      return state;
  }
};

const selectedCustomFonts = (state = {}, action) => {
  switch (action.type) {
    case CUSTOM_FONTS_FETCH_FONT:
      {
        const {
          response
        } = action;
        if (!(response && response.length)) return state;
        return response.reduce((hash, font) => {
          hash[font.family] = font;
          return hash;
        }, Object.assign({}, state));
      }

    case SELECT_CUSTOM_FONT:
      {
        const {
          font
        } = action;
        return Object.assign({}, state, {
          [font.family]: font
        });
      }

    default:
      return state;
  }
};

const customFontsRequestStatus = (state = {}, action) => {
  switch (action.type) {
    case CUSTOM_FONTS_FETCH_FONTS:
      {
        const {
          error,
          options,
          response
        } = action;
        const {
          path
        } = options || {};
        if (!path) return state;
        let status = RequestStatus.PENDING;
        if (response) status = RequestStatus.SUCCEEDED;
        if (error) status = RequestStatus.FAILED;
        return Object.assign({}, state, {
          [path]: status
        });
      }

    default:
      return state;
  }
};

export default combineReducers({
  fonts,
  selectedCustomFonts,
  selectedFonts,
  commonFonts,
  commonFontsRequestStatus,
  customFonts,
  customFontsRequestStatus
});